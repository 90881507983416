import i18n from '../../assets/locales.json'

import { Dropdown } from '../Dropdown/Dropdown'
import { Icon, Switch } from '../../atoms'
import { LeaderboardLegend } from '../LeaderboardLegend/LeaderboardLegend'
import { LiveBadge } from '../LiveBadge/LiveBadge'
import { useRoundGroups } from '../../hooks'

export const LeaderboardBar = () => {
  const {
    live,
    scoreMode: { handleChange, modeKey, modeOpts, typeValue },
  } = useRoundGroups()

  return (
    <section className="flex flex-col md:flex-row md:items-center justify-between space-y-2 md:space-y-0 md:space-x-4">
      <div className="flex items-center space-x-2">
        <Icon className="text-primary-solid-2" icon="leaderboard" />
        <h2 className="font-semibold text-grey-solid-2 text-xl">
          {i18n.global.leaderboard}
        </h2>
        {live && <LiveBadge />}
      </div>
      <div className="flex items-center gap-x-2 md:gap-x-4">
        <LeaderboardLegend />
        <Dropdown
          onChange={({ value }) => handleChange('mode', `${value}`)}
          options={modeOpts}
          size="xl"
          text={modeKey}
        />
        <Switch
          label={i18n.global.net}
          onChange={(isNet) => handleChange('type', isNet ? 'net' : 'gross')}
          value={typeValue === 'net'}
        />
      </div>
    </section>
  )
}
