import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import {
  AuthenticationContext,
  AuthenticationContextType,
} from './AuthenticationContext'

interface Props {
  children: ReactNode
}

export const AuthenticationProvider = ({ children }: Props) => {
  const [user, setUser] = useState<AuthenticationContextType['user']>(undefined)

  const value = useMemo(() => ({ user }), [user])

  const loadUser = useCallback(async () => {
    try {
      const response = await fetch('/users/current')
      const data: CurrentUser = await response.json()
      setUser(data.full_name ? data : undefined)
    } catch (err) {
      // user is logged out
    }
  }, [])

  useEffect(() => {
    loadUser()
  }, [])

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}
