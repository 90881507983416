import { Props } from './Button'

export const getColor = (
  color: Props['color'],
  outline: Props['outline'],
  textColor: Props['textColor'],
  disabled: boolean
) => {
  if (disabled) {
    return 'border-separator-solid-1 bg-grey-alpha-4 text-grey-solid-5'
  }
  let classNames = `border-${color} `
  if (outline) {
    classNames += `text-${color}`
  } else {
    classNames += `bg-${color} ${textColor}`
  }
  return classNames
}

export const getFont = (size: Props['size']) => {
  let classNames = 'text-sm leading-[17px] '
  if (size === 'xl') {
    classNames += 'md:text-md md:leading-[22px]'
  }
  if (size === '2xl') {
    classNames += 'md:text-lg md:leading-[22px]'
  }
  return classNames
}

export const getPadding = (size: Props['size']) => {
  let classNames = 'px-[14px] py-[9px] '
  if (size === 'xl') {
    classNames += 'md:px-[19px] md:py-[12px]'
  }
  if (size === '2xl') {
    classNames += 'md:px-[23px] md:py-[15px]'
  }
  return classNames
}

export const getRadius = (size: Props['size']) => {
  return size === '2xl' ? 'rounded-[10px]' : 'rounded-[7px]'
}
