import { ReactNode } from 'react'

import { GlossaryLink } from '../molecules'

interface Props {
  href?: string
  linktype?: string
  target?: string | null
}

export const RichTextLink = (
  children: ReactNode,
  { href, linktype, target }: Props
) => {
  if (href && linktype === 'glossary') {
    return <GlossaryLink href={href}>{children}</GlossaryLink>
  }

  return (
    <a
      href={linktype === 'email' ? `mailto:${href}` : href}
      rel={href}
      target={target || undefined}
    >
      {children}
    </a>
  )
}
