import { GlossaryTerm } from '../../../../types'
import { GlossaryCard } from '../GlossaryCard/GlossaryCard'

interface Props {
  letter: string
  terms: GlossaryTerm[]
}

export const GlossaryRow = ({ letter, terms }: Props) => (
  <div className="flex first:pt-0 pt-10 space-x-6">
    <div className="font-bold text-dsm min-w-14">{letter}</div>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-4">
      {terms
        .sort((a, b) => a.term.localeCompare(b.term))
        .map((term) => (
          <GlossaryCard key={term.full_slug} {...term} />
        ))}
    </div>
  </div>
)
