import React, { useCallback, useEffect, useState } from 'react'
import CourseProfileHelper from '../../../../helpers/courseProfile'
import { Label } from '../Label/Label'
import { useApi } from '../../hooks/useApi'

interface Photo {
  src: string;
  srcSet: string[];
  caption: string;
  authName: string;
  date: string;
  thumbnail: string;
}

interface ComponentState {
  photos: Photo[]
  isOpen: boolean
  index: number
  component: any
}

interface State {
  data: Activity[]
  error: boolean
}

interface ComponentProps {
  title: string
  photos: Photo[]
}

interface Props {
  courseIdentitySlug: string
  title: string
}

const photoObject = (photoInfo: Activity) : Photo => ({
  src: photoInfo.images.small,
  srcSet: [
    `${photoInfo.images.small} 800w`,
    `${photoInfo.images.medium} 1280w`,
    `${photoInfo.images.big} 1920w`,
  ],
  thumbnail: photoInfo.images.thumb.medium,
  caption: `Taken by ${photoInfo.user.full_name} on ${new Date(photoInfo.created_at).toLocaleDateString()}`,
  authName: photoInfo.user.full_name,
  date: new Date(photoInfo.created_at).toLocaleDateString(),
});

function Photo({ photo, onClick, index }) {
  return (
    <li className="aspect-1/1 relative w-full">
      <img
        alt={photo.authName}
        className="absolute inset-0 object-cover h-full rounded-xs w-full"
        src={photo.thumbnail}
        onClick={() => onClick(index)}
      />
    </li>
  );
}

class CourseCommunityComponent extends React.Component {
  constructor(props : ComponentProps) {
    super(props);
    this.state = { index: 0, isOpen: false, component: null };
    this.openGallery = this.openGallery.bind(this);
  }

  componentDidMount() {
    //Loading this dependency dynamically to avoid SSR issues
    // @ts-ignore
    import('react-images').then((module) => {
      this.setState({ component: module.default });
    });
  }

  onClose = () => (
    this.setState({ isOpen: false })
  );

  moveNext = () => (
    this.setState((prevState : ComponentState) => ({ index: (prevState.index + 1) % this.props.photos.length }))
  );

  movePrev = () => (
    this.setState((prevState : ComponentState) => ({ index: (prevState.index + this.props.photos.length - 1) % this.props.photos.length }))
  );

  openGallery = (index : number) => {
    this.setState({ index, isOpen: true })
  };

  lightboxStyles = {
    footer: {
      font: '16px aileronregular, arial, tahoma, sans-serif',
    },
  };

  render() {
    const state = this.state as ComponentState;
    const {photos, title} = this.props as ComponentProps;
    const Component = state.component;
    const {index, isOpen}  = state;

    return (
      <Label text={title}>
        <ul className="grid grid-cols-3 gap-1">

          {photos?.slice(0,9).map((photo, index) => (
            <Photo onClick={this.openGallery} index={index} photo={photo} key={index} />
          ))}
          {Component && (
            <Component
              images={photos || []}
              onClickImage={this.moveNext}
              currentImage={index}
              isOpen={isOpen}
              onClose={this.onClose}
              onClickPrev={this.movePrev}
              onClickNext={this.moveNext}
              theme={this.lightboxStyles}
            />
          )}
        </ul>
      </Label>
    );
  }
}

export const CourseCommunity = ({
  courseIdentitySlug,
  title,
}: Props) => {
  const { pop } = useApi()

  const [state, setState] = useState<State>({
    data: [],
    error: false,
  })

  const fetchPhotos = useCallback(async () => {
    try {
      const res: CommunityPhotosResponse =
        await CourseProfileHelper.fetchCommunityPhotos(
          pop,
          courseIdentitySlug
        )
      setState({ data: res.data, error: false })
    } catch (err) {
      setState({ data: [], error: true })
    }
  }, [courseIdentitySlug, pop])

  useEffect(() => {
    fetchPhotos()
  }, [fetchPhotos])

  if (state.error || state.data.length === 0) {
    return null
  }

  return (
    <CourseCommunityComponent
      photos={state.data.map(photoObject)}
      title={title}
    />
  )
}

