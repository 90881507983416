import { RichText } from '../../atoms'
import { useGlossary } from '../../hooks'
import { RichtextStoryblok } from '../../../../types'

interface Props {
  className?: string
  text: string | RichtextStoryblok
}

export const GlossaryText = ({ className, text }: Props) => {
  const { withGlossary } = useGlossary()

  return <RichText className={className} content={withGlossary(text)} />
}
