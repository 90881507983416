import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { Content, Layout } from '../templates'
import { TermsConditionsPageStoryblok } from '../../../types'

export const TermsConditionsPage = ({
  blok,
}: {
  blok: TermsConditionsPageStoryblok
}) => (
  <Layout>
    <Content spacing="space-y-8" padding="py-16 md:pt-24">
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
