import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { Content, Layout } from '../templates'
import { ContactsPageStoryblok } from '../../../types'

export const ContactsPage = ({ blok }: { blok: ContactsPageStoryblok }) => (
  <Layout>
    <Content padding="py-16 md:pt-24">
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
