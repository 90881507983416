import clsx from 'clsx'
import { FeatureImageStoryblok } from '../../../../types/storyblok'

interface Props {
  image: {
    alt: string | null
    filename: string | null
  }
  ratio: '5/4' | '4/5'
}

export const FeatureImage = ({ image, ratio }: Props) => (
  <div
    className={clsx('flex-1 order-first lg:order-none relative w-full', {
      'aspect-5/4': ratio === '5/4',
      'aspect-4/5 max-h-[600px]': ratio === '4/5',
    })}
  >
    {image.filename && (
      <img
        alt={image.alt || ''}
        className="mx-auto h-full"
        src={image.filename}
      />
    )}
  </div>
)

export const FeatureImageSb = ({ blok }: { blok: FeatureImageStoryblok }) => (
  <FeatureImage {...blok} />
)
