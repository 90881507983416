import clsx from 'clsx'

import {
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
  useEffect,
  useState,
} from 'react'

export interface InputFieldProps {
  defaultValue?: string
  disabled?: boolean
  inputProps?: Record<string, string>
  name: string
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: (focus: boolean) => void
  prefix?: ReactNode
  placeholder?: string
  required?: boolean
  size?: 'xl' | '2xl'
  suffix?: ReactNode
  type?: HTMLInputTypeAttribute
  value?: string
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      defaultValue,
      disabled = false,
      inputProps = {},
      name,
      onChange,
      onKeyDown,
      onFocus,
      prefix,
      placeholder,
      required = false,
      size = '2xl',
      suffix,
      type = 'text',
      value,
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false)

    useEffect(() => {
      if (onFocus) onFocus(focus)
    }, [focus, onFocus])

    return (
      <label
        className={clsx(
          'bg-white-solid-1 border border-separator-solid-1 flex items-center px-4 rounded-sm space-x-2',
          {
            'bg-grey-alpha-4 cursor-not-allowed text-grey-solid-5': disabled,
            'text-grey-solid-0': !disabled,
            'shadow-inputFocus': !disabled && focus,
            'hover:shadow-input': !disabled && !focus,
            'py-3': size === '2xl',
            'py-[13px]': size === 'xl',
          }
        )}
        htmlFor={name}
      >
        {prefix && <span>{prefix}</span>}
        <input
          className={clsx(
            'flex-grow font-semibold focus:outline-none min-w-0',
            'placeholder:text-grey-solid-4',
            {
              'cursor-not-allowed': disabled,
              'text-lg': size === '2xl',
              'text-md': size === 'xl',
            }
          )}
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          onBlur={() => setFocus(false)}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setFocus(true)}
          ref={ref}
          required={required}
          placeholder={placeholder}
          type={type}
          value={value}
          {...inputProps}
        />
        {suffix && <span>{suffix}</span>}
      </label>
    )
  }
)
