import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { Content, Layout } from '../templates'
import { AboutUsPageStoryblok } from '../../../types'
import { Heading } from '../molecules'

export const AboutUsPage = ({ blok }: { blok: AboutUsPageStoryblok }) => (
  <Layout>
    <Content padding="py-16 md:pt-24">
      <Heading text={blok.text} title={blok.title} />
      <div className="flex flex-col lg:w-2/3">
        {blok.head?.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </div>
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
