import clsx from 'clsx'
import { PlatformButton } from '../../atoms/PlatformButton'

interface Props {
  app: 'hole19' | 'CORE'
  caption?: string
  captionClass?: string
  onClick: () => void
  variant: 'xl' | 'lg' | 'md' | 'sm'
}

export const BannerButtons = ({ app, caption, captionClass, onClick, variant }: Props) => (
  <div className={clsx("flex-1", {
    'flex flex-grow flex-col items-center gap-y-4': Boolean(caption),
    'max-w-[410px]': variant !== 'sm',
    'max-w-[250px]': variant === 'sm',
    'flex flex-col md:items-start': variant === 'md'
  })}>
    {caption && (
      <h4
        className={clsx(
          captionClass,
          'font-semibold text-center text-lg text-grey-solid-0'
        )}
      >
        {caption}
      </h4>
    )}
    <div className="flex flex-1 justify-center gap-x-2 lg:w-full">
      <PlatformButton app={app} onClick={onClick} variant="ios" />
      <PlatformButton app={app} onClick={onClick} variant="android" />
    </div>
  </div>
)
