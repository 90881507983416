import { AdvertiseCoverageStoryblok } from '../../../../types'
import { AdvertiseSection } from '../AdvertiseSection/AdvertiseSection'

interface Props {
  text: string
  title: string
  image: {
    alt: string | null
    filename: string | null
  }
}

export const AdvertiseCoverage = ({ text, title, image }: Props) => (
  <AdvertiseSection className="max-w-full" text={text} title={title}>
    {image.filename && (
      <div className="aspect-9/5 relative w-full">
        <img
          alt={image.alt || title}
          className="absolute inset-0 object-cover h-full w-full"
          src={image.filename}
        />
      </div>
    )}
  </AdvertiseSection>
)

export const AdvertiseCoverageSb = ({
  blok,
}: {
  blok: AdvertiseCoverageStoryblok
}) => <AdvertiseCoverage {...blok} />
