import clsx from 'clsx'

export interface LiveStatusProps {
  live: boolean
}

export const LiveStatus = ({ live }: LiveStatusProps) => (
  <div className="inline-flex flex-col items-center justify-center relative">
    <div
      className={clsx('h-1 rounded w-1', {
        'bg-secondary-solid-1': live,
        'bg-danger-solid-1': !live,
      })}
    />
    {live && (
      <div className="absolute inset-0 animate-pulse h-1 ring ring-secondary-alpha-1 rounded w-1" />
    )}
  </div>
)
