import { CardsSectionStoryblok } from '../../../../types'
import { Card, CardProps } from '../Card/Card'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  items: CardProps[]
  text: string
  title: string
}

export const CardsSection = ({ items, text, title }: Props) => (
  <section className="flex flex-col items-center max-w-3xl mx-auto w-full">
    <h2 className="font-semibold mb-2 md:mb-3 text-xl md:text-dsm text-center">
      {title}
    </h2>
    <GlossaryText
      className="max-w-[560px] mb-6 md:mb-11 text-grey-solid-2 text-sm md:text-lg text-center"
      text={text}
    />
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {items.map((item) => (
        <Card key={item.title} {...item} />
      ))}
    </div>
  </section>
)

export const CardsSectionSb = ({ blok }: { blok: CardsSectionStoryblok }) => (
  <CardsSection {...blok} items={blok.items || []} />
)
