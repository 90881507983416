import React from 'react';

import { AuthenticationProvider } from './stories/providers/AuthenticationProvider.tsx';
import { ApiProvider } from './stories/providers/ApiProvider.tsx';
import { AppStateProvider } from './stories/providers/AppStateProvider.tsx';
import { GlossaryProvider } from './stories/providers/GlossaryProvider.tsx';

import { DiscoverCoursesPage } from './stories/pages/DiscoverCoursesPage.tsx';

const DiscoverCourses = (props) => (
  <AppStateProvider webview={props.webview}>
    <ApiProvider pop={props.pop} rex={props.rex}>
      <AuthenticationProvider>
        <GlossaryProvider glossary={{ terms: [] }}>
          <DiscoverCoursesPage {...props} />
        </GlossaryProvider>
      </AuthenticationProvider>
    </ApiProvider>
  </AppStateProvider>
);

export default DiscoverCourses;
