import clsx from 'clsx'

import { Icon, IconProps } from '../Icon'

interface Props {
  active?: boolean
  fontWeight?: 'normal' | 'semibold'
  icon?: IconProps['icon']
  handleClick?: () => void
  handleMouseEnter?: () => void
  href?: string
  target?: string
  text: string
}

export const NavButton = ({
  active = false,
  fontWeight = 'normal',
  icon,
  handleClick,
  handleMouseEnter,
  href,
  target,
  text,
}: Props) => {
  const classNames = clsx(
    'flex items-center gap-x-2 py-[12px] rounded-sm',
    'hover:text-grey-solid-0 hover:bg-grey-solid-7',
    'ease-in-out duration-200 transition-colors',
    'text-ellipsis',
    {
      'pl-4 pr-3': Boolean(icon),
      'px-4': !Boolean(icon),
      'text-grey-solid-2': !active,
      'bg-grey-solid-7 text-grey-solid-0': active,
    }
  )

  const children = (
    <>
      <span className={`font-${fontWeight} text-md truncate`}>{text}</span>
      {icon && <Icon icon={icon} size="20" />}
    </>
  )

  return href ? (
    <a
      className={classNames}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      href={href}
      target={target}
    >
      {children}
    </a>
  ) : (
    <button
      className={classNames}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      type="button"
    >
      {children}
    </button>
  )
}
