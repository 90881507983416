import {
  FeatureInfoItemStoryblok,
  RichtextStoryblok,
} from '../../../../types/storyblok'

import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  text: string | RichtextStoryblok
  title: string
}

export const FeatureInfoItem = ({ text, title }: Props) => (
  <div className="flex flex-col flex-1 space-y-4 lg:pr-15">
    <h3 className="font-semibold text-sm lg:text-lg">{title}</h3>
    <GlossaryText
      className="text-xs lg:text-sm text-grey-solid-2 whitespace-pre-line"
      text={text}
    />
  </div>
)

export const FeatureInfoItemSb = ({
  blok,
}: {
  blok: FeatureInfoItemStoryblok
}) => <FeatureInfoItem {...blok} />
