import { AspectRatio, ImageAsset, ImageDimensions } from '../../../types'

const calculateDimensions = (width: number, ratio: AspectRatio): ImageDimensions => {
  const [widthRatio, heightRatio] = ratio.split('/').map(Number)
  return {
    width: Math.round(width),
    height: Math.round((width * heightRatio) / widthRatio)
  }
}

const getImageUrlParts = (filename: string) => {
  const parts = filename.split('/')
  return {
    baseUrl: parts.slice(0, -1).join('/'),
    filename: parts.pop() || ''
  }
}

const buildStoryblokUrl = (
  baseUrl: string,
  filename: string,
  dimensions: ImageDimensions,
  focalPoint?: string | null
) =>{
  const focal = focalPoint ? `filters:focal(${focalPoint})` : ''
  return `${baseUrl}/${filename}/m/${dimensions.width}x${dimensions.height}/${focal}`
}

export const getAspectRatioClasses = (ratio: AspectRatio) => ({
  'aspect-1/1': ratio === '1/1',
  'aspect-2/3': ratio === '2/3',
  'aspect-3/2': ratio === '3/2',
  'aspect-4/3': ratio === '4/3',
  'aspect-4/5': ratio === '4/5',
  'aspect-16/9': ratio === '16/9',
  'aspect-9/16': ratio === '9/16'
})

export const getOptimizedImageUrl = (
  image: ImageAsset,
  containerWidth: number,
  ratio: AspectRatio
): string => {
  if (!image.filename) return ''

  const dpr = typeof window !== 'undefined' ? window.devicePixelRatio : 1
  const dimensions = calculateDimensions(containerWidth, ratio)
  const scaledDimensions = {
    width: Math.round(dimensions.width * dpr),
    height: Math.round(dimensions.height * dpr)
  }

  const { baseUrl, filename } = getImageUrlParts(image.filename)
  return buildStoryblokUrl(baseUrl, filename, scaledDimensions, image.focus)
}

export const getPlaceholderImageUrl = (
  image: ImageAsset,
  ratio: AspectRatio
): string =>{
  if (!image.filename) return ''

  const dimensions = calculateDimensions(150, ratio)
  const { baseUrl, filename } = getImageUrlParts(image.filename)
  return buildStoryblokUrl(baseUrl, filename, dimensions, image.focus)
}
