import { FeatureHeadingStoryblok } from '../../../../types/storyblok'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  category: string
  text: string
  title: string
}

export const FeatureHeading = ({ category, text, title }: Props) => (
  <section className="flex flex-col items-center justify-center">
    <div className="flex flex-col lg:max-w-3xl space-y-2 text-center">
      <p className="font-semibold text-md text-primary-solid-2">{category}</p>
      <div className="flex flex-col items-center justify-center space-y-4">
        <h2 className="font-semibold text-dxs lg:text-dmd">{title}</h2>
        <GlossaryText
          className="text-grey-solid-2 text-md lg:text-xl"
          text={text}
        />
      </div>
    </div>
  </section>
)

export const FeatureHeadingSb = ({
  blok,
}: {
  blok: FeatureHeadingStoryblok
}) => <FeatureHeading {...blok} />
