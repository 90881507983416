import clsx from 'clsx'

interface Props {
  active?: string
  items: {
    name: string
    value: string | undefined
  }[]
  onClick?: (value?: string) => void
  title: string
}

export const Categories = ({ active, items, onClick, title }: Props) => (
  <nav
    aria-label="categories"
    className="flex flex-col font-semibold space-y-4 w-full"
  >
    <h3 className="text-primary-solid-2 text-sm">{title}</h3>
    <div className="overflow-x-auto">
      <ol className="flex flex-row flex-nowrap items-center gap-x-4 gap-y-2 md:items-stretch md:flex-col md:gap-x-0 text-md">
        {items.map((item, index) => (
          <li key={`${item.value}-${index}`}>
            <button
              className={clsx(
                'border-b-2 py-1 text-grey-solid-3 whitespace-nowrap',
                'md:border-b-0 md:border-l-2 md:px-3',
                {
                  'border-primary-solid-2 text-primary-solid-2':
                    active === item.value,
                  'border-transparent': active !== item.value,
                }
              )}
              onClick={() => onClick?.(item.value)}
              type="button"
            >
              {item.name}
            </button>
          </li>
        ))}
      </ol>
    </div>
  </nav>
)
