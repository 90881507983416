import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import { HandicapPageStoryblok } from '../../../types/storyblok'
import { Content, Layout } from '../templates'

export const HandicapPage = ({ blok }: { blok: HandicapPageStoryblok }) => (
  <Layout {...storyblokEditable(blok)}>
    <Content>
      {blok.head.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
      {blok.body.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
