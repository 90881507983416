import i18n from '../../assets/locales.json'

interface Props {
  value: string
}

export const Step = ({ value }: Props) => (
  <div
    aria-label={`${i18n.global.step} ${value}`}
    className="bg-primary-solid-6 flex items-center justify-center font-semibold h-12 rounded-full text-dsm w-12"
  >
    {value}
  </div>
)
