import { format, isValid } from 'date-fns';

import i18n from '../../assets/locales.json'

interface Props {
  className?: string;
  date: string;
}

export const PublishedAt = ({ className = '', date }: Props) => {
  const day = new Date(date.replace(' ', 'T') + ':00.000Z')

  if (!day || isValid(new Date(day)) === false) {
    return null;
  }

  return (
    <time className={className} dateTime={date}>
      {i18n.global.published} {format(day, 'dd MMM yyyy')}
    </time>
  );
};
