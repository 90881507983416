import i18n from '../../assets/locales.json'

import { HolesScore } from './HolesScore'
import { HolesTitles } from './HolesTitles'
import { HolesValues } from './HolesValues'

interface Props {
  holes: GolfRound['holes']
  isNet: boolean
  isStableford: boolean
}

export const RoundHoles = ({ holes, isNet, isStableford }: Props) => {
  if (holes.length === 0) {
    return null
  }

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-2 md:col-span-1 sticky left-0 z-10">
        <HolesTitles />
      </div>
      <div className="col-span-10 md:col-span-11 flex overflow-x-auto">
        <HolesValues
          holes={holes.slice(0, 9)}
          isNet={isNet}
          isStableford={isStableford}
        />
        <HolesScore
          holes={holes.slice(0, 9)}
          isNet={isNet}
          isStableford={isStableford}
          title={i18n.global.out}
        />
        <HolesValues
          holes={holes.slice(9, 18)}
          isNet={isNet}
          isStableford={isStableford}
        />
        <HolesScore
          holes={holes.slice(9, 18)}
          isNet={isNet}
          isStableford={isStableford}
          title={i18n.global.out}
        />
        <HolesScore
          holes={holes}
          isNet={isNet}
          isStableford={isStableford}
          title={i18n.global.total}
        />
      </div>
    </div>
  )
}
