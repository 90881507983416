import { ISbComponentType } from 'storyblok-js-client'

import i18n from '../../assets/locales.json'
import { Label } from '../Label/Label'

interface Props {
  data: {
    tee: string
    distance: number | string
    par: number | string
    cr: number | string
    sr: number | string
  }[]
  title: string
}

export const CourseScorecard = ({ data, title }: Props) => (
  <Label text={title}>
    <section className="border border-grey-solid-6 px-4 md:px-5 py-4 rounded">
      <div className="flex border-b border-grey-solid-6 mb-4 pb-3 pt-2 text-grey-solid-3 text-sm">
        <h4 className="w-1/5">{i18n.scorecard.column1}</h4>
        <h4 className="w-1/5 text-center">{i18n.scorecard.column2}</h4>
        <h4 className="w-1/5 text-center">{i18n.scorecard.column3}</h4>
        <h4 className="w-1/5 text-center">{i18n.scorecard.column4}</h4>
        <h4 className="w-1/5 text-right">{i18n.scorecard.column5}</h4>
      </div>
      <div className="flex flex-col space-y-4">
        {data.map((row, index) => (
          <div key={index} className="flex py-2 text-md">
            <p className="w-1/5 font-medium">{row.tee}</p>
            <p className="w-1/5 text-center">{row.distance}</p>
            <p className="w-1/5 text-center">{row.par}</p>
            <p className="w-1/5 text-center">{row.cr}</p>
            <p className="w-1/5 font-bold text-primary-solid-2 text-right">
              {row.sr}
            </p>
          </div>
        ))}
      </div>
    </section>
  </Label>
)

export type CourseScorecardStoryblok = ISbComponentType<'CourseScorecard'> &
  Props

export const CourseScorecardSb = ({
  blok,
}: {
  blok: CourseScorecardStoryblok
}) => <CourseScorecard {...blok} />
