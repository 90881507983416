import { GlossaryTerm } from '../../../../types'
import { GlossaryRow } from '../'

interface Props {
  entries: {
    letter: string
    terms: GlossaryTerm[]
  }[]
}

export const GlossaryGrid = ({ entries }: Props) => (
  <div className="flex flex-col divide-y divide-grey-solid-7 gap-y-10">
    {entries.map(({ letter, terms }) => (
      <GlossaryRow key={letter} letter={letter} terms={terms} />
    ))}
  </div>
)
