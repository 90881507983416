import { Icon } from '../../atoms';
import { Asset } from '../Asset/Asset';
import { Author, AuthorProps } from '../Author/Author';

export interface PostCardProps {
  author?: AuthorProps;
  category?: string | (string | number)[];
  image: {
    alt: string | null;
    filename: string | null;
  };
  text?: string;
  title: string;
  url: string;
}

export const PostCard = ({
  author,
  category,
  image,
  text,
  title,
  url,
}: PostCardProps) => (
  <article>
    <a className="flex flex-col space-y-4" href={`/${url}`}>
      <Asset
        className="md:aspect-9/5 rounded"
        image={image}
        ratio="3/2"
      />
      <div className="flex flex-col space-y-2">
        {category && (
          <h4 className="font-semibold text-primary-solid-2 text-sm">
            {category}
          </h4>
        )}
        <div className="flex justify-between space-x-3">
          <h3 className="font-semibold line-clamp-2 text-dxs">{title}</h3>
          <Icon className="shrink-0" icon="arrow_top_right" />
        </div>
        {text && <p className="text-grey-solid-2">{text}</p>}
      </div>
      {author && <Author {...author} />}
    </a>
  </article>
);
