import clsx from 'clsx'

import { FooterBar } from './FooterBar'
import { FooterLdJson } from './Footer.ldjson'
import { FooterLink, Item } from './FooterLink'
import { FooterLogo } from './FooterLogo'

import i18n from '../../assets/locales.json'
import constants from '../../assets/constants.json'

interface Props {
  className?: string
  items?: Item[]
}

export const Footer = ({
  className = '',
  items = [
    {
      title: i18n.global.hole19GolfApp,
      links: [
        { text: i18n.footer.allFeatures, url: constants.routes.hole19.root },
        { text: i18n.global.appleDevices, url: constants.routes.hole19.apple },
        {
          text: i18n.global.androidDevices,
          url: constants.routes.hole19.android,
        },
        { text: i18n.global.premium, url: constants.routes.hole19.premium },
      ],
    },
    {
      title: i18n.global.coreGolfApp,
      links: [
        { text: i18n.footer.allFeatures, url: constants.routes.coreGolf.root },
        {
          text: i18n.global.appleDevices,
          url: constants.routes.coreGolf.apple,
        },
        { text: i18n.global.premium, url: constants.routes.coreGolf.premium },
      ],
    },
    {
      title: i18n.footer.forGolfers,
      links: [
        { text: i18n.global.shop, url: constants.routes.shop },
        { text: i18n.global.discoverCourses, url: constants.routes.courses },
        {
          text: i18n.footer.hcpCalculator,
          url: constants.routes.calculateHandicap,
        },
      ],
    },
    {
      title: i18n.global.blog,
      links: [
        { text: i18n.footer.allPosts, url: constants.routes.blog.root },
        {
          text: i18n.global.courses,
          url: constants.routes.blog.category1,
        },
        {
          text: i18n.global.gameImprovement,
          url: constants.routes.blog.category2,
        },
        {
          text: i18n.global.reviews,
          url: constants.routes.blog.category3,
        },
      ],
    },
    {
      title: i18n.footer.partners,
      links: [
        { text: i18n.footer.advertise, url: constants.routes.advertise },
        { text: i18n.footer.pressKit, url: constants.routes.pressKit },
      ],
    },
    {
      title: i18n.footer.about,
      links: [
        { text: i18n.footer.aboutUs, url: constants.routes.aboutUs },
        { text: i18n.footer.careers, url: constants.routes.careers },
        { text: i18n.footer.contacts, url: constants.routes.contact },
      ],
    },
    {
      title: i18n.footer.support,
      links: [
        {
          text: i18n.footer.helpCenter,
          target: 'new',
          url: constants.routes.helpCenter,
        },
        {
          text: i18n.footer.termsConditions,
          url: constants.routes.termsAndConditions,
        },
      ],
    },
  ],
}: Props) => (
  <footer
    className={clsx(className, 'bg-grey-solid-0 text-grey-solid-4 w-full')}
  >
    <div className="max-w-7xl px-4 lg:px-8 mx-auto flex flex-col lg:flex-row lg:items-start justify-between py-10 lg:space-x-4">
      <FooterLogo />
      <div className="columns-1 lg:columns-3 mt-13 lg:mt-0 gap-x-6 space-y-4 lg:space-y-6">
        {items.map((item) => (
          <FooterLink key={item.title} item={item} />
        ))}
      </div>
    </div>
    <FooterBar />
    <FooterLdJson />
  </footer>
)
