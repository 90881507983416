import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { AspectRatio, ImageAsset } from '../../../../types'
import { getAspectRatioClasses, getOptimizedImageUrl, getPlaceholderImageUrl } from '../../helpers'

interface Props {
  className?: string
  image: ImageAsset
  ratio: AspectRatio
}

export const Asset = ({ className, image, ratio }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [imageUrl, setImageUrl] = useState(getPlaceholderImageUrl(image, ratio))
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!containerRef.current || !image.filename) return

    const { offsetWidth } = containerRef.current
    const newUrl = getOptimizedImageUrl(image, offsetWidth, ratio)
    setImageUrl(newUrl)
    setIsLoading(false)
  }, [image.filename, image.focus, ratio])

  return (
    <div
      ref={containerRef}
      className={clsx(
        'bg-grey-solid-6 flex flex-col overflow-hidden relative w-full',
        getAspectRatioClasses(ratio),
        className
      )}
    >
      <img
        alt={image.alt || ''}
        className={clsx('absolute inset-0 h-full object-cover w-full', {
          'blur-sm': isLoading
        })}
        src={imageUrl}
      />
    </div>
  )
}
