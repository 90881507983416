import {
  render,
  NODE_PARAGRAPH,
  NODE_HEADING,
  MARK_LINK,
  MARK_BOLD,
  MARK_ITALIC,
} from 'storyblok-rich-text-react-renderer'

import { GlossaryLink } from '../GlossaryLink/GlossaryLink'
import { RichtextStoryblok, TextStoryblok } from '../../../../types'
import { useGlossary } from '../../hooks'

interface Props {
  text: RichtextStoryblok
}

export const Text = ({ text }: Props) => {
  const html = render(text, {
    markResolvers: {
      [MARK_LINK]: (children, { href, linktype, target }) => {
        if (href && linktype === 'glossary') {
          return <GlossaryLink href={href}>{children}</GlossaryLink>
        }
        return (
          <a
            className="underline text-primary-solid-2"
            href={href}
            target={target}
          >
            {children}
          </a>
        )
      },
      [MARK_BOLD]: (children) => <strong>{children}</strong>,
      [MARK_ITALIC]: (children) => <em>{children}</em>,
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children) => (
        <p className="text-lg text-grey-solid-2">{children}</p>
      ),
      [NODE_HEADING]: (children, { level }) => {
        if (level === 2) {
          return <h2 className="font-semibold text-dsm">{children}</h2>
        }
        return <div>{children}</div>
      },
    },
  })
  return <>{html}</>
}

export const TextSb = ({ blok }: TextStoryblok) => {
  const { withGlossary } = useGlossary()

  return (
    <div className="flex flex-col space-y-4">
      <Text text={withGlossary(blok.text)} />
    </div>
  )
}
