import clsx from 'clsx'

import { Button } from '../../atoms'
import { CtaCardStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'

interface Props {
  buttonColor: 'highlights-core' | 'primary-solid-2'
  buttonText: string
  buttonUrl: string
  image: {
    alt: string | null
    filename: string | null
  }
  text: string
  title: string
  variant: 'dark' | 'light'
}

export const CTACard = ({
  buttonColor,
  buttonText,
  buttonUrl,
  image,
  text,
  title,
  variant,
}: Props) => (
  <section
    className={clsx(
      'bg-grey-solid-6 lg:aspect-8/3 relative rounded w-full',
      'flex flex-col justify-center',
      'px-8 md:px-16 lg:px-24',
      'py-12 md:py-16'
    )}
  >
    <div className="flex flex-col items-start lg:w-2/3 z-[1]">
      <h2
        className={clsx('font-semibold mb-2 text-dsm lg:text-dlg', {
          'text-white-solid-1': variant === 'dark',
        })}
      >
        {title}
      </h2>
      <p
        className={clsx('mb-4 text-md md:text-lg', {
          'text-grey-solid-2': variant === 'light',
          'text-white-solid-1': variant === 'dark',
        })}
      >
        {text}
      </p>
      <Button
        color={buttonColor}
        href={buttonUrl}
        size="xl"
        textColor={
          buttonColor === 'highlights-core'
            ? 'text-grey-solid-0'
            : 'text-white-solid-1'
        }
        text={buttonText}
      />
    </div>
    {image.filename && (
      <img
        alt={image.alt || title}
        className="absolute inset-0 h-full rounded object-cover w-full"
        src={image.filename}
      />
    )}
    <div
      className={clsx(
        'absolute bg-gradient-to-r from-10% inset-0 rounded to-transparent via-80% via-transparent',
        {
          'from-grey-solid-0/75': variant === 'dark',
          'from-separator-solid-0': variant === 'light',
        }
      )}
    />
  </section>
)

export const CTACardSb = ({ blok }: { blok: CtaCardStoryblok }) => (
  <CTACard {...blok} buttonUrl={storyblokLink(blok.buttonUrl)} />
)
