import { Score } from '../Score/Score'

interface Props {
  holes: GolfRound['holes']
  isNet: boolean
  isStableford: boolean
}

export const HolesValues = ({ holes, isNet, isStableford }: Props) => {
  if (holes.length === 0) {
    return null
  }

  return holes.map((hole) => (
    <div className="divide-y divide-grey-alpha-3 flex-1 flex-col font-semibold min-w-14 text-center">
      <div className="py-1 text-grey-solid-5 text-xs">{hole.sequence}</div>
      <div className="py-1 text-grey-solid-3 text-sm">{hole.par}</div>
      <div className="py-1 text-grey-solid-3 text-sm">{hole.strokeIndex}</div>
      <div className="flex items-center justify-center h-11">
        <Score
          colorName={hole.scoreColorName}
          score={hole.totalOfStrokes}
          scratched={Boolean(hole.isScratched)}
          sup={
            isStableford
              ? isNet
                ? hole.stablefordNet
                : hole.stablefordGross
              : undefined
          }
        />
      </div>
    </div>
  ))
}
