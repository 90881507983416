import { createContext } from 'react'

export type AuthenticationContextType = {
  user?: {
    full_name: string
    premium_at: string
    avatar_url: string
  }
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  user: undefined,
})
