import { ReactNode, useMemo, useState } from 'react'

import {
  ApiContext,
  ApiContextType,
  ApiService,
} from './ApiContext'

interface Props {
  children: ReactNode
  pop?: ApiService
  rex?: ApiService
}

export const ApiProvider = ({ children, pop, rex }: Props) => {
  const [api] = useState<ApiContextType>({ rex, pop })

  const value = useMemo(() => ({ ...api }), [api])

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  )
}
