import clsx from 'clsx'

export interface Props {
  icon:
    | 'clear-day'
    | 'clear-night'
    | 'cloudy'
    | 'fog'
    | 'partly-cloudy-day'
    | 'partly-cloudy-night'
    | 'rain'
    | 'snow'
    | 'wind'
}

export const ForecastIcon = ({ icon }: Props) => (
  <div className="h-8 overflow-hidden relative w-8">
    <img
      alt={icon}
      className={clsx('h-8 min-w-[360px] w-[360px]', {
        'translate-x-0': icon === 'clear-day',
        '-translate-x-[40px]': icon === 'clear-night',
        '-translate-x-[80px]': icon === 'cloudy',
        '-translate-x-[120px]': icon === 'fog',
        '-translate-x-[160px]': icon === 'partly-cloudy-day',
        '-translate-x-[200px]': icon === 'partly-cloudy-night',
        '-translate-x-[240px]': icon === 'rain',
        '-translate-x-[280px]': icon === 'snow',
        '-translate-x-[320px]': icon === 'wind',
      })}
      height="40"
      src="https://a-us.storyblok.com/f/1022273/720x80/7eec34527f/forecast.png?cv=1739357664777"
      width="360px"
    />
  </div>
)
