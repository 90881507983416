import { createContext } from 'react'

export interface State {
  filter_query?: { categories: { in: string } }
  is_startpage?: boolean
  page?: number
  per_page?: number
  resolve_relations?: string
  search_term?: string
  starts_with?: string
}

interface Props {
  data: unknown[]
  rels: unknown[]
  loading: boolean
  error: boolean
  state: State
  total: number
  updateState: (value: State) => void
}

export const PaginationContext = createContext<Props>({
  data: [],
  rels: [],
  loading: false,
  error: false,
  state: {
    page: 1,
    per_page: 6,
  },
  total: 1,
  updateState: () => {},
})
