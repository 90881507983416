import clsx from 'clsx'
import { useCallback, useRef, useState } from 'react'

import { useClickOutside } from '../../hooks'
import { Icon } from '../../atoms'
import { Tee } from '..'

export type Option = {
  key: number | string
  tee?: string
  value: number | string
}

interface Props {
  disabled?: boolean
  onChange?: (val: Option) => void
  options: Option[]
  prefix?: string
  size?: 'xl' | '2xl'
  tee?: string
  text: string
}

export const Dropdown = ({
  disabled = false,
  onChange,
  options,
  prefix,
  size = '2xl',
  tee,
  text,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  const handleSelect = useCallback(
    (option: Option) => {
      onChange?.(option)
      setOpen(false)
    },
    [onChange]
  )

  const handleClickOutside = useCallback(() => {
    setOpen(false)
  }, [])

  useClickOutside(ref, handleClickOutside)

  return (
    <div ref={ref} className="flex relative rounded-sm">
      <button
        className={clsx(
          'border border-separator-solid-1 rounded-sm',
          'flex items-center justify-between px-4 space-x-2 w-full',
          'font-semibold focus:outline-none focus:shadow-inputFocus',
          {
            'bg-grey-alpha-4 text-grey-solid-5 cursor-not-allowed': disabled,
            'bg-white-solid-1 text-grey-solid-2 hover:shadow-input': !disabled,
            'py-[13px] text-md': size === 'xl',
            'py-3 text-lg': size === '2xl',
          }
        )}
        disabled={disabled}
        onClick={() => setOpen((prev) => !prev)}
        type="button"
      >
        <span className="flex items-center truncate space-x-2">
          {prefix && (
            <span
              className={clsx('font-normal', {
                'text-grey-solid-4': !disabled,
              })}
            >
              {prefix}
            </span>
          )}
          <span className="capitalize min-w-0 truncate">{text}</span>
        </span>
        <Icon
          className={clsx({
            'text-grey-solid-3': !disabled,
            'text-grey-solid-4': disabled,
          })}
          icon="chevron_down"
          size={size === 'xl' ? '20' : 'md'}
        />
      </button>
      {open ? (
        <div
          className={clsx(
            'absolute w-full z-10',
            'bg-white-solid-1 flex flex-col py-2',
            'border border-separator-solid-1 rounded-sm shadow-dropdown',
            {
              'top-12': size === 'xl',
              'top-14': size === '2xl',
            }
          )}
        >
          {options.map((option) => (
            <button
              key={option.key}
              className={clsx(
                'flex items-center space-x-2 font-normal hover:bg-grey-alpha-6 py-[13px] px-4',
                'text-grey-solid-4 hover:text-grey-solid-0 text-md text-left transition-colors'
              )}
              onClick={() => handleSelect(option)}
              type="button"
            >
              {option.tee && (
                <Tee color={option.tee} letter={`${option.key}`[0]} />
              )}
              <span className="capitalize">{option.key}</span>
            </button>
          ))}
        </div>
      ) : null}
    </div>
  )
}
