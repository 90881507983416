import clsx from 'clsx'

import LogoSVG from '../assets/logo.svg'
import LogoLightSVG from '../assets/logo_light.svg'
import Hole19SymbolSVG from '../assets/symbol.svg'

interface Props {
  className?: string
  variant: 'default' | 'defaultLight' | 'symbol'
}

export const Hole19Logo = ({ className = '', variant }: Props) =>
  variant === 'symbol' ? (
    <div className={clsx(className, 'bg-primary-solid-2 rounded')}>
      <img
        alt="Hole19 symbol"
        height="70px"
        src={Hole19SymbolSVG}
        width="70px"
      />
    </div>
  ) : (
    <div className={className}>
      <img
        alt="Hole19 logo"
        className="w-full"
        src={variant === 'defaultLight' ? LogoLightSVG : LogoSVG}
      />
    </div>
  ) 