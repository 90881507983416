import { Asset } from "../Asset/Asset"
import { AspectRatio, ImageAsset, ImageStoryblok } from "../../../../types"

interface Props {
  caption: string
  image: ImageAsset
  ratio: AspectRatio
}

export const Image = ({ caption, image, ratio }: Props) => (
  <figure className="flex flex-col space-y-3">
    <Asset
      className="rounded"
      image={{ ...image, alt: image.alt || caption }}
      ratio={ratio}
    />
    <figcaption className="text-sm text-grey-solid-2">
      {caption}
    </figcaption>
  </figure>
)

export const ImageSb = ({ blok }: ImageStoryblok) => (
  <Image {...blok} />
)
