import { Icon } from '../../atoms/Icon'
import Icons from '../../atoms/Icons'

import i18n from '../../assets/locales.json'
import constants from '../../assets/constants.json'

const socials: {
  icon: keyof typeof Icons
  label: string
  url: string
}[] = [
  {
    icon: 'instagram',
    label: i18n.footer.instagram,
    url: constants.routes.instagram,
  },
  {
    icon: 'twitter',
    label: i18n.footer.twitter,
    url: constants.routes.twitter,
  },
  {
    icon: 'linkedin',
    label: i18n.footer.linkedin,
    url: constants.routes.linkedin,
  },
  {
    icon: 'facebook',
    label: i18n.footer.facebook,
    url: constants.routes.facebook,
  },
]

export const FooterBar = () => (
  <section className="flex flex-col space-y-2 max-w-7xl px-4 lg:px-8 mx-auto py-8 md:py-10">
    <div className="flex flex-col items-center md:flex-row md:justify-between space-y-2 md:space-y-0">
      <small className="text-md">
        &copy; {new Date().getFullYear()} {i18n.global.hole19}
      </small>
      <nav aria-label={i18n.global.socialMediaLinks} className="flex space-x-5">
        {socials.map((social) => (
          <a
            key={social.label}
            aria-label={social.label}
            href={social.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon icon={social.icon} />
          </a>
        ))}
      </nav>
    </div>
    <div className="flex justify-center md:justify-start py-6">
      <a href={constants.routes.pt2020}>
        <img
          alt={i18n.global.footerLogos}
          className="max-w-75 w-full"
          src={constants.footerLogos}
        />
      </a>
    </div>
  </section>
)
