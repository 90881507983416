import { ReactNode } from 'react'
import clsx from 'clsx'

import { HeroStoryblok } from '../../../../types/storyblok'
import { PlatformButton, RichText } from '../../atoms'

interface Props {
  app: 'hole19' | 'CORE'
  background?: {
    alt: string | null
    filename: string | null
  }
  caption?: string
  color: 'separator-solid-0' | 'white-solid-1'
  image?: {
    alt: string | null
    filename: string | null
  }
  handleClick: (platform: string) => void
  text: string | ReactNode
  title: string
}

export const Hero = ({
  app,
  background,
  caption,
  color = 'white-solid-1',
  image,
  handleClick,
  text,
  title,
}: Props) => (
  <section
    className={clsx(
      `bg-${color} flex flex-col relative w-full`,
      {
        'pt-5 md:pt-10 lg:pt-22': Boolean(image?.filename),
        'pt-24 pb-50 md:py-32 lg:py-50': !Boolean(image?.filename),
      }
    )}
  >
    <div className="mx-auto max-w-7xl px-4 lg:px-8 relative w-full z-[1]">
      <section className="flex flex-col md:items-start md:justify-between md:flex-row md:space-x-12">
        <div className="flex flex-1 flex-col lg:max-w-[530px]">
          <h1 className="font-semibold mb-4 text-dmd lg:text-dlg">{title}</h1>
          <div className="mb-6 text-grey-solid-2 text-md lg:text-xl">{text}</div>
          {caption && <p className="font-semibold mb-4 text-lg">{caption}</p>}
          <div className="flex items-center max-w-[340px] space-x-2 lg:space-x-3">
            <PlatformButton app={app} onClick={() => handleClick('ios')} variant="ios" />
            <PlatformButton
              app={app}
              onClick={() => handleClick('android')}
              variant="android"
            />
          </div>
        </div>
        {image?.filename && (
          <div className="flex flex-1 flex-col items-center mt-2 lg:mt-0">
            <div className="aspect-1/2 max-h-[280px] md:max-h-[460px] lg:max-h-[600px]">
              <img alt={image.alt || ''} src={image.filename} />
            </div>
          </div>
        )}
      </section>
    </div>
    {background?.filename && (
      <img
        alt={background.alt || ''}
        className="absolute inset-0 object-cover h-full w-full"
        src={background.filename}
      />
    )}
  </section>
)

export const HeroSb = ({
  blok,
  onPlatformClick,
}: {
  blok: HeroStoryblok
  onPlatformClick: (platform: string) => void
}) => (
  <Hero
    app={blok.app}
    background={blok.background}
    caption={blok.caption}
    color={blok.color}
    image={blok.image}
    handleClick={onPlatformClick}
    text={<RichText content={blok.text} />}
    title={blok.title}
  />
)
