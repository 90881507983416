import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import { ProductPageStoryblok } from '../../../types/storyblok'
import { Content, Header, Layout } from '../templates'

export const ProductPage = ({ blok }: { blok: ProductPageStoryblok }) => (
  <Layout navColor="bg-separator-solid-0/85" {...storyblokEditable(blok)}>
    <Header>
      {blok.head.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Header>
    <Content>
      {blok.body.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)

export default ProductPage
