import { AdvertiseStatItemStoryblok } from '../../../../types'

export interface AdvertiseStatItemProps {
  name: string
  value: string
}

export const AdvertiseStatItem = ({ name, value }: AdvertiseStatItemProps) => (
  <li className="bg-primary-solid-7 flex flex-col items-center p-8 rounded text-center">
    <p className="font-semibold text-dmd md:text-dlg">{value}</p>
    <p className="font-semibold text-grey-solid-3 text-sm md:text-lg">{name}</p>
  </li>
)

export const AdvertiseStatItemSb = ({ blok }: AdvertiseStatItemStoryblok) => (
  <AdvertiseStatItem {...blok} />
)
