import clsx from 'clsx'
import { ISbComponentType } from 'storyblok-js-client'

import { RatingTag } from '../Tags/Tags'

export interface CourseCardProps {
  compact?: boolean
  description?: string
  href: string
  image: {
    alt: string | null
    filename: string | null
  }
  location: string
  rating?: number | string
  size?: 'sm'
  title: string
}

export const CourseCard = ({
  compact = true,
  description,
  href,
  image,
  location,
  rating,
  size,
  title,
}: CourseCardProps) => (
  <a
    className={clsx(
      'flex border border-grey-solid-6 overflow-hidden rounded-md h-full w-full',
      {
        'flex-col': compact,
        'flex-row': !compact,
      }
    )}
    href={href}
  >
    <div
      className={clsx('flex-none h-[160px] relative', {
        'md:h-[200px] lg:h-[280px] aspect-3/2 lg:aspect-4/3': compact,
        'md:h-[200px] lg:h-[340px] aspect-1/1 lg:aspect-4/3':
          !compact && size !== 'sm',
        'w-[160px] md:aspect-1/1': !compact && size === 'sm',
      })}
    >
      {image.filename && (
        <img
          alt={image.alt || ''}
          className="absolute inset-0 object-cover h-full w-full"
          src={image.filename}
        />
      )}
    </div>
    <div
      className={clsx('flex flex-grow flex-col p-3 w-full', {
        'items-start justify-between': compact,
        'items-start justify-center': !compact,
        'md:justify-between': !compact && size !== 'sm',
        'lg:py-8 lg:pl-8 lg:pr-12': size !== 'sm',
      })}
    >
      <div className={clsx("flex flex-col items-start space-y-2 w-full", {
        'lg:space-y-3': size !== 'sm'
      })}>
        <RatingTag rating={rating} />
        <div className="flex flex-col">
          <h3
            className="font-semibold line-clamp-2 text-md lg:text-xl max-h-[48px] md:max-h-12">
            {title}
          </h3>
          <p className="font-semibold line-clamp-2 text-grey-solid-2 text-xs lg:text-sm">
            {location}
          </p>
        </div>
      </div>
      {description && (
        <div className="hidden  md:block text-grey-solid-2">
          <p className={clsx(
            'line-clamp-2 overflow-hidden text-ellipsis',
            { 'text-xs': compact, 'text-md': !compact })
          }>
            {description.length > 130 ? `${description.slice(0, 130)}...` : description}
          </p>
        </div>
      )}
    </div>
  </a>
)

export type CourseCardStoryblok = ISbComponentType<'CourseCard'> &
  CourseCardProps

export const CourseCardSb = ({ blok }: { blok: CourseCardStoryblok }) => (
  <CourseCard {...blok} />
)
