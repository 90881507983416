import { StoryblokServerComponent } from '@storyblok/react/rsc'
import {
  BreadcrumbsStoryblok,
  CtaBannerStoryblok,
  HeroImageStoryblok,
} from '../../../types/storyblok'

interface Props {
  content: (BreadcrumbsStoryblok | CtaBannerStoryblok | HeroImageStoryblok)[]
}

export const Hero = ({ content }: Props) => {
  const parser = (blok: Props['content'][0]) => {
    switch (blok.component) {
      case 'HeroImage':
        return <StoryblokServerComponent key={blok._uid} blok={blok} />
      case 'Breadcrumbs':
        return (
          <div
            key={blok._uid}
            className="max-w-7xl mx-auto px-4 w-full py-2 lg:px-8"
          >
            <StoryblokServerComponent blok={blok} />
          </div>
        )
      case 'CTABanner':
        return (
          <div
            key={blok._uid}
            className="max-w-7xl mx-auto px-4 w-full lg:px-8 lg:py-2"
          >
            <StoryblokServerComponent blok={blok} />
          </div>
        )
      default:
        return null
    }
  }

  return content.map(parser)
}
