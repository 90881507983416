import { PagesGrid } from '..'
import { useGlossary } from '../../hooks'

interface Props {
  title: string
}

export const GlossaryTermLinks = ({ title }: Props) => {
  const {
    glossary: { links = [] },
  } = useGlossary()

  if (links.length === 0) {
    return null
  }

  return (
    <PagesGrid
      items={links.map((link) => ({
        category: link.category,
        image: link.image,
        title: link.title,
        url: `/${link.full_slug}`,
      }))}
      title={title}
    />
  )
}
