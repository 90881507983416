import i18n from '../../assets/locales.json'
import { PageListItem } from './PageListItem'

export interface PaginationProps {
  current: number
  onClick?: (page: number) => void
  total: number
}

export const Pagination = ({ current, onClick, total }: PaginationProps) => (
  <nav
    aria-label="pagination"
    className="flex items-start font-semibold overflow-x-auto text-grey-solid-2 text-sm"
  >
    <ul className="bg-white-solid-1 border border-grey-solid-6 flex rounded-sm">
      <PageListItem
        active={false}
        disabled={current - 1 < 1}
        onClick={() => onClick?.(current - 1)}
        text={i18n.global.previous}
        variant="prev"
      />
      {Array.from({ length: total }).map((_, page) => (
        <PageListItem
          key={`/${page + 1}`}
          active={page + 1 === current}
          disabled={false}
          onClick={() => onClick?.(page + 1)}
          text={`${page + 1}`}
          variant="page"
        />
      ))}
      <PageListItem
        active={false}
        disabled={current + 1 > total}
        onClick={() => onClick?.(current + 1)}
        text={i18n.global.next}
        variant="next"
      />
    </ul>
  </nav>
)
