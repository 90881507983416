import clsx from 'clsx'

interface Props {
  color: string
  letter: string
  textColor?: string
}

export const Tee = ({ color, letter, textColor }: Props) => {
  const light = ['R', 'B', 'P']
  const upperCase = letter.toUpperCase().slice(0, 1)

  return (
    <span
      className={clsx(
        'flex flex-col items-center justify-center font-bold h-4 rounded-xs shrink-0 text-xs w-4',
        {
          'text-white-solid-1': light.includes(upperCase),
          'text-grey-solid-0': !light.includes(upperCase),
        }
      )}
      style={{ backgroundColor: color }}
    >
      {textColor ? <span style={{ color: textColor }}>{upperCase}</span> : upperCase}
    </span>
  )
}
