import { Button } from '../../atoms'
import { ContactItemStoryblok } from '../../../../types'
import { GlossaryText } from '../GlossaryText/GlossaryText'
import { Link } from '../Link/Link'
import { storyblokLink } from '../../helpers'

interface Props {
  buttonText: string
  buttonUrl: string
  text: string
  title: string
  variant?: 'button' | 'link'
}

export const ContactItem = ({
  buttonText,
  buttonUrl,
  text,
  title,
  variant = 'button',
}: Props) => (
  <section className="flex flex-col items-start lg:max-w-3xl">
    <h2 className="font-semibold mb-2 text-dsm">{title}</h2>
    <GlossaryText className="text-grey-solid-2 mb-4 text-lg" text={text} />
    {variant === 'button' ? (
      <Button
        color="primary-solid-2"
        href={buttonUrl}
        size="xl"
        text={buttonText}
      />
    ) : (
      <Link
        className="text-primary-solid-2"
        icon="arrow_top_right"
        href={buttonUrl}
        textClass="font-semibold text-lg underline"
      >
        {buttonText}
      </Link>
    )}
  </section>
)

export const ContactItemSb = ({ blok }: ContactItemStoryblok) => (
  <ContactItem {...blok} buttonUrl={storyblokLink(blok.buttonUrl)} />
)
