import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import i18n from '../assets/locales.json'
import RoundGroupViewModel from '../../performance/roundGroup/RoundGroupViewModel.es6'

import { RoundGroupsContext } from './RoundGroupsContext'
import { useScoreMode } from '../hooks'

interface Props {
  children: ReactNode
  initData: {
    course: PartialCourse
    id: string
    rounds: Round[]
    scoring_mode: ScoringMode
    started_at: string
    status: 'started' | 'scheduled' | 'closed'
  }
  polling: number
}

export const RoundGroupsProvider = ({ children, initData, polling }: Props) => {
  const [data, setData] = useState(initData)
  const scoreMode = useScoreMode(initData.scoring_mode)

  const [state] = useState({
    courseName: initData.course.name,
    live: initData.status !== 'closed',
    startedAt: initData.started_at,
  })

  const rounds = useMemo(() => {
    const model = RoundGroupViewModel(data, i18n.placeholderImage)
    const isNet = scoreMode.typeValue === 'net'
    return model
      .roundsSortedByScoringModeTypeAndNet(scoreMode.modeValue, isNet)
      .map((round: any) => ({
        ...round,
        byScoringMode: round.scoresByScoringMode(scoreMode.modeValue, isNet),
      }))
  }, [data, scoreMode.modeValue, scoreMode.typeValue])

  const fetchData = useCallback(async () => {
    try {
      const res = await fetch(window.location.href, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
      const json = await res.json()
      if (json.status !== 'closed') {
        setTimeout(fetchData, polling * 1000)
      }
      setData(json)
    } catch (err) {}
  }, [polling])

  useEffect(() => {
    if (initData.status !== 'closed') {
      setTimeout(fetchData, polling * 1000)
    }
  }, [initData.status, polling])

  const value = useMemo(
    () => ({
      rounds,
      ...state,
      scoreMode,
    }),
    [rounds, state, scoreMode]
  )

  return (
    <RoundGroupsContext.Provider value={value}>
      {children}
    </RoundGroupsContext.Provider>
  )
}
