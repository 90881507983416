import { ReactNode } from 'react'

import { AdvertiseSectionHeading } from '../AdvertiseSectionHeading/AdvertiseSectionHeading'
import { Tabs } from '../Tabs/Tabs'
import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { AdvertiseOptionsStoryblok } from '../../../../types'

interface Props {
  items: {
    content: ReactNode
    title: string
  }[]
  title: string
}

export const AdvertiseOptions = ({ items, title }: Props) => (
  <section className="flex flex-col md:pt-16 space-y-6 md:space-y-8">
    <AdvertiseSectionHeading title={title} />
    <Tabs
      className="space-y-6 md:space-y-8"
      defaultOpenIndex={0}
      items={items}
      variant="rounded"
    />
  </section>
)

export const AdvertiseOptionsSb = ({
  blok,
}: {
  blok: AdvertiseOptionsStoryblok
}) => (
  <AdvertiseOptions
    items={blok.items.map((item) => ({
      content: <StoryblokServerComponent blok={item} />,
      title: item.title,
    }))}
    title={blok.title}
  />
)
