import { clsx } from 'clsx'
import { Icon } from '../../atoms'

export interface Props {
  caption: string
  color: 'grey-solid-7' | 'separator-solid-3' | 'separator-solid-4'
  href: string
  text: string
  title: string
  variant?: 'dark' | 'light'
}

export const ImageBanner = ({
  caption,
  color,
  href,
  text,
  title,
  variant = 'light',
}: Props) => (
  <a
    href={href}
    className={clsx(
      'border border-grey-solid-6 flex flex-col h-full overflow-hidden relative rounded-md w-full',
      {
        'text-grey-solid-2': variant === 'light',
        'text-white-solid-1': variant === 'dark',
      }
    )}
  >
    <div
      className={clsx('h-full p-8 md:py-17 z-10', {
        'bg-grey-solid-7': color === 'grey-solid-7',
        'bg-separator-solid-3': color === 'separator-solid-3',
        'bg-separator-solid-4': color === 'separator-solid-4',
      })}
    >
      <div className="flex flex-col max-w-[450px] space-y-13">
        <div className="flex flex-col items-start md:items-center md:flex-row gap-4">
          <div>
            <h3 className="font-semibold text-dsm md:text-dmd">{title}</h3>
            <p className="font-semibold text-xl">{caption}</p>
          </div>
          <div className="shrink-0">
            <Icon
              className={
                variant === 'light' ? 'text-grey-solid-4' : 'text-white-solid-1'
              }
              icon="arrow_circle_right"
              size="xl"
            />
          </div>
        </div>
        <p className="pb-8 md:pb-0 text-xl">{text}</p>
      </div>
    </div>
  </a>
)
