import { ISbStoriesParams } from '@storyblok/react'
import { useEffect, useState } from 'react'

export const usePaginationState = (state: ISbStoriesParams) => {
  const [params, setParams] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (state.search_term) {
      params.set('search', state.search_term)
    } else {
      params.delete('search')
    }

    if (state.filter_query?.categories?.in) {
      params.set('category', state.filter_query?.categories?.in)
    } else {
      params.delete('category')
    }

    if (state.page && state.page > 1) {
      params.set('page', state.page.toString())
    } else {
      params.delete('page')
    }

    const value = `?${params.toString()}`

    setParams(value)
    window.history.replaceState({}, '', value)
  }, [state])

  return params
}
