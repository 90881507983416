import { Badge, BadgeProps } from '../Badge/Badge'

interface Props {
  items: BadgeProps[]
}

export const Badges = ({ items }: Props) => (
  <section className="flex flex-wrap gap-x-3 gap-y-2">
    {items.map((item, index) => (
      <Badge key={`${item.url}-${index}`} {...item} />
    ))}
  </section>
)

interface BadgesStoryblok {
  blok: {
    items: BadgeProps[]
  }
}

export const BadgesSb = ({ blok }: BadgesStoryblok) => (
  <Badges {...blok} />
)
