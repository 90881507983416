import { GlossaryText } from '../GlossaryText/GlossaryText'
import { PageTitleStoryblok } from '../../../../types/storyblok'

interface Props {
  text: string
  title: string
}

export const PageTitle = ({ text, title }: Props) => (
  <section className="flex flex-col items-center space-y-2 text-center">
    <h1 className="font-semibold text-dmd lg:text-dlg">{title}</h1>
    <GlossaryText
      className="text-md lg:text-xl md:max-w-2xl text-grey-solid-2"
      text={text}
    />
  </section>
)

export const PageTitleSb = ({ blok }: { blok: PageTitleStoryblok }) => (
  <PageTitle {...blok} />
)
