import { AuthorStoryblok } from "../../../../types"

export interface AuthorProps {
  avatar: {
    alt: string | null
    filename: string | null
  }
  name: string
  text: string | null
}

export const Author = ({ avatar, name, text }: AuthorProps) => (
  <figure className="flex items-center space-x-2">
    {avatar.filename && (
      <img
        alt={avatar.alt || name}
        className="aspect-1/1 h-8 rounded-sm w-8"
        src={avatar.filename}
      />
    )}
    <figcaption className="flex flex-col">
      <h5 className="text-md">{name}</h5>
      {text && <p className="text-grey-solid-2 text-sm">{text}</p>}
    </figcaption>
  </figure>
)

export const AuthorSb = ({ blok }: AuthorStoryblok) => (
  <Author {...blok} />
)
