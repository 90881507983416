import clsx from 'clsx'

import { Icon } from '../../atoms/Icon'
import { PageLinkItemStoryblok } from '../../../../types/storyblok'
import { storyblokLink } from '../../helpers'

interface Props {
  direction: 'prev' | 'next'
  subtitle: string
  title: string
  url: string
}

export const PageLinkItem = ({ direction, subtitle, title, url }: Props) => (
  <a
    className="border border-grey-solid-6 rounded p-8 flex items-center justify-between space-x-11 text-grey-solid-0"
    href={url}
    rel={direction}
  >
    {direction === 'prev' && <Icon icon="arrow_circle_left" />}
    <span
      className={clsx('flex flex-col space-y-3 whitespace-nowrap', {
        'text-left': direction === 'next',
        'text-right': direction === 'prev',
      })}
    >
      <span className="font-semibold text-dxs">{title}</span>
      <span className="font-bold text-lg text-grey-solid-2">{subtitle}</span>
    </span>
    {direction === 'next' && <Icon icon="arrow_circle_right" />}
  </a>
)

export const PageLinkItemSb = ({ blok }: { blok: PageLinkItemStoryblok }) => (
  <PageLinkItem {...blok} url={storyblokLink(blok.link)} />
)
