import { CardStoryblok } from '../../../../types'
import { GlossaryText } from '../GlossaryText/GlossaryText'

export interface CardProps {
  text: string
  title: string
}

export const Card = ({ text, title }: CardProps) => (
  <article className="bg-primary-solid-7 flex flex-col p-8 rounded space-y-2">
    <h3 className="font-semibold text-dxs">{title}</h3>
    <GlossaryText className="text-grey-solid-2 text-lg" text={text} />
  </article>
)

export const CardSb = ({ blok }: { blok: CardStoryblok }) => <Card {...blok} />
