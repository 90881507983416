import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import { GlossaryPageStoryblok } from '../../../types'
import { Content, Layout } from '../templates'
import { Glossary } from '../organisms'

export const GlossaryPage = ({ blok }: { blok: GlossaryPageStoryblok }) => (
  <Layout {...storyblokEditable(blok)}>
    <Content className="pt-20 md:pt-24 lg:pt-24">
      {blok.head?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
      <Glossary title={blok.title} text={blok.text} />
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)

export default GlossaryPage
