import clsx from 'clsx'
import { ReactNode } from 'react'
import { GlossaryText } from '../GlossaryText/GlossaryText'

export const AdvertiseSection = ({
  children,
  className = 'max-w-3xl',
  text,
  title,
}: {
  children: ReactNode
  className?: string
  text: string
  title: string
}) => (
  <section
    className={clsx(
      className,
      'flex flex-col items-center mx-auto text-center w-full'
    )}
  >
    <h3 className="font-semibold mb-2 md:mb-3 text-xl md:text-dsm">{title}</h3>
    <GlossaryText
      className="max-w-[560px] mb-6 md:mb-11 text-grey-solid-2 text-sm md:text-lg"
      text={text}
    />
    {children}
  </section>
)
