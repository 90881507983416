import clsx from 'clsx'
import { useState } from 'react'
import { Icon } from '../../atoms/Icon'

export type Item = {
  title: string
  links: {
    text: string
    target?: string
    url: string
  }[]
}

interface Props {
  item: Item
}

export const FooterLink = ({ item }: Props) => {
  const [open, setOpen] = useState('')

  return (
    <div className="break-inside-avoid flex flex-col space-y-4 w-full">
      <button
        className="flex items-center justify-between"
        onClick={() =>
          setOpen((prev) => (prev === item.title ? '' : item.title))
        }
        type="button"
      >
        <h3 className="font-semibold text-xs">{item.title}</h3>
        <Icon
          className="lg:hidden"
          icon={open === item.title ? 'chevron_up' : 'chevron_down'}
          size="sm"
        />
      </button>
      <ul
        className={clsx('space-y-2', {
          'hidden lg:block': open !== item.title,
        })}
      >
        {item.links.map((link, index) => (
          <li key={index + link.url} className="flex">
            <a
              className="font-semibold text-md whitespace-nowrap"
              href={link.url}
              target={link.target}
            >
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
