import { createContext } from 'react'

export type ApiService = {
  api: string
  token: string
}

export type ApiContextType = {
  pop?: ApiService
  rex?: ApiService
}

export const ApiContext = createContext<ApiContextType>({
  pop: undefined,
  rex: undefined,
})
