import clsx from 'clsx'
import { ISbStoryData } from '@storyblok/react'
import { useCallback, useEffect, useRef } from 'react'

import constants from '../../assets/constants.json'
import i18n from '../../assets/locales.json'

import { AuthorStoryblok, BlogPostPageStoryblok } from '../../../../types'
import { BlogPosts, Categories, Pagination } from '../../molecules'
import { getTotalPages, mapStoriesToPosts } from '../../helpers'
import { Search } from '../../atoms'

import {
  useCategories,
  usePagination,
  usePaginationState,
  usePaginationTitle,
} from '../../hooks'

interface Props {
  className?: string
}

export const BlogPagination = ({ className = '' }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const { data, loading, updateState, rels, state, total } = usePagination()
  const { categories } = useCategories()
  const title = usePaginationTitle()

  usePaginationState(state)

  const handleQuery = useCallback(
    (query: string) => {
      updateState({
        page: 1,
        search_term: query || undefined,
      })
    },
    [updateState]
  )

  const handleFilter = useCallback(
    (category?: string) => {
      updateState({
        page: 1,
        filter_query: !category ? undefined : { categories: { in: category } },
      })
    },
    [updateState]
  )

  useEffect(() => {
    if (state.page && state.page > 1 && ref.current && !loading) {
      window.scrollTo({
        behavior: 'smooth',
        top: ref.current.offsetTop - (constants.navbarHeight + 3),
      })
    }
  }, [loading, state])

  return (
    <section
      ref={ref}
      className={clsx('grid grid-cols-1 md:grid-cols-4 md:gap-x-8', className)}
    >
      <aside className="col-span-1 space-y-6">
        <Search
          defaultValue={state.search_term}
          name="query"
          onChange={handleQuery}
          placeholder={i18n.global.search}
        />
        <Categories
          active={state.filter_query?.categories?.in}
          items={[
            { name: i18n.global.viewAll, value: undefined },
            ...categories,
          ]}
          onClick={handleFilter}
          title={i18n.blog.categories}
        />
      </aside>
      <section className="col-span-1 md:col-span-3">
        <BlogPosts
          items={mapStoriesToPosts(
            data as ISbStoryData<BlogPostPageStoryblok>[],
            rels as ISbStoryData<AuthorStoryblok>[],
            categories
          )}
          title={title}
        />
        <Pagination
          current={state.page || 1}
          onClick={(value) => updateState({ page: value })}
          total={getTotalPages(total, state.per_page || 6)}
        />
      </section>
    </section>
  )
}
