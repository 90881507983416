import { ISbComponentType } from 'storyblok-js-client'
import { useState } from 'react'

import i18n from '../../assets/locales.json'
import { Label } from '..'

interface Props {
  address?: string
  email?: string
  phone?: string
}

export const CourseDetails = ({ address, email, phone }: Props) => {
  const [showPhone, setShowPhone] = useState(false)

  if (!address && !email && !phone) {
    return null
  }

  return (
    <address className="flex flex-col not-italic space-y-6">
      {phone && (
        <Label text={i18n.global.phoneNumber}>
          <p className="flex items-center space-x-2 text-lg">
            {showPhone ? (
              phone
            ) : (
              <>
                <span>{phone.slice(0, 7)} *** ***</span>
                <button
                  className="font-semibold text-xs text-primary-solid-2"
                  onClick={() => setShowPhone(true)}
                  type="button"
                >
                  {i18n.global.showMore}
                </button>
              </>
            )}
          </p>
        </Label>
      )}
      {email && (
        <Label text={i18n.global.email}>
          <a href={`mailto:${email}`} className="text-lg text-primary-solid-2">
            {email}
          </a>
        </Label>
      )}
      {address && (
        <Label text={i18n.global.address}>
          <p className="text-lg">{address}</p>
        </Label>
      )}
    </address>
  )
}

export type CourseDetailsStoryblok = ISbComponentType<'CourseDetails'> & Props

export const CourseDetailsSb = ({ blok }: { blok: CourseDetailsStoryblok }) => (
  <CourseDetails {...blok} />
)
