import {
  storyblokInit,
  apiPlugin,
  ISbStoriesParams,
} from '@storyblok/react/rsc'

import { GridSb } from './components/stories/atoms'

import {
  AdvertiseCoverageSb,
  AdvertiseOptionSb,
  AdvertiseOptionsSb,
  AdvertisePartnershipsSb,
  AdvertiseSectionHeadingSb,
  AdvertiseStatListSb,
  AuthorSb,
  BadgesSb,
  BreadcrumbItemSb,
  BreadcrumbsSb,
  CTACardSb,
  CTAHeadingSb,
  CardsSectionSb,
  ContactItemSb,
  CourseCardSb,
  CourseDetailsSb,
  CourseDescriptionSb,
  CourseHeadingSb,
  CourseScorecardSb,
  CourseReviewsSb,
  FeatureCardSb,
  FeatureContentSb,
  FeatureHeadingSb,
  FeatureHighlightSb,
  FeatureInfoItemSb,
  FeatureInfoSb,
  FeatureImageSb,
  FeatureLinkItemSb,
  FeatureListSb,
  FeatureListItemSb,
  FeatureTextSb,
  FeaturesGridSb,
  FeatureStepItemSb,
  HeadingSb,
  HelpCenterLinkSb,
  ImageSb,
  ListSectionSb,
  PageHeadingSb,
  PageLinkItemSb,
  PageLinksSb,
  PageSectionSb,
  PageTitleSb,
  PlanCardSb,
  PlatformLinkItemSb,
  PlatformLinksSb,
  PlatformReviewSb,
  PressKitCardSb,
  PressKitColorSb,
  PressKitNewsSb,
  PressKitSectionSb,
  QuoteSb,
  RecentItemsSb,
  RelatedLinksSb,
  ReviewSb,
  TextSb,
  ShopItemSb,
  YoutubePlayerSb,
} from './components/stories/molecules'

import {
  CTABannerSb,
  CoursesGridSb,
  CoursesSectionSb,
  HandicapBannerSb,
  HandicapCalculatorSb,
  HeroSb,
  HeroImageSb,
  FeaturesComparisonSb,
  FeaturesSectionSb,
  FeatureStepsSb,
  GlossaryListSb,
  MembersGridSb,
  ReviewsGridSb,
} from './components/stories/organisms'

import {
  AboutUsPage,
  AdvertisePage,
  BlogPage,
  BlogPostPage,
  CareersPage,
  CompanyPage,
  ContactsPage,
  FeaturePage,
  GlossaryPage,
  GlossaryTermPage,
  HandicapPage,
  PlatformPage,
  PremiumPage,
  PressKitPage,
  ProductPage,
  PT2020Page,
  TermsConditionsPage,
} from './components/stories/pages'

import { toQuery } from './components/stories/helpers'

storyblokInit({
  apiOptions: {
    region: 'us',
  },
  use: [apiPlugin],
  components: {
    AboutUsPage,
    AdvertisePage,
    BlogPage,
    BlogPostPage,
    CareersPage,
    CompanyPage,
    ContactsPage,
    FeaturePage,
    GlossaryPage,
    GlossaryTermPage,
    HandicapPage,
    PlatformPage,
    PremiumPage,
    PressKitPage,
    ProductPage,
    PT2020Page,
    TermsConditionsPage,

    AdvertiseCoverage: AdvertiseCoverageSb,
    AdvertiseOption: AdvertiseOptionSb,
    AdvertiseOptions: AdvertiseOptionsSb,
    AdvertisePartnerships: AdvertisePartnershipsSb,
    AdvertiseSectionHeading: AdvertiseSectionHeadingSb,
    AdvertiseStatList: AdvertiseStatListSb,
    Author: AuthorSb,
    Badges: BadgesSb,
    Breadcrumbs: BreadcrumbsSb,
    BreadcrumbItem: BreadcrumbItemSb,
    CardsSection: CardsSectionSb,
    ContactItem: ContactItemSb,
    CourseCard: CourseCardSb,
    CourseDetails: CourseDetailsSb,
    CourseDescription: CourseDescriptionSb,
    CourseHeading: CourseHeadingSb,
    CourseReviews: CourseReviewsSb,
    CourseScorecard: CourseScorecardSb,
    CoursesGrid: CoursesGridSb,
    CoursesSection: CoursesSectionSb,
    CTABanner: CTABannerSb,
    CTACard: CTACardSb,
    CTAHeading: CTAHeadingSb,
    FeatureCard: FeatureCardSb,
    FeatureContent: FeatureContentSb,
    FeatureHeading: FeatureHeadingSb,
    FeatureHighlight: FeatureHighlightSb,
    FeatureInfo: FeatureInfoSb,
    FeatureInfoItem: FeatureInfoItemSb,
    FeatureImage: FeatureImageSb,
    FeatureList: FeatureListSb,
    FeatureListItem: FeatureListItemSb,
    FeatureLinkItem: FeatureLinkItemSb,
    FeatureText: FeatureTextSb,
    FeaturesComparison: FeaturesComparisonSb,
    FeaturesGrid: FeaturesGridSb,
    FeaturesSection: FeaturesSectionSb,
    FeatureStepItem: FeatureStepItemSb,
    FeatureSteps: FeatureStepsSb,
    GlossaryList: GlossaryListSb,
    Grid: GridSb,
    HandicapBanner: HandicapBannerSb,
    HandicapCalculator: HandicapCalculatorSb,
    Heading: HeadingSb,
    HelpCenterLink: HelpCenterLinkSb,
    Hero: HeroSb,
    HeroImage: HeroImageSb,
    Image: ImageSb,
    ListSection: ListSectionSb,
    Quote: QuoteSb,
    MembersGrid: MembersGridSb,
    PlanCard: PlanCardSb,
    PageHeading: PageHeadingSb,
    PageLinkItem: PageLinkItemSb,
    PageLinks: PageLinksSb,
    PageSection: PageSectionSb,
    PageTitle: PageTitleSb,
    PlatformLinks: PlatformLinksSb,
    PlatformLinkItem: PlatformLinkItemSb,
    PlatformReview: PlatformReviewSb,
    PressKitCard: PressKitCardSb,
    PressKitColor: PressKitColorSb,
    PressKitNews: PressKitNewsSb,
    PressKitSection: PressKitSectionSb,
    RecentItems: RecentItemsSb,
    RelatedLinks: RelatedLinksSb,
    Review: ReviewSb,
    ReviewsGrid: ReviewsGridSb,
    ShopItem: ShopItemSb,
    Text: TextSb,
    YoutubePlayer: YoutubePlayerSb,
  },
})()

export const fetchBlogposts = async <T>(options: ISbStoriesParams = {}) => {
  try {
    const res = await fetch(`/blogposts?${toQuery(options)}`)
    const json = await res.json()
    return {
      error: false,
      rels: json.data.rels,
      stories: json.data.stories as T[],
      total: json.headers.total,
    }
  } catch (err) {
    console.error(err)
    return { error: true, stories: [], total: 0 }
  }
}
