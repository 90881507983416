import { ReactNode } from 'react'
import { FeatureHeading, Tabs } from '../../molecules'
import { FeaturesComparisonStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  category: string
  items: {
    content: ReactNode
    title: string
  }[]
  text: string
  title: string
}

export const FeaturesComparison = ({ category, items, text, title }: Props) => (
  <section className="flex flex-col space-y-4">
    <FeatureHeading category={category} text={text} title={title} />
    <Tabs items={items} variant="underline" />
  </section>
)

export const FeaturesComparisonSb = ({
  blok,
}: {
  blok: FeaturesComparisonStoryblok
}) => (
  <FeaturesComparison
    category={blok.category}
    items={[
      {
        content: blok.premiumContent.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        )),
        title: blok.premiumTitle,
      },
      {
        content: blok.freeContent.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        )),
        title: blok.freeTitle,
      },
    ]}
    text={blok.text}
    title={blok.title}
  />
)
