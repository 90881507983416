import clsx from 'clsx'

import { getColor, getFont, getPadding, getRadius } from './Button.helpers'
import { Icon, IconProps } from '../Icon'

export interface Props {
  className?: string
  color:
    | 'danger-solid-1'
    | 'highlights-core'
    | 'primary-solid-2'
    | 'grey-solid-0'
    | 'grey-solid-2'
  disabled?: boolean
  href?: string
  icon?: IconProps['icon']
  onClick?: () => void
  outline?: boolean
  size?: 'md' | 'xl' | '2xl'
  text: string
  textColor?: 'text-white-solid-1' | 'text-grey-solid-0'
}

export const Button = ({
  className,
  color,
  disabled = false,
  href,
  icon,
  onClick,
  outline = false,
  size = 'md',
  text,
  textColor = 'text-white-solid-1',
}: Props) => {
  const classNames = clsx(
    className,
    getColor(color, outline, textColor, disabled),
    getFont(size),
    getPadding(size),
    getRadius(size),
    'border font-semibold'
  )

  return href ? (
    <a
      href={href}
      className={clsx('inline-flex items-center', classNames)}
      onClick={onClick}
    >
      {text}
    </a>
  ) : (
    <button
      className={clsx('flex items-center', classNames, {
        'cursor-not-allowed': disabled,
      })}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {icon && <Icon className="mr-2" icon={icon} />}
      {text}
    </button>
  )
}
