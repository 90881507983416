import { createContext } from 'react'

import { DatasourceEntries } from '../../../types'

type CategoriesContextType = {
  categories: DatasourceEntries['datasource_entries']
}

export const CategoriesContext = createContext<CategoriesContextType>({
  categories: [],
})
