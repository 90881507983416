import { HeroImageStoryblok } from '../../../../types/storyblok'

interface Props {
  image: {
    alt: string | null
    filename: string | null
  }
}

export const HeroImage = ({ image }: Props) => (
  <section className="aspect-4/3 md:aspect-11/5 max-h-[560px] relative w-full">
    <img
      alt={image.alt || ''}
      className="absolute aspect-square inset-0 h-full object-contain w-full"
      src={image.filename || ''}
    />
  </section>
)

export const HeroImageSb = ({ blok }: { blok: HeroImageStoryblok }) => (
  <HeroImage image={blok.image} />
)
