import { GlossaryText } from '../GlossaryText/GlossaryText'
import { HeadingStoryblok } from '../../../../types'
import { PublishedAt } from '../PublishedAt/PublishedAt'
import { Search, SearchProps } from '../../atoms'

interface Props {
  caption?: string
  datetime?: string
  disableGlossary?: boolean
  searchProps?: SearchProps & {
    helpText?: string
  }
  text: string
  title: string
}

export const Heading = ({
  caption,
  datetime = '',
  disableGlossary = false,
  searchProps,
  text,
  title,
}: Props) => (
  <header className="flex flex-col">
    {datetime && (
      <PublishedAt
        className="flex font-semibold mb-2 text-primary-solid-2 text-md"
        date={datetime}
      />
    )}
    <div className="flex flex-col lg:w-2/3">
      <h1 className="font-semibold text-dmd lg:text-dxl">{title}</h1>
      {caption && (
        <p className="font-semibold lg:text-xl pb-2 text-grey-solid-2 text-md">
          {caption}
        </p>
      )}
      {disableGlossary ? (
        <p className="lg:text-xl mt-2 text-grey-solid-2 text-md">{text}</p>
      ) : (
        <GlossaryText
          className="lg:text-xl mt-2 text-grey-solid-2 text-md"
          text={text}
        />
      )}
    </div>
    {searchProps && (
      <div className="flex flex-col lg:w-2/3 mt-6 space-y-2">
        <Search {...searchProps} />
        {searchProps.helpText && (
          <p className="font-medium px-4 text-grey-solid-4 text-xs">
            {searchProps.helpText}
          </p>
        )}
      </div>
    )}
  </header>
)

export const HeadingSb = ({ blok }: { blok: HeadingStoryblok }) => (
  <Heading {...blok} />
)
