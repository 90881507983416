type Obj = {
  [key: string]: any
}

// Maps an object to a query string and supports nested keys
// for example { foo: 'bar', filter: { category: 'home' } }
// results in ?foo=bar&filter[category]=home
export const toQuery = (obj: Obj, prefix?: string): string => {
  const entries = Object.entries(obj).map(([key, value]) => {
    const fullKey = prefix ? `${prefix}[${key}]` : key;
    if (typeof value === 'object' && value !== null) {
      return toQuery(value, fullKey);
    }
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
  });

  return entries.join('&');
}
