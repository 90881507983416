import { ReactNode } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

import {
  FeatureInfoStoryblok,
  RichtextStoryblok,
} from '../../../../types/storyblok'

import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  children: ReactNode
  text: string | RichtextStoryblok
  title: string
}

export const FeatureInfo = ({ children, text, title }: Props) => (
  <section className="flex flex-col py-12 lg:py-16">
    <h2 className="font-semibold text-xl lg:text-dsm">{title}</h2>
    <GlossaryText
      className="lg:max-w-5xl mt-4 text-sm lg:text-md text-grey-solid-2"
      text={text}
    />
    <div className="flex flex-row mt-8 lg:mt-12 space-x-3 lg:space-x-16">
      {children}
    </div>
  </section>
)

export const FeatureInfoSb = ({ blok }: { blok: FeatureInfoStoryblok }) => (
  <FeatureInfo text={blok.text} title={blok.title}>
    {blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  </FeatureInfo>
)
