import { createContext } from 'react'

import { ScoreMode } from '../hooks'

type RoundGroupsContextType = {
  courseName: string
  live: boolean
  rounds: GolfRound[],
  scoreMode: ScoreMode
  startedAt: string
}

export const RoundGroupsContext = createContext<RoundGroupsContextType>({
  courseName: '',
  live: false,
  rounds: [],
  scoreMode: {
    handleChange: () => {},
    modeKey: '',
    modeValue: '',
    modeOpts: [],
    typeKey: '',
    typeValue: '',
    typeOpts: [],
    value: '',
  },
  startedAt: '',
})
