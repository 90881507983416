import clsx from 'clsx'

import { Icon, IconProps } from '../../atoms'
import Icons from '../../atoms/Icons'

interface Props {
  className?: string
  color: 'highlights-best-drive' | 'primary' | 'primary-solid-7' | 'white' | 'grey'
  icon?: keyof typeof Icons
  iconClass?: string
  iconSize?: IconProps['size']
  px?: string
  text?: string
  textClass?: string
}

export const Tag = ({
  className = '',
  color,
  icon,
  iconClass,
  iconSize = 'sm',
  px = 'px-2',
  text,
  textClass = '',
}: Props) => (
  <div
    className={clsx(
      'flex items-center justify-center py-[3px] rounded-sm space-x-[3px]',
      {
        'bg-highlights-best-drive': color === 'highlights-best-drive',
        'bg-primary-solid-2': color === 'primary',
        'bg-primary-solid-7': color === 'primary-solid-7',
        'bg-white-solid-1': color === 'white',
        'bg-grey-solid-6': color === 'grey',
      },
      px,
      className
    )}
  >
    {icon && <Icon className={iconClass} icon={icon} size={iconSize} />}
    {text && (
      <span
        className={clsx(
          'font-bold text-md uppercase',
          {
            'text-white-solid-1': color === 'primary',
            'text-grey-solid-3': color === 'white',
          },
          textClass
        )}
      >
        {text}
      </span>
    )}
  </div>
)
