import { useCallback, useEffect, useMemo, useState } from 'react'

import { PremiumProductsContext } from './PremiumProductsContext'

interface Props {
  children: React.ReactNode
}

export const PremiumProductsProvider = ({ children }: Props) => {
  const [countryCode, setCountryCode] = useState('')
  const [data, setData] = useState<PremiumProduct[]>([])
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const res = await fetch('/premium/products')
      const json: PremiumProductsResponse = await res.json()
      setCountryCode(json.country_code)
      setData(json.products)
    } catch (err) {}
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const contextValue = useMemo(
    () => ({
      countryCode,
      data,
      loading,
    }),
    [countryCode, data, loading]
  )

  return (
    <PremiumProductsContext.Provider value={contextValue}>
      {children}
    </PremiumProductsContext.Provider>
  )
}
