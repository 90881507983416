import { ReactNode } from 'react'

import { RichText } from '../../atoms'
import { PressKitColorStoryblok } from '../../../../types'

export interface PressKitColorProps {
  color: string
  text: ReactNode
}

export const PressKitColor = ({ color, text }: PressKitColorProps) => (
  <figure className="flex flex-col space-y-2 w-full">
    <div className="aspect-4/1 w-full">
      <svg
        className="h-full w-full"
        height="100"
        viewBox="0 0 380 100"
        xmlns="http://www.w3.org/2000/svg"
        width="380"
      >
        <rect x="0" y="0" height="100" fill={color} width="380" />
      </svg>
    </div>
    <figcaption className="text-grey-solid-3">{text}</figcaption>
  </figure>
)

export const PressKitColorSb = ({ blok }: { blok: PressKitColorStoryblok }) => (
  <PressKitColor color={blok.color} text={<RichText content={blok.text} />} />
)
