import i18n from '../../assets/locales.json'
import { Tee } from '../Tee/Tee'

interface Props {
  avatarUrl: string
  name: string
  playingHandicap: string
  tee: GolfRound['tee']
}

export const RoundProfile = ({ avatarUrl, name, playingHandicap, tee }: Props) => (
  <div className="flex truncate space-x-2">
    <div className="aspect-1/1 h-8 w-8 relative">
      <img
        alt={name}
        className="absolute object-cover rounded-sm h-full w-full"
        src={avatarUrl}
      />
    </div>
    <div className="flex flex-col min-w-0">
      <div className="font-semibold text-md truncate">
        {name}
      </div>
      <div className="font-semibold text-grey-solid-3 text-sm truncate">
        {i18n.global.phcp}: {playingHandicap}
      </div>
      <div className="flex items-center mt-1 space-x-1 text-xs">
        <Tee color={tee.backgroundColor} letter={tee.name} textColor={tee.textColor} />
        <span className="capitalize font-medium text-grey-solid-2 truncate">{tee.name}</span>
      </div>
    </div>
  </div>
)
