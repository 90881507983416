import ReactOnRails from 'react-on-rails';

// Import base files
import '../storyblok.ts';
import '../base.js';

// Global API helpers
import { Get, Post, Put } from '../helpers/networking.es6';

// New components
import StoryblokBridge from '../components/StoryblokBridge.es6';
import CourseProfile from '../components/CourseProfile.es6';
import Courses from '../components/Courses.es6';
import LocationCourses from '../components/LocationCourses.es6';
import RoundGroups from '../components/RoundGroups.es6';

// Legacy components
import Optional from '../helpers/optional.es6';
import CommonHeader from '../components/CommonHeader.es6';
import EmailUnsubscribe from '../components/preferences/email/EmailUnsubscribe.es6.jsx';
import ErrorScreen from '../components/ErrorPage.es6';
import ImportMultiplayerRound from '../components/performance/rounds/ImportMultiplayerRound.es6';
import NotificationPreferences from '../components/preferences/notifications/NotificationPreferences.es6.jsx';
import RoundLeaderboardsIndex from '../components/performance/leaderboards/Index.es6';
import RoundLeaderboardsShow from '../components/performance/leaderboards/Show.es6';
import RoundGroupShow from '../components/performance/roundGroup/Show.es6';
import ShowSubscription from '../components/premiumSubscription/ShowSubscription.es6';
import StoreButtons from '../components/StoreButtons.es6';

window.Get = Get;
window.Post = Post;
window.Put = Put;

// Build these components for the browser
ReactOnRails.register({
  StoryblokBridge,
  CourseProfile,
  Courses,
  LocationCourses,
  RoundGroups,
  Optional,
  CommonHeader,
  EmailUnsubscribe,
  ErrorScreen,
  ImportMultiplayerRound,
  NotificationPreferences,
  RoundLeaderboardsIndex,
  RoundLeaderboardsShow,
  RoundGroupShow,
  ShowSubscription,
  StoreButtons,
});
