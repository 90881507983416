import { ISbComponentType } from 'storyblok-js-client'

import { CarouselGrid, CarouselGridItem } from '..'

import {
  CourseCard,
  CourseCardProps,
} from '../../molecules/CourseCard/CourseCard'

import { Link } from '../../molecules'

interface Props {
  items: CourseCardProps[]
  link?: {
    text: string
    url: string
  }
  title: string
  variant: 'grid' | 'rows'
}

export const CoursesSection = ({
  items,
  link,
  title,
  variant,
}: Props) => (
  <section className="flex flex-col space-y-4 md:space-y-8">
    <div className="flex items-center justify-between space-x-4 text-nowrap">
      <h2 className="font-semibold text-lg truncate md:text-dsm">{title}</h2>
      {link && (
        <Link
          textClass="font-semibold shrink-0 text-grey-solid-3 text-lg md:text-dsm"
          iconClass="text-grey-solid-4"
          icon="chevron_right"
          href={link.url}
        >
          {link.text}
        </Link>
      )}
    </div>
    {variant === 'grid' ? (
      <CarouselGrid cols="3" gap="2">
        {items.map((item, index) => (
          <CarouselGridItem key={index}>
            <CourseCard {...item} />
          </CarouselGridItem>
        ))}
      </CarouselGrid>
    ) : (
      <div className="flex flex-col space-y-2 md:space-y-4">
        {items.map((item, index) => (
          <CourseCard key={index} {...item} />
        ))}
      </div>
    )}
  </section>
)

export type CoursesSectionStoryblok = ISbComponentType<'CoursesSection'> & Props

export const CoursesSectionSb = ({
  blok,
}: {
  blok: CoursesSectionStoryblok
}) => <CoursesSection {...blok} />
