import { createContext } from 'react'

import { Glossary, RichtextStoryblok } from '../../../types'

type GlossaryContextType = {
  glossary: Glossary
  withGlossary: (text: string | RichtextStoryblok) => RichtextStoryblok
}

export const GlossaryContext = createContext<GlossaryContextType>({
  glossary: {
    links: [],
    terms: [],
  },
  withGlossary: () => ({} as RichtextStoryblok),
})
