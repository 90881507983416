import { useCallback, useState } from 'react'

import { LeaderboardHeader } from './LeaderboardHeader'
import { RoundHoles } from '../RoundHoles/RoundHoles'
import { RoundPosition } from '../RoundPosition/RoundPosition'
import { RoundProfile } from '../RoundProfile/RoundProfile'
import { RoundScore } from '../RoundScore/RoundScore'
import { useRoundGroups } from '../../hooks'

export const LeaderboardTable = () => {
  const {
    rounds,
    scoreMode: { modeValue, typeValue },
  } = useRoundGroups()

  const [open, setOpen] = useState<Record<number, boolean>>({})

  const handleClick = useCallback((round: GolfRound) => {
    setOpen((prev) => ({
      ...prev,
      [round.id]: prev[round.id] ? false : true,
    }))
  }, [])

  return (
    <section className="flex flex-col space-y-2">
      <LeaderboardHeader />
      <ul className="flex flex-col space-y-2">
        {rounds.map((round, index) => (
          <li key={round.id} className="flex flex-col gap-y-2">
            <button
              className="bg-grey-solid-7 hover:bg-primary-solid-6 relative transition-colors duration-150 rounded"
              onClick={() => handleClick(round)}
              type="button"
            >
              <div className="flex items-center justify-between p-4 space-x-2 text-left">
                <RoundProfile
                  avatarUrl={round.user.avatarUrl}
                  name={round.user.fullName}
                  playingHandicap={round.user.playingHandicap}
                  tee={round.tee}
                />
                <RoundScore
                  isOpen={Boolean(open[round.id])}
                  score={round.byScoringMode.total_score}
                  topar={round.byScoringMode.to_par}
                  thru={round.throughHole}
                />
              </div>
              <RoundPosition position={index + 1} />
            </button>
            {Boolean(open[round.id]) && (
              <RoundHoles
                holes={round.holes}
                isNet={typeValue === 'net'}
                isStableford={modeValue === 'stableford'}
              />
            )}
          </li>
        ))}
      </ul>
    </section>
  )
}
