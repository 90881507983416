import { Button } from '../../atoms'
import { HandicapBannerStoryblok } from '../../../../types/storyblok'
import { storyblokLink } from '../../helpers'

interface Props {
  buttonText: string
  image: {
    alt: string | null
    filename: string | null
  }
  text: string
  title: string
  url: string
  variant?: 'default' | 'course'
}

export const HandicapBanner = ({
  buttonText,
  image,
  text,
  title,
  url,
  variant = 'default',
}: Props) =>
  variant === 'course' ? (
    <div className="bg-separator-solid-2 rounded-md relative">
      <div className="flex flex-col items-start p-5 space-y-4 w-3/4 md:w-1/2 relative z-[2]">
        <h3 className="font-semibold text-xl md:text-dsm">{title}</h3>
        <Button
          color="grey-solid-0"
          href={url}
          outline={true}
          text={buttonText}
          size="2xl"
        />
      </div>
      <div className="absolute inset-0 rounded-md bg-gradient-to-l from-transparent to-separator-solid-2 z-[1]" />
      <div className="absolute aspect-3/2 h-full bottom-0 right-0 w-1/2 md:w-1/2 z-0">
        <img
          alt={image.alt || ''}
          className="absolute inset-0 object-left-top object-cover rounded-r-md h-full w-full"
          src={`${image.filename}/m/300x200`}
        />
      </div>
    </div>
  ) : (
    <div className="max-w-[720px] mx-auto">
      <section className="bg-separator-solid-2 grid grid-cols-1 md:grid-cols-3 rounded-2xl">
        <div className="flex flex-col flex-grow md:col-span-2 p-8 pb-4 md:pl-0 md:py-8 md:pr-12 text-center md:text-left">
          <h3 className="font-semibold mb-2 text-xl">{title}</h3>
          <p className="mb-8 md:mb-4 text-sm">{text}</p>
          <div className="flex flex-col items-center md:items-start">
            <Button
              color="grey-solid-0"
              href={url}
              outline={true}
              text={buttonText}
              size="xl"
            />
          </div>
        </div>
        <div className="aspect-4/3 col-span-1 flex md:aspect-auto md:order-first relative">
          <img
            alt={image.alt || ''}
            className="absolute inset-0 object-cover h-full w-full"
            src={`${image.filename}/m/350x350`}
          />
        </div>
      </section>
    </div>
  )

export const HandicapBannerSb = ({
  blok,
}: {
  blok: HandicapBannerStoryblok
}) => (
  <HandicapBanner
    buttonText={blok.buttonText}
    image={blok.image}
    text={blok.text}
    title={blok.title}
    url={storyblokLink(blok.link)}
  />
)
