import { storyblokEditable } from '@storyblok/react'

import { Pt2020PageStoryblok } from '../../../types/storyblok'
import { Content, Layout } from '../templates'
import { RichText } from '../atoms'

export const PT2020Page = ({ blok }: { blok: Pt2020PageStoryblok }) => (
  <Layout {...storyblokEditable(blok)}>
    <Content
      padding="py-24 md:py-16"
      maxWidth="max-w-[872px]"
      spacing="space-y-12"
    >
      {blok.image?.filename && (
        <section className="flex flex-col items-center justify-center md:py-12 md:px-10">
          <img
            alt={blok.image.alt || blok.seoTitle}
            className="max-w-[692px] w-full"
            src={blok.image.filename}
          />
        </section>
      )}
      <section>
        <RichText
          className="mb-4 text-grey-solid-0 text-sm"
          content={blok.text}
        />
      </section>
    </Content>
  </Layout>
)
