import { Get } from '../networking.es6';

export default {
  fetchCuratedLists(service, query) {
    return Get(`${service.api}/api/web/course_identity_lists/home?${query}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchCourses(service, query) {
    return Get(`${service.api}${query}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchPlaceByExternalRef(service, value) {
    return Get(`${service.api}/api/web/places/show?external_ref=${value}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchLocation(service, query, limit) {
    return Get(`${service.api}/api/web/locations/autocomplete?q=${query}&limit=${limit}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  buildCoursesQueryString(lat, lng, place, radius) {
    if (place && place.name) {
      return `/api/web/course_identities/geo_search?with_images=true&count=24&geo_type=place&geo[place_slug]=${place.slug}`;
    }

    if (lat && lng) {
      return `/api/web/course_identities/geo_search?with_images=true&count=24&geo_type=coordinate&geo[latitude]=${lat}&geo[longitude]=${lng}&geo[radius]=${radius}`;
    }
  },
};
