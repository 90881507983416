import { Button } from '../../atoms'
import { CtaHeadingStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'

interface Props {
  outlineButtonText?: string
  outlineButtonUrl?: string
  primaryButtonText: string
  primaryButtonUrl: string
  text: string
  title: string
}

export const CTAHeading = ({
  text,
  title,
  outlineButtonText,
  outlineButtonUrl,
  primaryButtonText,
  primaryButtonUrl,
}: Props) => (
  <section className="flex flex-col items-center">
    <h2 className="font-semibold mb-4 text-dsm md:text-dmd">{title}</h2>
    <p className="text-grey-solid-4 mb-6 text-xl">{text}</p>
    <div className="flex items-center justify-center gap-x-3">
      {Boolean(outlineButtonText && outlineButtonUrl) && (
        <Button
          color="grey-solid-2"
          href={outlineButtonUrl}
          outline
          text={outlineButtonText || ''}
        />
      )}
      <Button
        color="primary-solid-2"
        href={primaryButtonUrl}
        text={primaryButtonText}
      />
    </div>
  </section>
)

export const CTAHeadingSb = ({ blok }: CtaHeadingStoryblok) => (
  <CTAHeading
    {...blok}
    outlineButtonUrl={storyblokLink(blok.outlineButtonUrl)}
    primaryButtonUrl={storyblokLink(blok.primaryButtonUrl)}
  />
)
