import clsx from 'clsx'

import i18n from '../../assets/locales.json'

import { Link } from '..'
import { FeatureListItemStoryblok } from '../../../../types/storyblok'
import { storyblokLink } from '../../helpers'
import { Tag } from '../Tag/Tag'

interface Props {
  description?: string
  title: string
  variant: 'free' | 'premium'
  url?: string
}

export const FeatureListItem = ({
  description,
  title,
  variant,
  url,
}: Props) => (
  <li className="flex items-center justify-between space-x-4 md:space-x-10">
    <div className="flex flex-col">
      <h4
        className={clsx('font-semibold md:text-dxs', {
          'mb-1 md:mb-2': variant === 'premium',
        })}
      >
        {title}
      </h4>
      {description && (
        <p className="mb-4 text-lg text-grey-solid-2">{description}</p>
      )}
      {url && (
        <Link href={url} textClass="font-bold text-primary-solid-2 md:text-xl">
          {i18n.platform.discoverFeature}
        </Link>
      )}
    </div>
    <div className="flex items-center justify-center space-x-3 md:space-x-10 md:px-5">
      <Tag
        color="primary"
        icon="star"
        iconClass="h-[24px] text-white-solid-1"
        px="px-[7px]"
      />
      {variant === 'free' ? (
        <Tag
          color="primary-solid-7"
          icon="check"
          iconClass="h-[24px] text-primary-solid-2"
          px="px-[7px]"
        />
      ) : (
        <Tag color="white" px="px-[11px]" text="-" />
      )}
    </div>
  </li>
)

export const FeatureListItemSb = ({
  blok,
}: {
  blok: FeatureListItemStoryblok
}) => (
  <FeatureListItem
    {...blok}
    url={blok.link ? storyblokLink(blok.link) : undefined}
  />
)
