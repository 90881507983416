import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  className?: string
  children: ReactNode
  padding?: string
  maxWidth?: string
  spacing?: string
}

export const Content = ({
  children,
  className = '',
  padding = 'py-8 lg:py-16',
  maxWidth = 'max-w-7xl',
  spacing = 'space-y-8 lg:space-y-16',
}: Props) => (
  <div
    className={clsx(
      className,
      padding,
      spacing,
      'flex flex-col',
      `mx-auto ${maxWidth} w-full`,
      'px-4 lg:px-8'
    )}
  >
    {children}
  </div>
)
