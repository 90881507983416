import { CoreWebLogo } from './CoreWebLogo'
import { Hole19Logo } from './Hole19Logo'

interface Props {
  app?: 'hole19' | 'CORE'
  className?: string
  variant: 'default' | 'defaultLight' | 'symbol'
}

export const Logo = ({ app = 'hole19', className = '', variant }: Props) =>
  app === 'hole19' ? (
    <Hole19Logo className={className} variant={variant} />
  ) : (
    <CoreWebLogo className={className} />
  )
