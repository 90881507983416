import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'

import i18n from '../../assets/locales.json'

import { ImageBanner, Props } from '../../molecules/ImageBanner/ImageBanner'

interface Country {
  name: string
  url: string
  course_identity_count: number
}

interface CountrySuggestions {
  current_country: Country
  nearby_suggestions: Country[]
}

export const ImageBannerGrid = () => {
  const [data, setData] = useState<Props[]>([])

  const reducer = useCallback(
    (country: Country, color: Props['color'], variant: Props['variant']) => ({
      caption: `${country.course_identity_count} ${i18n.global.courses}`,
      color,
      href: country.url,
      text: `${i18n.courses.suggestions.title} ${country.name}`,
      title: `${i18n.courses.suggestions.subtitle1of2} ${country.name}${i18n.courses.suggestions.subtitle2of2}`,
      variant,
    }),
    []
  )

  const fetchSuggestions = useCallback(async () => {
    try {
      const res = await fetch('/courses/suggested_countries')
      const json: CountrySuggestions = await res.json()
      setData([
        reducer(json.current_country, 'grey-solid-7', 'light'),
        ...(json.nearby_suggestions[0]
          ? [reducer(json.nearby_suggestions[0], 'separator-solid-3', 'dark')]
          : []),
        ...(json.nearby_suggestions[1]
          ? [reducer(json.nearby_suggestions[1], 'separator-solid-4', 'dark')]
          : []),
      ])
    } catch (err) {
      setData([])
    }
  }, [])

  useEffect(() => {
    fetchSuggestions()
  }, [])

  if (data.length === 0) {
    return null
  }

  return (
    <section className="grid grid-cols-1 items-stretch gap-x-2 gap-y-2 md:grid-cols-2 md:gap-y-4 z-0">
      {data.map((item, index) => (
        <div
          key={index}
          className={clsx('col-span-1 h-full', {
            'md:col-span-2': index === 0,
          })}
        >
          <ImageBanner {...item} />
        </div>
      ))}
    </section>
  )
}
