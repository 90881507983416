import { HelpCenterLinkStoryblok } from '../../../../types'
import { Icon } from '../../atoms'
import { storyblokLink } from '../../helpers'

interface Props {
  text: string
  title: string
  url: string
}

export const HelpCenterLink = ({ text, title, url }: Props) => (
  <a
    className="bg-primary-solid-7 flex flex-col p-4 space-y-2 rounded"
    href={url}
  >
    <div className="flex items-center space-x-2">
      <div className="bg-primary-solid-6 flex flex-col items-center justify-center p-2 rounded-full">
        <Icon className="text-primary-solid-2" icon="help_circle" size="md" />
      </div>
      <h3 className="font-bold text-md">{title}</h3>
    </div>
    <div className="bg-primary-alpha-5 flex items-start justify-between p-4 rounded-md space-x-4">
      <p>{text}</p>
      <Icon icon="arrow_top_right" size="md" />
    </div>
  </a>
)

export const HelpCenterLinkSb = ({
  blok,
}: {
  blok: HelpCenterLinkStoryblok
}) => (
  <HelpCenterLink
    text={blok.text}
    title={blok.title}
    url={storyblokLink(blok.url)}
  />
)
