import { FeatureLinkItemStoryblok } from '../../../../types/storyblok'
import { Icon } from '../../atoms/Icon'
import { storyblokLink } from '../../helpers'

interface Props {
  text: string
  url: string
}

export const FeatureLinkItem = ({ text, url }: Props) => (
  <a className="flex items-center space-x-2" href={url}>
    <Icon
      className="md:h-[26px] md:w-[26px] text-primary-solid-2"
      icon="check_circle"
      size="20"
    />
    <span className="text-grey-solid-2 text-sm md:text-lg">{text}</span>
  </a>
)

export const FeatureLinkItemSb = ({
  blok,
}: {
  blok: FeatureLinkItemStoryblok
}) => <FeatureLinkItem text={blok.text} url={storyblokLink(blok.link)} />
