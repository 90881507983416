import clsx from 'clsx'

import { BreadcrumbItemStoryblok } from '../../../../types/storyblok'
import { Icon } from '../../atoms/Icon'
import { storyblokLink } from '../../helpers'

export interface BreadcrumbItemProps {
  current?: boolean
  index?: number
  length?: number
  text: string
  url: string
}

const Separator = ({ direction }: { direction: 'back' | 'next' }) => (
  <span className="text-grey-solid-4">
    <Icon
      icon={direction === 'back' ? 'chevron_left' : 'chevron_right'}
      size="sm"
    />
  </span>
)

export const BreadcrumbItem = ({
  current,
  index = 0,
  length = 1,
  text,
  url,
}: BreadcrumbItemProps) => (
  <li
    className={clsx('flex items-center space-x-[8px]', {
      'shrink-0': index === 0,
      'min-w-0 truncate': index > 0,
    })}
  >
    {length === 1 && <Separator direction="back" />}
    <span className="font-medium text-sm text-grey-solid-4 truncate">
      {current ? (
        text
      ) : (
        <a className="truncate" href={url}>
          {text}
        </a>
      )}
    </span>
    {length > 1 && index < length - 1 && <Separator direction="next" />}
  </li>
)

export const BreadcrumbItemSb = ({
  blok,
  index,
  length,
}: {
  blok: BreadcrumbItemStoryblok
  index: number
  length: number
}) => (
  <BreadcrumbItem
    current={true}
    index={index}
    length={length}
    text={blok.text}
    url={storyblokLink(blok.url)}
  />
)
