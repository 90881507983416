const Icons = {
  arrow_top_right:
    'M7 8a1 1 0 0 1 0-2h10a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L14.586 8z',
  arrow_circle_left:
    'M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18m11-9c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11m-10.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 1 1 1.414 1.414L10.414 11H16a1 1 0 1 1 0 2h-5.586z',
  arrow_circle_right:
    'M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12m10.293-3.293a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L13.586 13H8a1 1 0 1 1 0-2h5.586z',
  arrow_circle_left_slimer:
    'M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m1 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M12.028 9.278a.393.393 0 0 0-.556-.556l-2.75 2.75a.393.393 0 0 0 0 .556l2.75 2.75a.393.393 0 0 0 .556-.556l-2.08-2.08H14.5a.393.393 0 1 0 0-.785H9.948z',
  arrow_circle_right_slimer:
    'M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m1 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11m-11.528 2.222a.393.393 0 0 0 .556.556l2.75-2.75a.393.393 0 0 0 0-.556l-2.75-2.75a.393.393 0 0 0-.556.556l2.08 2.08H9a.393.393 0 0 0 0 .785h4.552z',
  arrow_left_slimer:
    'M12.424 5.41a.835.835 0 0 1 0 1.18l-4.408 4.408h9.65a.835.835 0 0 1 0 1.67h-9.65l4.408 4.408a.835.835 0 0 1-1.181 1.181L5.41 12.424a.835.835 0 0 1 0-1.181l5.833-5.833a.835.835 0 0 1 1.18 0',
  arrow_right_slimer:
    'M11.576 18.59a.835.835 0 0 1 0-1.18l4.408-4.408h-9.65a.835.835 0 0 1 0-1.67h9.65l-4.408-4.408a.835.835 0 0 1 1.181-1.181l5.833 5.833a.835.835 0 0 1 0 1.181l-5.833 5.833a.835.835 0 0 1-1.18 0',
  check:
    'M20.036 6.366a1.25 1.25 0 0 1 0 1.765l-9.108 9.138a2.483 2.483 0 0 1-3.454.064l-3.476-3.24a1.25 1.25 0 0 1-.065-1.764 1.24 1.24 0 0 1 1.758-.065l3.477 3.239 9.108-9.137a1.24 1.24 0 0 1 1.76 0',
  check_circle:
    'M11.95 2.173c-5.4 0-9.778 4.377-9.778 9.777s4.378 9.777 9.778 9.777 9.777-4.377 9.777-9.777-4.378-9.777-9.777-9.777M0 11.95C0 5.35 5.35 0 11.95 0S23.9 5.35 23.9 11.95 18.55 23.9 11.95 23.9 0 18.55 0 11.95m17.606-4.027a1.086 1.086 0 0 1 0 1.536l-6.518 6.518a1.086 1.086 0 0 1-1.536 0l-3.26-3.259a1.086 1.086 0 0 1 1.537-1.536l2.491 2.49 5.75-5.75a1.086 1.086 0 0 1 1.536 0',
  chevron_up:
    'M19.469 16.153a1.184 1.184 0 0 1-1.675 0L12 10.36l-5.794 5.794a1.184 1.184 0 0 1-1.675-1.675l6.632-6.631a1.184 1.184 0 0 1 1.674 0l6.632 6.631a1.184 1.184 0 0 1 0 1.675',
  chevron_down:
    'M4.531 7.847a1.184 1.184 0 0 1 1.675 0L12 13.64l5.794-5.794a1.184 1.184 0 1 1 1.675 1.675l-6.632 6.631a1.184 1.184 0 0 1-1.674 0L4.53 9.522a1.184 1.184 0 0 1 0-1.675',
  chevron_left:
    'M16.153 4.531a1.184 1.184 0 0 1 0 1.675L10.36 12l5.794 5.794a1.184 1.184 0 0 1-1.675 1.675l-6.631-6.632a1.184 1.184 0 0 1 0-1.674l6.631-6.632a1.184 1.184 0 0 1 1.675 0',
  chevron_right:
    'M7.847 19.469a1.184 1.184 0 0 1 0-1.675L13.64 12 7.847 6.206A1.184 1.184 0 1 1 9.522 4.53l6.631 6.632a1.184 1.184 0 0 1 0 1.674L9.522 19.47a1.184 1.184 0 0 1-1.675 0',
  course:
    'M5.548 6.008C5.843 4.272 7.375 3 9.17 3h10.075c1.757 0 3.064 1.59 2.691 3.274l-1.497 6.75c-.275 1.24-1.395 2.126-2.69 2.126h-7.151l-2.433 4.43A2.76 2.76 0 0 1 5.741 21a2.314 2.314 0 0 1-2.282-2.702zM9.17 4.8c-.898 0-1.663.636-1.811 1.504l-2.096 12.33a.484.484 0 0 0 .478.566.92.92 0 0 0 .808-.473l2.433-4.43c.32-.583.94-.947 1.616-.947h7.15a.914.914 0 0 0 .898-.709l1.496-6.75a.905.905 0 0 0-.897-1.091z',
  instagram:
    'M6.998 0C3.139 0 0 3.142 0 7.002v10C0 20.86 3.142 24 7.002 24h10C20.86 24 24 20.858 24 16.998v-10C24 3.139 20.858 0 16.998 0zM19 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2m-7 2c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6m0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8',
  leaderboard:
    'M8.58466 3.0009L16.1247 3.05186L16.8441 10.0225C16.8277 11.3353 16.3886 12.564 15.6558 13.4579M15.6558 13.4579L12.5649 17.2228C12.4942 17.2217 12.4235 17.2212 12.3527 17.2212C12.2477 17.2212 12.1429 17.2224 12.0383 17.2247L8.97215 13.4182C8.24489 12.5149 7.81611 11.2805 7.81048 9.96719L8.58466 3.0009M9.68224 17.4879L7.41449 14.6726C6.89985 14.0335 6.50234 13.2961 6.23185 12.5041C4.42682 10.5036 2.79227 8.26829 2.09135 5.78464C1.67963 4.32529 2.72635 2.75391 4.30353 2.75391H6.5998L6.6117 2.64678C6.71636 1.70207 7.51839 0.993931 8.46361 1.00004L16.2743 1.05283C17.2165 1.06048 18.0037 1.77489 18.1004 2.71365L18.1045 2.75391H20.3282C21.9054 2.75391 22.9522 4.32521 22.5404 5.78456C21.8453 8.2501 20.2289 10.4708 18.4398 12.4598C18.1623 13.2913 17.7457 14.0634 17.202 14.7265L14.9475 17.4726C15.5807 17.6014 16.1762 17.7791 16.7116 18.0039C17.2837 18.2442 17.9273 18.6023 18.443 19.1084C18.9672 19.6229 19.406 20.3421 19.406 21.257V23.257H5.29016V21.257C5.29016 20.3397 5.7318 19.6197 6.25755 19.1056C6.77481 18.5999 7.41986 18.2425 7.99157 18.003C8.50633 17.7874 9.07656 17.6153 9.68224 17.4879ZM18.7411 8.92136C19.602 7.71709 20.2623 6.4946 20.6155 5.2417C20.652 5.11228 20.6241 4.98419 20.5541 4.88647C20.4836 4.78803 20.398 4.75391 20.3282 4.75391H18.311L18.7411 8.92136ZM5.91123 8.9498L6.37753 4.75391H4.30353C4.23374 4.75391 4.14811 4.78803 4.07762 4.88647C4.00765 4.98417 3.97969 5.11204 4.01617 5.24143M5.91123 8.9498C5.04056 7.73636 4.37258 6.50433 4.01617 5.24143Z',
  location:
    'm11.106 20.658-5.06-8.116c-2.529-4.055-.248-9.389 4.43-10.363a8.5 8.5 0 0 1 3.465 0c4.678.974 6.958 6.308 4.43 10.364l-5.06 8.115a1.3 1.3 0 0 1-2.205 0m1.102-1.984-4.46-7.205c-1.814-2.93-.165-6.772 3.209-7.475a6.1 6.1 0 0 1 2.503 0c3.374.703 5.023 4.545 3.209 7.475zm0-7.567a2.166 2.166 0 1 0 0-4.332 2.166 2.166 0 0 0 0 4.332',
  play: 'M12 0C5.38327 0 0 5.38327 0 12C0 18.6167 5.38327 24 12 24C18.6167 24 24 18.6167 24 12C24 5.38327 18.6167 0 12 0ZM10.3791 7.97921C9.8764 7.69995 9.25869 8.06343 9.25869 8.63848V15.1664C9.25869 15.7414 9.8764 16.1049 10.3791 15.8257L16.2542 12.5617C16.7715 12.2744 16.7715 11.5305 16.2542 11.2432L10.3791 7.97921Z',
  rss: 'M0 1.667C0 .747.746 0 1.667 0A18.333 18.333 0 0 1 20 18.333a1.667 1.667 0 1 1-3.333 0 15 15 0 0 0-15-15C.747 3.333 0 2.587 0 1.667m0 7.291c0-.92.746-1.666 1.667-1.666a11.04 11.04 0 0 1 11.041 11.041 1.667 1.667 0 1 1-3.333 0 7.71 7.71 0 0 0-7.708-7.708C.747 10.625 0 9.879 0 8.958m0 8.334a2.708 2.708 0 1 1 5.417 0 2.708 2.708 0 0 1-5.417 0',
  share:
    'M15.019 5.782 12 3 8.982 5.782a.82.82 0 0 0 0 1.227 1 1 0 0 0 1.33 0l.747-.688v7.305c0 .479.421.867.941.867s.941-.388.941-.867V6.32l.746.688a1 1 0 0 0 1.332 0 .82.82 0 0 0 0-1.227M7.685 8.42C5.65 8.42 4 9.942 4 11.818v5.423C4 19.317 5.826 21 8.078 21h7.844C18.174 21 20 19.317 20 17.24v-5.422c0-1.876-1.65-3.398-3.686-3.398-.52 0-.941.389-.941.868s.42.867.94.867c.997 0 1.805.745 1.805 1.663v5.423c0 1.118-.984 2.024-2.196 2.024H8.078c-1.212 0-2.196-.906-2.196-2.024v-5.423c0-.918.808-1.663 1.804-1.663.52 0 .941-.388.941-.867 0-.48-.42-.868-.94-.868',
  star: 'm12 17.165 5.53 3.335-1.463-6.29 4.88-4.229-6.434-.552L12 3.5 9.487 9.43l-6.434.551 4.88 4.23L6.471 20.5z',
  search:
    'M11.5 4a7.5 7.5 0 1 0 5.188 12.916 1 1 0 0 1 .228-.228A7.5 7.5 0 0 0 11.5 4m7.388 13.473A9.46 9.46 0 0 0 21 11.5a9.5 9.5 0 1 0-9.5 9.5 9.46 9.46 0 0 0 5.973-2.113l2.82 2.82a1 1 0 0 0 1.414-1.414z',
  help_circle:
    'M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12m11.258-3.976a2 2 0 0 0-2.225 1.308 1 1 0 1 1-1.886-.664 4 4 0 0 1 7.773 1.333c0 1.53-1.135 2.54-1.945 3.081a8 8 0 0 1-1.686.848l-.035.013-.011.003-.004.002h-.002L11.92 13l.316.949a1 1 0 0 1-.633-1.897l.016-.006.074-.027a6.051 6.051 0 0 0 1.172-.6c.69-.46 1.055-.95 1.055-1.419v-.001a2 2 0 0 0-1.662-1.975M11 17a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1',
  menu: 'M8.25 8a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2h-10a1 1 0 0 1-1-1M3.5 12a1 1 0 0 1 1-1h14.75a1 1 0 1 1 0 2H4.5a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h14.75a1 1 0 1 0 0-2z',
  close:
    'M17.883 6.117a1.12 1.12 0 0 1 0 1.584L13.583 12l4.3 4.3a1.12 1.12 0 1 1-1.584 1.583L12 13.583l-4.3 4.3A1.12 1.12 0 1 1 6.118 16.3l4.3-4.299-4.3-4.3A1.12 1.12 0 1 1 7.7 6.118l4.299 4.3 4.3-4.3a1.12 1.12 0 0 1 1.583 0',
  facebook:
    'M24 12.073C24 5.405 18.627 0 12 0S0 5.405 0 12.073C0 18.1 4.388 23.094 10.125 24v-8.437H7.078v-3.49h3.047v-2.66c0-3.025 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971H15.83c-1.491 0-1.956.93-1.956 1.887v2.263h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.1 24 12.073',
  twitter:
    'm16.12 24.513-5.795-8.63-7.255 8.63H0l8.963-10.657L0 .513h7.88l5.463 8.132L20.186.513h3.07l-8.547 10.163 9.292 13.837zm3.418-2.433h-2.066L4.395 2.945h2.067l5.237 7.662.906 1.33z',
  linkedin:
    'M22.223 0H1.772C.792 0 0 .773 0 1.73v20.536C0 23.222.792 24 1.772 24h20.451c.98 0 1.777-.778 1.777-1.73V1.73C24 .773 23.203 0 22.223 0M7.12 20.452H3.558V8.995H7.12zM5.34 7.434a2.064 2.064 0 1 1 0-4.125 2.063 2.063 0 0 1 0 4.125m15.112 13.018h-3.558v-5.57c0-1.326-.024-3.037-1.852-3.037-1.851 0-2.133 1.449-2.133 2.944v5.663H9.356V8.995h3.413v1.566h.047c.473-.9 1.636-1.852 3.365-1.852 3.605 0 4.27 2.372 4.27 5.457z',
}

export default Icons
