import { ReactNode } from 'react'
import { PlatformLinksStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  children: ReactNode[]
  subtitle: string
  title: string
}

export const PlatformLinks = ({ children, subtitle, title }: Props) => (
  <section className="flex flex-col items-center justify-center py-12 text-center">
    <nav
      aria-label="Platform Navigation"
      className="flex flex-col items-center justify-center"
    >
      <h3 className="font-semibold text-dxs lg:text-dmd">{title}</h3>
      <p className="mb-12 mt-4 text-grey-solid-2 text-xl">{subtitle}</p>
      <ul className="flex flex-col items-center md:flex-row space-y-2 md:space-y-0 md:space-x-8">
        {children}
      </ul>
    </nav>
  </section>
)

export const PlatformLinksSb = ({ blok }: { blok: PlatformLinksStoryblok }) => (
  <PlatformLinks
    children={blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
    subtitle={blok.subtitle}
    title={blok.title}
  />
)
