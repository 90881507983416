import {
  render,
  NODE_PARAGRAPH,
  MARK_LINK,
  MARK_BOLD,
  MARK_ITALIC,
} from 'storyblok-rich-text-react-renderer'

import { RichtextStoryblok } from '../../../types/storyblok'
import { RichTextLink } from './RichTextLink'

interface Props {
  className?: string
  content: RichtextStoryblok
}

export const RichText = ({ className = '', content }: Props) => {
  const html = render(content, {
    markResolvers: {
      [MARK_LINK]: RichTextLink,
      [MARK_BOLD]: (children) => <strong>{children}</strong>,
      [MARK_ITALIC]: (children) => <em>{children}</em>,
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children) => <p className={className}>{children}</p>,
    },
  })
  return <>{html}</>
}
