import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  className?: string
  children: ReactNode
  padding?: string
}

export const Header = ({
  children,
  className = '',
  padding = 'pt-16',
}: Props) => (
  <header className={clsx(className, padding, 'flex flex-col w-full')}>
    {children}
  </header>
)
