import React from 'react';

import { AuthenticationProvider } from './stories/providers/AuthenticationProvider.tsx';
import { ApiProvider } from './stories/providers/ApiProvider.tsx';
import { GlossaryProvider } from './stories/providers/GlossaryProvider.tsx';
import { RoundGroupsProvider } from './stories/providers/RoundGroupsProvider.tsx';

import { RoundGroupsPage } from './stories/pages/RoundGroupsPage.tsx';

const RoundGroups = (props) => (
  <ApiProvider pop={props.pop} rex={props.rex}>
    <AuthenticationProvider>
      <GlossaryProvider glossary={{ terms: [] }}>
        <RoundGroupsProvider
          initData={props.data}
          polling={props.polling_interval_in_seconds}
        >
          <RoundGroupsPage webview={Boolean(props.webview)} />
        </RoundGroupsProvider>
      </GlossaryProvider>
    </AuthenticationProvider>
  </ApiProvider>
);

export default RoundGroups;
