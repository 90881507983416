import clsx from 'clsx'

interface Props {
  colorName: string | 'birdie' | 'bogey' | 'bogey2' | 'eagle' | 'par'
  score: number
  scratched: boolean
}

export const ScoreValue = ({ colorName, score, scratched }: Props) => (
  <span className="inline-flex flex-col items-center justify-center relative">
    <span>{score}</span>
    {scratched && (
      <span
        className={clsx(
          'absolute bg-white-solid-1 h-[1px] ring-offset-0 ring-1 rotate-45 w-3',
          {
            'ring-score-solid-2-bogey': colorName === 'bogey2',
            'ring-score-solid-birdie': colorName === 'birdie',
            'ring-score-solid-bogey': colorName === 'bogey',
            'ring-score-solid-eagle': colorName === 'eagle',
            'ring-score-solid-par': colorName === 'par',
          }
        )}
      >
        &nbsp;
      </span>
    )}
  </span>
)
