import { ReactNode } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { FeatureTextStoryblok } from '../../../../types/storyblok'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  children?: ReactNode[]
  text: string
  title: string
}

export const FeatureText = ({ children, text, title }: Props) => (
  <div className="flex-1 order-last lg:order-none mt-5 lg:mt-0">
    <div className="flex flex-col text-center pb-8 lg:pb-0 lg:text-left">
      <h3 className="font-semibold mb-2 lg:mb-3 text-xl lg:text-dsm">
        {title}
      </h3>
      <GlossaryText
        className="text-grey-solid-2 text-sm lg:text-lg"
        text={text}
      />
      {children && (
        <ul className="flex flex-col items-center lg:items-start mt-6 lg:mt-11 space-y-4">
          {children}
        </ul>
      )}
    </div>
  </div>
)

export const FeatureTextSb = ({ blok }: { blok: FeatureTextStoryblok }) => (
  <FeatureText
    children={(blok.items || []).map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
    text={blok.text}
    title={blok.title}
  />
)
