import clsx from 'clsx'
import React, { useCallback, useRef, useState } from 'react'

import i18n from '../../assets/locales.json'

import { data } from './Nav.helpers'
import { NavAuthentication } from './NavAuthentication'
import { Button, NavButton } from '../../atoms'
import { NavLogo } from './NavLogo'
import { useClickOutside } from '../../hooks'

interface Props {
  color?: string
  className?: string
}

export const NavDesktop = ({
  color = 'bg-white-solid-1/85',
  className = '',
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null)

  const [openApps, setOpenApps] = useState(false)
  const [openProfile, setOpenProfile] = useState(false)
  const [app, setApp] = useState<'hole19' | 'core'>()
  const [features, setFeatures] = useState<'hole19' | 'core'>()

  const reset = useCallback(() => {
    setOpenApps(false)
    setOpenProfile(false)
    setApp(undefined)
    setFeatures(undefined)
  }, [])

  const handleApp = useCallback((key: 'hole19' | 'core') => {
    setApp(key)
    setFeatures(undefined)
  }, [])

  const handleOpen = useCallback((menu: 'apps' | 'profile') => {
    setOpenApps(menu === 'apps')
    setOpenProfile(menu === 'profile')
  }, [])

  useClickOutside(divRef, reset)

  return (
    <div
      className={clsx(
        openApps || openProfile ? 'bg-white-solid-1 shadow-nav' : color,
        className,
        'backdrop-blur-md ease-in-out duration-100 transition-colors w-full'
      )}
      ref={divRef}
      role="banner"
    >
      <div className="flex justify-between items-start max-w-7xl mx-auto px-4 lg:px-8">
        <NavLogo />
        <nav
          className="flex flex-col flew-grow max-w-[846px] ml-auto pl-5 py-[16px] w-full"
          role="navigation"
        >
          <div className="flex justify-between gap-x-4">
            <NavButton
              active={openApps}
              fontWeight="semibold"
              handleClick={() => handleOpen('apps')}
              handleMouseEnter={() => handleOpen('apps')}
              icon="chevron_down"
              text={data.main.dropdown}
            />
            {data.main.links.map((link) => (
              <NavButton
                key={`main-${link.text}`}
                fontWeight="semibold"
                handleMouseEnter={reset}
                href={link.href}
                target={link.target}
                text={link.text}
              />
            ))}
            <div className="flex justify-end">
              <NavAuthentication
                handleMouseEnter={() => handleOpen('profile')}
              />
            </div>
          </div>
          <div
            className={clsx('flex gap-x-3', {
              'pt-4': openApps || openProfile,
            })}
          >
            {openProfile && (
              <div className="flex flex-col items-start ml-auto gap-y-1">
                {data.profile.links.map((link) => (
                  <NavButton
                    key={`profile-${link.text}`}
                    href={link.href}
                    text={link.text}
                  />
                ))}
                <div className="flex flex-col pl-4 pt-2">
                  <Button
                    className="text-nowrap"
                    color="danger-solid-1"
                    href="/users/sign_out"
                    outline={true}
                    size="xl"
                    text={i18n.global.signOut}
                  />
                </div>
              </div>
            )}
            {openApps && (
              <div className="flex flex-col gap-y-1">
                {[data.hole19, data.core].map((item) => (
                  <NavButton
                    key={`app-${item.key}`}
                    active={app === item.key}
                    href={item.featuresHref}
                    handleMouseEnter={() => handleApp(item.key)}
                    icon="chevron_right"
                    text={item.appText}
                  />
                ))}
              </div>
            )}
            {[data.hole19, data.core].map((item) =>
              app === item.key ? (
                <React.Fragment key={`${item.key}-${item.featuresText}`}>
                  <div className="flex flex-col gap-y-1">
                    <NavButton
                      active={Boolean(features)}
                      handleClick={() => setFeatures(item.key)}
                      handleMouseEnter={() => setFeatures(item.key)}
                      icon="chevron_right"
                      text={item.featuresText}
                    />
                    {item.platforms.map((link) => (
                      <NavButton
                        key={`${item.key}-${link.text}`}
                        href={link.href}
                        text={link.text}
                      />
                    ))}
                  </div>
                  {features === item.key && (
                    <div className="basis-1/2 columns-2 gap-x-3 space-y-1">
                      {item.features.map((link) => (
                        <NavButton
                          key={`${item.key}-${link.text}`}
                          href={link.href}
                          text={link.text}
                        />
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ) : null
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}
