import { ISbComponentType } from 'storyblok-js-client'

import { GlossaryText, Label } from '..'

interface Props {
  text: string
  title: string
}

export const CourseDescription = ({ text, title }: Props) => text ? (
  <Label text={title}>
    <section className="border border-grey-solid-6 px-4 md:px-5 py-4 rounded">
      <GlossaryText className="text-grey-solid-2 text-lg" text={text} />
    </section>
  </Label>
) : null

export type CourseDescriptionStoryblok = ISbComponentType<'CourseDescription'> &
  Props

export const CourseDescriptionSb = ({
  blok,
}: {
  blok: CourseDescriptionStoryblok
}) => <CourseDescription {...blok} />
