import { ReactNode } from 'react'

import { Footer, Navbar } from '../organisms'
import { useAppState } from '../hooks'

interface Props {
  children?: ReactNode
  footerClass?: string
  navColor?: string
  webview?: boolean
}

export const Layout = ({
  children = null,
  footerClass = '',
  navColor,
  webview = false,
}: Props) => {
  const appState = useAppState()

  if (webview || appState.webview) {
    return <main>{children}</main>
  }

  return (
    <>
      <Navbar className="fixed top-0 z-[100]" color={navColor} />
      <main>{children}</main>
      <Footer className={footerClass} />
    </>
  )
}
