import { ReactNode } from 'react'
import { Link } from '../Link/Link'

interface Props {
  children: ReactNode
  text: string
  link?: {
    text: string
    url: string
  }
}

export const Label = ({ children, link, text }: Props) => (
  <div className="flex flex-col space-y-3 w-full">
    <div className="flex font-semibold justify-between text-sm">
      <h3 className="text-grey-solid-3">{text}</h3>
      {link && (
        <Link textClass="text-primary-solid-2 text-xs" href={link.url}>
          {link.text}
        </Link>
      )}
    </div>
    <div>{children}</div>
  </div>
)
