import { useState } from 'react'

import { Icon } from '../../atoms'
import { YoutubePlayerStoryblok } from '../../../../types'

interface Props {
  poster: {
    alt: string | null
    filename: string | null
  }
  title: string
  videoId: string
}

export const YoutubePlayer = ({
  poster,
  title,
  videoId,
}: Props) => {
  const [showPoster, setShowPoster] = useState(true)

  return (
    <div className="aspect-16/9 bg-grey-solid-0 flex justify-center overflow-hidden relative rounded-sm w-full">
      {showPoster ? (
        <button
          className="flex items-center justify-center h-full w-full"
          onClick={() => setShowPoster(false)}
          type="button"
        >
          {poster.filename && (
            <img
              alt={poster.alt || title}
              className="absolute inset-0 h-full object-cover w-full"
              src={poster.filename}
            />
          )}
          <span className="flex items-center justify-center h-14 w-14 md:h-24 md:w-24 rounded-full z-[1]">
            <Icon className="backdrop-blur-sm rounded-full text-white-solid-1/85" icon="play" size="full" />
          </span>
        </button>
      ) : (
        <iframe
          allow="autoplay"
          allowFullScreen
          className="h-full w-full"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
        />
      )}
    </div>
  )
}

export const YoutubePlayerSb = ({ blok }: { blok: YoutubePlayerStoryblok }) => (
  <YoutubePlayer {...blok} />
)
