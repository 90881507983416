import { Icon } from '../../atoms'

export interface PageCardProps {
  category: string
  image: {
    alt: string
    filename: string
  }
  title: string
  url: string
}

export const PageCard = ({ category, image, title, url }: PageCardProps) => (
  <a
    className="flex border border-grey-solid-6 overflow-hidden rounded-md max-w-[400px] w-full"
    href={url}
  >
    <div className="aspect-1/1 h-[160px] relative w-[160px]">
      {image.filename && (
        <img
          alt={image.alt || ''}
          className="absolute inset-0 object-cover h-full w-full"
          src={image.filename}
        />
      )}
    </div>
    <div className="flex flex-col items-start justify-between p-4 w-full">
      <div className="w-full">
        <div className="flex justify-between space-x-4 text-grey-solid-3 w-full">
          <h4 className="font-semibold text-xs">{category}</h4>
          <Icon icon="arrow_top_right" size="sm" />
        </div>
        <h3 className="font-semibold line-clamp-2 text-balance text-md">
          {title}
        </h3>
      </div>
      <p className="break-all line-clamp-2 text-xs text-grey-solid-4 uppercase">
        {url.split('https://')?.[1]}
      </p>
    </div>
  </a>
)
