import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import { Content, Header, Layout } from '../templates'
import { PremiumPageStoryblok } from '../../../types/storyblok'
import { PremiumProductsProvider } from '../providers'

export const PremiumPage = ({ blok }: { blok: PremiumPageStoryblok }) => (
  <PremiumProductsProvider>
    <Layout {...storyblokEditable(blok)}>
      <Header padding="p-0">
        {blok.head.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </Header>
      <Content>
        {blok.body.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </Content>
    </Layout>
  </PremiumProductsProvider>
)

export default PremiumPage
