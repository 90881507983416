import { LinkGrid, LinkGridProps } from '../../molecules'

interface Props {
  items: LinkGridProps[]
  text: string
  title: string
}

export const CoursesLinks = ({ items, text, title }: Props) => {
  const total = items.reduce((acc, curr) => {
    return acc + curr.items.length
  }, 0)

  if (total === 0) {
    return null
  }

  return (
    <section className="flex flex-col space-y-8 lg:space-y-12">
      <div className="flex flex-col space-y-2 lg:w-3/4">
        <h2 className="font-semibold text-dsm lg:text-dlg">{title}</h2>
        <p className="text-grey-solid-2 text-md lg:text-xl">{text}</p>
      </div>
      <div className="flex flex-col space-y-8 lg:space-y-12">
        {items.map((item, index) => (
          <LinkGrid key={index} {...item} />
        ))}
      </div>
    </section>
  )
}
