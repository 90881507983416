import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'

import CourseProfileHelpers from '../../../helpers/courseProfile'
import i18n from '../assets/locales.json'

import { Props as IconsProps } from '../molecules/Forecast/ForecastIcon'
import { useApi } from './useApi'

interface State {
  data: {
    day: string
    icon: IconsProps['icon']
    temperature: string
  }[]
  error: boolean
  loading: boolean
}

export const useForecast = (
  latitude?: number,
  longitude?: number,
  unit?: string
) => {
  const { rex } = useApi()

  const [state, setState] = useState<State>({
    data: [],
    error: false,
    loading: true,
  })

  const formatTemperature = useCallback(
    (today: boolean, value: number) => {
      if (unit === 'fahreneit') {
        return `${(value * 1.8 + 32).toFixed(0)}F`
      }
      return today ? `${value.toFixed(0)}℃` : `${value.toFixed(0)}°`
    },
    [unit]
  )

  const parseWeather = useCallback(
    (weather: WeatherData) => [
      {
        day: i18n.global.today,
        icon: weather.currently.icon as IconsProps['icon'],
        temperature: formatTemperature(true, weather.currently.temperature),
      },
      ...weather.daily.slice(1, 6).map((value) => ({
        day: format(new Date(value.time * 1000), 'EEE'),
        icon: value.icon as IconsProps['icon'],
        temperature: formatTemperature(false, value.temperatureMax),
      })),
    ],
    [formatTemperature]
  )

  const fetchForecast = useCallback(
    async (lat: number, lon: number) => {
      try {
        const res = await CourseProfileHelpers.fetchForecast(rex, lat, lon)
        const parsed = parseWeather(res.data)
        setState({ data: parsed, loading: false, error: false })
      } catch (err) {
        setState({ data: [], loading: false, error: true })
      }
    },
    [parseWeather, rex]
  )

  useEffect(() => {
    if (latitude && longitude) {
      fetchForecast(latitude, longitude)
    }
  }, [fetchForecast, latitude, longitude])

  return state
}
