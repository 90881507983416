import chunk from 'lodash.chunk'
import clsx from 'clsx'

import { AdvertisePartnershipsStoryblok } from '../../../../types'

interface Props {
  items?: {
    alt: string | null
    filename: string | null
  }[]
  title: string
}

export const AdvertisePartnerships = ({ items = [], title }: Props) => (
  <section className="flex flex-col space-y-8 md:space-y-16">
    <h2 className="font-semibold text-dxs md:text-dmd text-center w-full">
      {title}
    </h2>
    <div className="grid grid-cols-3 md:grid-cols-5 gap-x-4 md:gap-x-8 md:px-11 md:pb-8">
      {chunk(items, 3).map((column, index) => (
        <div
          key={`col-${index}`}
          className={clsx('flex flex-col space-y-8', {
            'hidden md:block': index > 2,
          })}
        >
          {column.map((item) => (
            <img
              key={`col-${index}-${item.filename}`}
              alt={item.alt || ''}
              src={item.filename || ''}
              width="100%"
            />
          ))}
        </div>
      ))}
    </div>
  </section>
)

export const AdvertisePartnershipsSb = ({
  blok,
}: {
  blok: AdvertisePartnershipsStoryblok
}) => <AdvertisePartnerships {...blok} />
