import { useMemo } from 'react'

interface Props {
  holes: GolfRound['holes']
  isNet: boolean
  isStableford: boolean
  title: string
}

export const HolesScore = ({ holes, isNet, isStableford, title }: Props) => {
  const state = useMemo(
    () => ({
      par: holes.reduce((acc, { par }) => acc + par, 0),
      index: holes.reduce((acc, { strokeIndex }) => acc + strokeIndex, 0),
      total: holes.reduce((acc, { totalOfStrokes }) => acc + totalOfStrokes, 0),
      sup: holes.reduce(
        (acc, c) => acc + (isNet ? c.stablefordNet : c.stablefordGross),
        0
      ),
    }),
    [holes, isNet]
  )

  if (holes.length === 0) {
    return null
  }

  return (
    <div className="bg-grey-solid-7 col-span-1 divide-y divide-grey-alpha-3 font-semibold min-w-18 text-grey-solid-3 text-center">
      <div className="py-1 uppercase text-grey-solid-5 text-xs">{title}</div>
      <div className="py-1 text-sm">{state.par}</div>
      <div className="py-1 text-sm">{state.index}</div>
      <div className="flex items-center justify-center h-11">
        <span className="text-grey-solid-2 text-dxs">
          {state.total}
          {isStableford && (
            <sup className="text-grey-solid-3 text-md">{state.sup}</sup>
          )}
        </span>
      </div>
    </div>
  )
}
