import i18n from '../../assets/locales.json'

export const LeaderboardHeader = () => (
  <div className="flex font-semibold items-center justify-between px-4 text-grey-solid-3 text-xs uppercase">
    <div>{i18n.global.player}</div>
    <div className="flex items-center md:mr-2 space-x-2">
      <span>{i18n.global.score}</span>
      <span>{i18n.global.topar}</span>
      <span>{i18n.global.thru}</span>
    </div>
  </div>
)
