import { createContext } from 'react';
import { ISbStories, ISbStoryData } from 'storyblok-js-client';

type SbRelationsContextType = {
  recentBlogPosts?: ISbStories['data'];
  rels: ISbStoryData[];
};

export const SbRelationsContext = createContext<SbRelationsContextType>({
  recentBlogPosts: undefined,
  rels: [],
});
