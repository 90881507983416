import chunk from 'lodash.chunk';

import { CarouselGrid, CarouselGridItem } from '../CarouselGrid/CarouselGrid';
import { useGlossary } from '../../hooks';
import { GlossaryListStoryblok } from '../../../../types';

interface Props {
  listLength?: number;
  title: string;
}

export const GlossaryList = ({ listLength = 4, title }: Props) => {
  const {
    glossary: { terms },
  } = useGlossary();
  const sorted = terms.sort((a, b) => a.term.localeCompare(b.term));

  return (
    <section className="flex flex-col space-y-5">
      <h2 className="font-bold text-xl">{title}</h2>
      <CarouselGrid cols="3" gap="2">
        {chunk(sorted, listLength).map((group, index) => (
          <CarouselGridItem key={index}>
            <div className="flex flex-col space-y-2">
              {group.map((link) => (
                <a key={link.full_slug} href={`/${link.full_slug}`}>
                  {link.term}
                </a>
              ))}
            </div>
          </CarouselGridItem>
        ))}
      </CarouselGrid>
    </section>
  );
};

export const GlossaryListSb = ({ blok }: { blok: GlossaryListStoryblok }) => (
  <GlossaryList {...blok} />
);
