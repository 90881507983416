import { GlossaryRef, GlossaryTerm, RichtextStoryblok } from '../../../types'
import { removePunctuation, stringToRichText } from './strings'

const matchTermInText = (term: string, value: string) => {
  const word = term.toLowerCase()
  const text = value.toLowerCase()

  const wholeWordMatch = removePunctuation(text).split(' ').indexOf(word) !== -1
  const multiWordMatch = word.split(' ').length > 1 && text.includes(word)

  if (wholeWordMatch || multiWordMatch) {
    const index = text.indexOf(word)
    return { index, text: value.substring(index, index + term.length) }
  }

  return undefined
}

const matchTermInMatches = (term: string, matches: Record<string, boolean>) => {
  const word = term.toLowerCase()
  const keys = Object.keys(matches).join(' ').toLowerCase()
  return keys.includes(word)
}

const nodeIsLink = (node: any) => {
  return node?.marks?.[0]?.type === 'link'
}

export const parseGlossaryLinks = (
  richText: RichtextStoryblok,
  glossary: GlossaryTerm[]
) => {
  let matchesMap: { [key: string]: boolean } = {}
  const sortedGlossary = glossary.sort((a, b) => b.term.length - a.term.length)

  const parseContent = (content: RichtextStoryblok['content'] = []) => {
    return content.reduce((acc: any, curr) => {
      let text = curr.text || ''
      if (nodeIsLink(curr) || !text.trim()) {
        return [...acc, curr]
      }

      const refs: GlossaryRef[] = []
      sortedGlossary.forEach((term) => {
        const found = matchTermInText(term.term, text)
        const taken = matchTermInMatches(term.term, matchesMap)
        if (found && !taken) {
          refs.push({
            full_slug: `/${term.full_slug}`,
            index: found.index,
            name: found.text,
          })
          matchesMap[term.term] = true
        }
      })

      if (refs.length === 0) return [...acc, curr]

      const anchors = refs
        .sort((a, b) => a.index - b.index)
        .reduce((nodes: any, ref, index) => {
          const [start, ...rest] = text.split(ref.name)
          text = rest.join(ref.name)
          const anchor = {
            attrs: {
              href: ref.full_slug,
              linktype: 'glossary',
            },
            type: 'link',
          }
          return [
            ...nodes,
            { text: start || null, type: 'text' },
            { marks: [anchor], text: ref.name, type: 'text' },
            {
              text: index === refs.length - 1 ? text : null,
              type: 'text',
            },
          ]
        }, [])

      return [...acc, ...anchors]
    }, [])
  }

  return {
    ...richText,
    content: (richText.content || []).reduce((richTextContent: any, node) => {
      switch (node.type) {
        case 'paragraph':
          return [
            ...richTextContent,
            {
              ...node,
              content: parseContent(node.content),
            },
          ]
        default:
          return [...richTextContent, node]
      }
    }, []),
  }
}

export const withGlossaryLinks = (
  text: string | RichtextStoryblok = {
    type: 'doc',
    content: [],
  },
  glossary: GlossaryTerm[] = []
): RichtextStoryblok => {
  let richText = typeof text === 'string' ? stringToRichText(text) : text
  if (glossary.length === 0) {
    return richText
  }
  try {
    return parseGlossaryLinks(richText, glossary)
  } catch (err) {
    console.log(err)
  }
  return richText
}
