import clsx from 'clsx'

import CORESymbolSVG from '../assets/CORE-symbol.svg'

interface Props {
  className?: string
}

export const CoreWebLogo = ({ className = '' }: Props) => (
  <div className={clsx(className, 'bg-grey-solid-0 rounded')}>
    <img
      alt="CORE symbol"
      height="70px"
      src={CORESymbolSVG}
      width="70px"
    />
  </div>
)
