import { CourseCard, CourseCardProps } from '../../molecules'

interface Props {
  caption?: string
  items: CourseCardProps[]
}

export const QueryResults = ({ caption, items }: Props) => (
  <section className="flex flex-col space-y-6">
    {caption && (
      <p className="font-semibold text-grey-solid-2 text-lg md:text-xl">
        {caption}
      </p>
    )}
    <div className="flex flex-col space-y-2 md:space-y-4">
      {items.map((item, index) => (
        <CourseCard key={index} compact={false} {...item} />
      ))}
    </div>
  </section>
)
