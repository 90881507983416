import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { useMemo } from 'react'

import { Content, Layout } from '../templates'
import { BlogPostPageStoryblok } from '../../../types'
import { slugToLabel } from '../helpers'
import { useSbRelations } from '../hooks'
import i18n from '../assets/locales.json'
import constants from '../assets/constants.json'

export const BlogPostPage = ({ blok }: { blok: BlogPostPageStoryblok }) => {
  const { rels } = useSbRelations()
  const author = rels.find(({ uuid }) => blok.author.includes(uuid))

  const head = useMemo(
    () => [
      {
        _uid: 'h1',
        component: 'Breadcrumbs',
        items: [
          {
            component: 'BreadcrumbItem',
            text: i18n.global.blog,
            url: { cached_url: constants.routes.blog },
          },
          {
            component: 'BreadcrumbItem',
            text: blok.title,
            current: true,
            url: '',
          },
        ],
      },
      {
        _uid: 'h2',
        component: 'Heading',
        datetime: blok.publishDate,
        title: blok.title,
        text: blok.subtitle,
      },
    ],
    [blok.title, blok.subtitle, blok.publishDate]
  )

  const body = useMemo(
    () => [
      ...(blok.body || []),
      ...(author
        ? [
            {
              _uid: author.uuid,
              ...author.content,
            },
          ]
        : []),
      {
        _uid: 'b1',
        component: 'Badges',
        items: blok.categories.map((slug) => ({
          text: slugToLabel(slug.toString()),
          url: `${constants.routes.blog}?category=${slug}`,
        })),
      },
    ],
    [author, blok.body, blok.categories]
  )

  return (
    <Layout>
      <Content>
        <header className="flex flex-col space-y-8 py-8 md:py-8 lg:py-0">
          {head.map((nestedBlok) => (
            <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
          ))}
        </header>
        <article className="flex flex-col mx-auto max-w-blog space-y-8 w-full">
          {body.map((nestedBlok) => (
            <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
          ))}
        </article>
        <footer className="flex flex-col space-y-8 lg:space-y-16">
          {blok.footer?.map((nestedBlok) => (
            <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
          ))}
        </footer>
      </Content>
    </Layout>
  )
}
