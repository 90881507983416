import { Link } from '../Link/Link'
import { PlatformLinkItemStoryblok } from '../../../../types/storyblok'
import { storyblokLink } from '../../helpers'

interface Props {
  text: string
  url: string
}

export const PlatformLinkItem = ({ text, url }: Props) => (
  <li>
    <Link
      iconClass="text-grey-solid-3"
      textClass="font-semibold text-grey-solid-0 text-xl lg:text-dxs"
      icon="arrow_circle_right"
      href={url}
    >
      {text}
    </Link>
  </li>
)

export const PlatformLinkItemSb = ({
  blok,
}: {
  blok: PlatformLinkItemStoryblok
}) => <PlatformLinkItem text={blok.text} url={storyblokLink(blok.url)} />
