import constants from '../../assets/constants.json'
import i18n from '../../assets/locales.json'

type Link = { href: string; target?: string; text: string }

export const data: {
  main: {
    dropdown: string
    links: Link[]
  }
  core: {
    key: 'core'
    appText: string
    features: Link[]
    featuresHref: string
    featuresText: string
    platforms: Link[]
  }
  hole19: {
    key: 'hole19'
    appText: string
    features: Link[]
    featuresHref: string
    featuresText: string
    platforms: Link[]
  }
  profile: {
    links: Link[]
  }
} = {
  main: {
    dropdown: i18n.global.apps,
    links: [
      { href: constants.routes.courses, text: i18n.global.discoverCourses },
      { href: constants.routes.blog.root, text: i18n.global.clubhouse },
      { href: constants.routes.shop, target: 'new', text: i18n.global.shop },
    ],
  },
  core: {
    key: 'core',
    appText: i18n.global.coreGolfApp,
    featuresHref: constants.routes.coreGolf.root,
    featuresText: i18n.global.appFeatures,
    features: [
      {
        href: constants.routes.coreGolf.features.personalizedPlans,
        text: i18n.coreFeatures.personalizedPlans,
      },
      {
        href: constants.routes.coreGolf.features.createYourPlan,
        text: i18n.coreFeatures.createYourPlan,
      },
      {
        href: constants.routes.coreGolf.features.focusedPlans,
        text: i18n.coreFeatures.focusedPlans,
      },
      {
        href: constants.routes.coreGolf.features.shareYourPlan,
        text: i18n.coreFeatures.shareYourPlan,
      },
      {
        href: constants.routes.coreGolf.features.drills,
        text: i18n.coreFeatures.drills,
      },
      {
        href: constants.routes.coreGolf.features.discipline,
        text: i18n.coreFeatures.discipline,
      },
      {
        href: constants.routes.coreGolf.features.trackYourProgress,
        text: i18n.coreFeatures.trackYourProgress,
      },
      {
        href: constants.routes.coreGolf.features.recordYourself,
        text: i18n.coreFeatures.recordYourself,
      },
    ],
    platforms: [
      { href: constants.routes.coreGolf.apple, text: i18n.global.appleDevices },
      { href: constants.routes.coreGolf.premium, text: i18n.global.premium },
    ],
  },
  hole19: {
    key: 'hole19',
    appText: i18n.global.hole19GolfApp,
    featuresHref: constants.routes.hole19.root,
    featuresText: i18n.global.appFeatures,
    features: [
      {
        href: constants.routes.hole19.features.gpsYardages,
        text: i18n.hole19features.gpsYardages,
      },
      {
        href: constants.routes.hole19.features.livePlay,
        text: i18n.hole19features.livePlay,
      },
      {
        href: constants.routes.hole19.features.playsLikeDistances,
        text: i18n.hole19features.playsLikeDistances,
      },
      {
        href: constants.routes.hole19.features.clubRecommendation,
        text: i18n.hole19features.clubRecommendation,
      },
      {
        href: constants.routes.hole19.features.statistics,
        text: i18n.hole19features.statistics,
      },
      {
        href: constants.routes.hole19.features.handicapCalculator,
        text: i18n.hole19features.handicapCalculator,
      },
      {
        href: constants.routes.hole19.features.gameModes,
        text: i18n.hole19features.gameModes,
      },
    ],
    platforms: [
      { href: constants.routes.hole19.apple, text: i18n.global.appleDevices },
      {
        href: constants.routes.hole19.android,
        text: i18n.global.androidDevices,
      },
      { href: constants.routes.hole19.premium, text: i18n.global.premium },
      {
        href: constants.routes.helpCenter,
        target: 'new',
        text: i18n.global.faq,
      },
    ],
  },
  profile: {
    links: [
      { href: constants.routes.performance, text: i18n.global.performance },
      { href: constants.routes.subscription, text: i18n.global.subscription },
      {
        href: constants.routes.notifications,
        text: i18n.global.emailsAndNotifications,
      },
    ],
  },
}
