import { BreadcrumbItemProps, CourseCardProps } from '../molecules'
import {
  LocationBreadcrumb,
  PartialCourseIdentity,
  Place,
} from '../../../types'

import constants from '../assets/constants.json'
import i18n from '../assets/locales.json'

const rootUrl = constants.routes.courses

export const mapBreadcrumbs = (
  name: string,
  country?: Place,
  region?: Place,
  city?: Place
) => {
  const crumbs = [{ text: i18n.global.allCourses, url: rootUrl }]
  if (country) {
    crumbs.push({
      text: country.name,
      url: `${rootUrl}/countries/${country.slug}`,
    })
  }
  if (country && region) {
    crumbs.push({
      text: region.name,
      url: `${rootUrl}/countries/${country.slug}/regions/${region.slug}`,
    })
  }
  if (country && city) {
    crumbs.push({
      text: city.name,
      url: `${rootUrl}/countries/${country.slug}/cities/${city.slug}`,
    })
  }
  return [...crumbs, { current: true, text: name, url: '' }]
}

export const mapLocationBreadcrumbs = (
  breadcrumbs: LocationBreadcrumb[],
  city_slug: string | null,
  country_slug: string,
  region_slug: string | null
) => {
  const items: BreadcrumbItemProps[] = [
    { text: i18n.global.allCourses, url: rootUrl },
  ]

  const country = breadcrumbs.find((b) => b.category === 'Country')
  if (country && country.slug === country_slug) {
    items.push({
      current: !region_slug && !city_slug,
      text: country.name,
      url: `${rootUrl}/countries/${country.slug}`,
    })
  }

  const city = breadcrumbs.find((b) => b.category === 'City')
  if (city && city.slug === city_slug) {
    items.push({
      current: Boolean(!region_slug && city_slug),
      text: city.name,
      url: `${rootUrl}/countries/${country_slug}/cities/${city.slug}`,
    })
  }

  const region = breadcrumbs.find((b) => b.category === 'Region')
  if (region && region.slug === region_slug) {
    items.push({
      current: Boolean(region_slug),
      text: region.name,
      url: `${rootUrl}/countries/${country_slug}/regions/${region.slug}`,
    })
  }

  return items
}

export const getRandomEntry = (arr: string[]) => {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

export const orderOptions = [
  {
    key: `${i18n.locationCourses.sorting.nameAscending}`,
    value: 'name:asc',
  },
  {
    key: `${i18n.locationCourses.sorting.nameDescending}`,
    value: 'name:desc',
  },
  {
    key: `${i18n.locationCourses.sorting.ratingAscending}`,
    value: 'rating:asc',
  },
  {
    key: `${i18n.locationCourses.sorting.ratingDescending}`,
    value: 'rating:desc',
  },
  {
    key: `${i18n.locationCourses.sorting.popularityAscending}`,
    value: 'popularity:asc',
  },
  {
    key: `${i18n.locationCourses.sorting.popularityDescending}`,
    value: 'popularity:desc',
  },
]

export const mapCourseIdentities = (
  courses: PartialCourseIdentity[],
  defaultImages: string[]
): CourseCardProps[] => {
  return courses.map((course) => ({
    description: course.description,
    href: `${constants.routes.courses}/${course.slug}`,
    image: {
      alt: course.name,
      filename:
        course.images[0]?.url ||
        getRandomEntry(defaultImages) ||
        i18n.placeholderImage,
    },
    location: course.location,
    rating: course.avg_rating,
    title: course.name,
  }))
}

export const getTotalPages = (length: number, perPage: number) => {
  if (length <= 0 || perPage <= 0) {
    return 0
  }
  return Math.ceil(length / perPage)
}
