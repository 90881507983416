import { BreadcrumbItemProps } from '../BreadcrumbItem/BreadcrumbItem'

interface Props {
  items: BreadcrumbItemProps[]
}

export const BreadcrumbsLdJson = ({ items }: Props) => {
  const json = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.url,
        name: item.text,
      },
    })),
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
    />
  )
}
