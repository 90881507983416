import { ISbStoryData } from 'storyblok-js-client'

import {
  AuthorStoryblok,
  BlogPostPageStoryblok,
  RecentItemsStoryblok,
} from '../../../../types'

import { Button } from '../../atoms'
import { mapStoriesToPosts, storyblokLink } from '../../helpers'
import { useCategories, useSbRelations } from '../../hooks'
import { PostCard } from '../PostCard/PostCard'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  buttonText: string
  buttonLink: string
  caption?: string
  text: string
  title: string
  variant: 'blog'
}

export const RecentItems = ({
  buttonLink,
  buttonText,
  caption,
  text,
  title,
  variant,
}: Props) => {
  const { recentBlogPosts } = useSbRelations()
  const { categories } = useCategories()

  if (!recentBlogPosts?.stories.length) {
    return null
  }

  return (
    <section className="flex flex-col space-y-6 lg:space-y-12">
      <div className="flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex flex-col">
          {caption && (
            <p className="font-semibold mb-2 text-sm md:text-md text-primary-solid-2">
              {caption}
            </p>
          )}
          <h2 className="font-semibold mb-4 text-dsm md:text-dmd">{title}</h2>
          <GlossaryText
            className="text-md md:text-xl text-grey-solid-2"
            text={text}
          />
        </div>
        <div className="flex flex-col items-start shrink-0">
          <Button
            color="primary-solid-2"
            href={buttonLink}
            size="xl"
            text={buttonText}
          />
        </div>
      </div>
      {variant === 'blog' && (
        <section className="grid grid-cols-1 mb-8 md:grid-cols-3 gap-8">
          {mapStoriesToPosts(
            recentBlogPosts.stories as ISbStoryData<BlogPostPageStoryblok>[],
            recentBlogPosts.rels as ISbStoryData<AuthorStoryblok>[],
            categories
          ).map((item) => (
            <PostCard key={item.url} {...item} />
          ))}
        </section>
      )}
    </section>
  )
}

export const RecentItemsSb = ({ blok }: RecentItemsStoryblok) => (
  <RecentItems {...blok} buttonLink={storyblokLink(blok.buttonLink)} />
)
