import clsx from 'clsx'

import { ForecastIcon } from './ForecastIcon'
import { useForecast } from '../../hooks'

interface Props {
  latitude?: number
  longitude?: number
  unit?: string
}

export const Forecast = ({ latitude, longitude, unit }: Props) => {
  const { data, loading, error } = useForecast(latitude, longitude, unit)

  if (error) {
    return null
  }

  return (
    <div className="pl-4 py-4 md:p-8 shrink-0">
      <ol className="flex pr-4 overflow-x-auto space-x-4">
        {loading ? (
          <li className="flex items-end">
            <div className="bg-grey-solid-7 h-8 w-8">&nbsp;</div>
            <div className="flex flex-col ml-1">
              <p className="bg-grey-solid-7 min-w-12 text-sm">&nbsp;</p>
              <p className="bg-grey-solid-7 min-w-20 text-dsm">&nbsp;</p>
            </div>
          </li>
        ) : data.map((item, index) => (
          <li key={item.day} className="flex items-end">
            <ForecastIcon icon={item.icon} />
            <div className="flex flex-col">
              <p className="text-sm">{item.day}</p>
              <p
                className={clsx('font-bold', {
                  'text-dsm': index === 0,
                  'pt-2 text-md': index !== 0,
                })}
              >
                {item.temperature}
              </p>
            </div>
            {index === 0 && (
              <div className="border-r border-grey-solid-0 h-6 -translate-y-1 translate-x-2" />
            )}
          </li>
        ))}
      </ol>
    </div>
  )
}
