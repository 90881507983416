import clsx from 'clsx'
import { Icon, IconProps } from '../../atoms'

export interface Props {
  className?: string
  rating: number | string | '1' | '2' | '3' | '4' | '5'
  size?: IconProps['size']
}

export const Rating = ({
  className = 'space-x-[4px]',
  rating,
  size = '20',
}: Props) => (
  <div className={clsx(className, 'flex items-center')}>
    {Array.from({ length: 5 }).map((_, index) => (
      <Icon
        key={`star-${index}`}
        className={
          index < parseInt(`${rating}`)
            ? 'text-primary-solid-2'
            : 'text-grey-solid-3/20'
        }
        icon="star"
        size={size}
      />
    ))}
  </div>
)
