import { ReactNode, useMemo } from 'react'

import { CategoriesContext } from './CategoriesContext'
import { DatasourceEntries } from '../../../types'

interface Props {
  children: ReactNode
  categories: DatasourceEntries['datasource_entries']
}

export const CategoriesProvider = ({ children, categories = [] }: Props) => {
  const value = useMemo(() => ({ categories }), [categories])

  return (
    <CategoriesContext.Provider value={value}>
      {children}
    </CategoriesContext.Provider>
  )
}
