export interface ListItemProps {
  image: {
    alt: string | null
    filename: string | null
  }
  text: string
}

export const ListItem = ({ image, text }: ListItemProps) => (
  <li className="flex items-center space-x-2">
    <span className="aspect-1/1 relative shrink-0 w-9">
      {image.filename && (
        <img
          alt={image.alt || text}
          className="absolute inset-0 object-cover h-full w-full"
          src={image.filename}
        />
      )}
    </span>
    <span className="text-grey-solid-2 text-sm md:text-lg">{text}</span>
  </li>
)
