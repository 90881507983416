import clsx from 'clsx'

interface Props {
  label: string
  onChange: (value: boolean) => void
  value: boolean
}

export const Switch = ({ label, onChange, value }: Props) => (
  <label className="cursor-pointer flex items-center space-x-2">
    <span className="font-semibold text-grey-solid-2 text-md">{label}</span>
    <button
      className={clsx('rounded p-[2px] w-8', {
        'bg-grey-solid-6': !value,
        'bg-primary-solid-2': value,
      })}
      onClick={() => onChange?.(!value)}
      type="button"
    >
      <span
        className={clsx(
          'flex flex-col h-4 transition-transform rounded-full min-w-4 w-4',
          {
            'bg-grey-solid-7 translate-x-0': !value,
            'bg-white-solid-1 translate-x-[16px]': value,
          }
        )}
      />
    </button>
  </label>
)
