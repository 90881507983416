import { formatRoundDate } from '../../helpers'

interface Props {
  startedAt: string
}

export const RoundDate = ({ startedAt }: Props) => (
  <span className="font-semibold text-grey-solid-4 text-xs text-right">
    {formatRoundDate(startedAt)}
  </span>
)
