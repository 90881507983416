import { useCallback, useEffect, useState } from 'react'
import { useApi } from './useApi'

import constants from '../assets/constants.json'
import CourseProfileHelpers from '../../../helpers/courseProfile'

interface State {
  data: {
    avatar: string
    score: number | string
    username: string
  }[]
  error: boolean
  loading: boolean
}

export const useLeaderboard = (coursePublicId?: string) => {
  const { pop } = useApi()

  const [state, setState] = useState<State>({
    data: [],
    error: false,
    loading: true,
  })

  const parseLeaderboard = useCallback(
    (gross: LeaderboardResponse['leaderboards']['gross']) =>
      gross.slice(0, 5).map((entry) => ({
        avatar: entry.user.avatar.small || constants.defaultAvatar,
        score: entry.score,
        username: entry.user.full_name,
      })),
    []
  )

  const fetchLeaderboard = useCallback(
    async (publicId: string) => {
      try {
        const res: LeaderboardResponse =
          await CourseProfileHelpers.fetchLeaderboard(pop, publicId)
        setState({
          data: parseLeaderboard(res.leaderboards.gross),
          loading: false,
          error: false,
        })
      } catch (err) {
        setState({ data: [], loading: false, error: true })
      }
    },
    [pop]
  )

  useEffect(() => {
    if (coursePublicId) {
      fetchLeaderboard(coursePublicId)
    }
  }, [fetchLeaderboard, coursePublicId])

  return state
}
