import { BreadcrumbsStoryblok } from '../../../../types/storyblok'
import { BreadcrumbsLdJson } from './Breadcrumbs.ldjson'
import {
  BreadcrumbItem,
  BreadcrumbItemProps,
} from '../BreadcrumbItem/BreadcrumbItem'
import { storyblokLink } from '../../helpers'

interface Props {
  items: BreadcrumbItemProps[]
  onClick?: () => void
}

export const Breadcrumbs = ({ items }: Props) => (
  <nav aria-label="breadcrumb">
    <ol className="flex flex-nowrap items-center py-[6px] space-x-[8px]">
      {items.map((item, index) => (
        <BreadcrumbItem
          key={index}
          index={index}
          length={items.length}
          {...item}
        />
      ))}
    </ol>
    <BreadcrumbsLdJson items={items} />
  </nav>
)

export const BreadcrumbsSb = ({ blok }: { blok: BreadcrumbsStoryblok }) => (
  <Breadcrumbs
    items={blok.items.map((nestedBlok) => ({
      current: Boolean(nestedBlok.current),
      text: nestedBlok.text,
      url: storyblokLink(nestedBlok.url),
    }))}
  />
)
