import { ReactNode } from 'react'
import { FeatureHeading } from '../../molecules'
import { FeaturesSectionStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  category: string
  children: ReactNode[]
  text: string
  title: string
}

export const FeaturesSection = ({ category, children, text, title }: Props) => (
  <section className="flex flex-col space-y-8 lg:space-y-16">
    <FeatureHeading category={category} text={text} title={title} />
    {children}
  </section>
)

export const FeaturesSectionSb = ({
  blok,
}: {
  blok: FeaturesSectionStoryblok
}) => (
  <FeaturesSection
    category={blok.category}
    children={blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
    text={blok.text}
    title={blok.title}
  />
)
