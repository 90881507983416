import { ReactNode } from 'react'
import { Grid } from '../../atoms'
import { FeaturesGridStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  children: ReactNode[]
}

export const FeaturesGrid = ({ children }: Props) => (
  <Grid children={children} smCols="12" mdCols="6" lgCols="4" />
)

export const FeaturesGridSb = ({ blok }: { blok: FeaturesGridStoryblok }) => (
  <FeaturesGrid
    children={blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  />
)
