import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  legend: string
  helperText?: string
}

export const FormField = ({ children, legend, helperText }: Props) => (
  <fieldset className="flex flex-col space-y-[8px]">
    <legend className="font-semibold pl-4 text-grey-solid-3 text-sm">
      {legend}
    </legend>
    {children}
    {helperText && (
      <small className="font-medium pl-4 text-grey-solid-3 text-xs">
        {helperText}
      </small>
    )}
  </fieldset>
)
