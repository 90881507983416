import { ShopItemStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'

interface Props {
  heading: string
  image: {
    alt: string
    filename: string
  }
  price: string
  title: string
  url: string
}

export const ShopItem = ({ heading, image, price, title, url }: Props) => (
  <article className="bg-primary-solid-7 p-4 max-w-[420px] mx-auto rounded w-full">
    <h3 className="font-semibold mb-2 text-primary-solid-2 text-sm">
      {heading}
    </h3>
    <a className="flex flex-col space-y-2 snap-start w-full" href={url}>
      <span className="aspect-4/3 border border-primary-solid-6 overflow-hidden relative rounded-md w-full">
        <img
          alt={image.alt}
          className="absolute object-cover h-full rounded-md w-full"
          src={image.filename}
        />
      </span>
      <span className="flex flex-col text-ellipsis text-sm">
        <h4 className="font-medium truncate">{title}</h4>
        <p className="font-bold text-primary-solid-2 truncate">{price}</p>
      </span>
    </a>
  </article>
)

export const ShopItemSb = ({ blok }: ShopItemStoryblok) => (
  <ShopItem {...blok} url={storyblokLink(blok.url)} />
)
