import { useCallback, useState } from 'react'

import { HandicapCard, HandicapForm } from '../../molecules'
import { HandicapCalculatorStoryblok } from '../../../../types'

type State = {
  courseName: string
  handicap: string
  holes: string
  par: string
  rating: string
  slope: string
  tees: string
}

interface Props {
  image: {
    alt: string | null
    filename: string | null
  }
  title: string
}

export const HandicapCalculator = ({ image, title }: Props) => {
  const [state, setState] = useState<State>()

  const handleFormSubmit = useCallback((values: State) => {
    setState(values)
  }, [])

  return (
    <section className="flex flex-col gap-y-6 md:flex-row md:justify-center md:gap-x-6">
      <div className={!state ? 'md:max-w-[50%] w-full' : 'flex-grow'}>
        <HandicapForm onSubmit={handleFormSubmit} />
      </div>
      {state && <HandicapCard image={image} title={title} {...state} />}
    </section>
  )
}

export const HandicapCalculatorSb = ({
  blok,
}: {
  blok: HandicapCalculatorStoryblok
}) => <HandicapCalculator {...blok} />
