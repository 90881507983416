import { MemberItemStoryblok } from "../../../../types"

export interface MemberItemProps {
  avatar: {
    alt: string
    filename: string
  }
  name: string
  position: string
  text: string
}

export const MemberItem = ({ avatar, name, position, text }: MemberItemProps) => (
  <article className="flex flex-col">
    <div className="aspect-1/1 mb-5 relative w-full">
      <img
        alt={avatar.alt}
        className="absolute inset-0 object-cover h-full rounded-xs w-full"
        src={avatar.filename}
      />
    </div>
    <h3 className="font-semibold line-clamp-1 mb-1 text-md md:text-xl">
      {name}
    </h3>
    <p className="line-clamp-1 mb-3 text-primary-solid-2 text-sm md:text-lg">
      {position}
    </p>
    <p className="line-clamp-2 text-grey-solid-4">{text}</p>
  </article>
)

export const MemberItemSb = ({ blok }: MemberItemStoryblok) => (
  <MemberItem {...blok} />
)
