import { format } from 'date-fns'
import { useState } from 'react'

import {
  Breadcrumbs,
  CourseCommunity,
  CourseDescription,
  CourseDetails,
  CourseHeading,
  CourseImageForecast,
  CourseLeaderboard,
  CourseReviews,
  CourseScorecard,
  Dropdown,
  Forecast,
  FormField,
} from '../molecules'

import { Content, Layout, Sidebar } from '../templates'
import { CoursesGrid, CTABanner, HandicapBanner } from '../organisms'
import { CourseIdentity } from '../../../types'

import i18n from '../assets/locales.json'
import { getRandomEntry, mapBreadcrumbs } from '../helpers'

interface Props {
  course_identity: CourseIdentity
  default_course_identity_images: string[]
  default_profile_image: string
  subtitle: string
}

export const CourseProfilePage = ({
  course_identity: {
    address,
    avg_rating,
    courses,
    description,
    email,
    images,
    latitude,
    location,
    longitude,
    name,
    nearby_course_identities,
    places_city,
    places_country,
    places_region,
    phone_number,
    region_slug,
    reviews,
    slug,
    temperature_unit,
  },
  default_course_identity_images = [],
  default_profile_image,
  subtitle,
}: Props) => {
  const [combination, setCombination] = useState(courses?.[0]?.public_id || '')
  const course = courses.find((c) => c.public_id === combination)

  return (
    <Layout>
      <Content className="pt-16">
        <Breadcrumbs
          items={mapBreadcrumbs(
            name,
            places_country,
            places_region,
            places_city
          )}
        />
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
        <CourseHeading
          location={location}
          message={i18n.course.headingMessage}
          name={name}
          rating={avg_rating}
          text={subtitle}
        />
        <CourseImageForecast
          image={{
            alt: name,
            filename:
              images[0]?.['resolutions']?.['big']
                || default_profile_image
                || i18n.placeholderImage,
          }}
        >
          <Forecast
            latitude={latitude}
            longitude={longitude}
            unit={temperature_unit}
          />
        </CourseImageForecast>
        <Sidebar
          main={[
            <CourseDescription
              key="description"
              text={description}
              title={i18n.global.description}
            />,
            <FormField key="combination" legend="Select Course Combination">
              <Dropdown
                options={courses.map((item) => ({
                  key: `${item.name} , ${item.holes_count} ${i18n.global.holes}`,
                  value: item.public_id,
                }))}
                onChange={(ev) => setCombination(`${ev.value}`)}
                size="xl"
                text={`${course?.name}, ${course?.holes_count} ${i18n.global.holes}`}
              />
            </FormField>,
            <CourseScorecard
              key="scorecard"
              data={(course?.active_version?.tees || []).map((item) => ({
                tee: item.name,
                distance: item.distance,
                par: item.par,
                cr: item.course_rating,
                sr: item.slope_rating,
              }))}
              title={i18n.global.scorecard}
            />,
            <HandicapBanner
              key="handicap"
              buttonText="Calculate My Handicap"
              image={{
                alt: 'Calculate My Handicap',
                filename:
                  'https://a-us.storyblok.com/f/1022273/295x202/62432c26cb/handicap-banner.png',
              }}
              title={`Know Your Handicap at ${name}`}
              text=""
              url={`/calculate-handicap?slug=${slug}`}
              variant="course"
            />,
            <CourseLeaderboard
              key="leaderboard"
              publicId={course?.public_id}
              title={i18n.course.leaderboard.title}
            />,
            <CourseReviews
              key="reviews"
              data={reviews.map((item) => ({
                avatar: item.user?.avatar_url || i18n.placeholderImage,
                date: format(item.created_at, 'MMMM dd, yyyy'),
                username: item.user?.full_name,
                rating: item.rating,
                text: item.description,
              }))}
              title={i18n.global.reviews}
            />,
          ]}
          side={[
            <CourseDetails
              key="details"
              address={address}
              email={email || undefined}
              phone={phone_number || undefined}
            />,
            <CTABanner
              key="cta-banner"
              title={i18n.ctaBanner.md.title}
              text={i18n.ctaBanner.md.text}
              variant="md"
            />,
            <CourseCommunity
              key="community"
              courseIdentitySlug={slug}
              title={i18n.course.communityPhotos}
            />,
          ]}
        />
        <CoursesGrid
          items={nearby_course_identities.map((item) => ({
            compact: true,
            href: item.profile_url,
            image: {
              alt: item.name,
              filename:
                item.images[0]?.url ||
                getRandomEntry(default_course_identity_images) ||
                i18n.placeholderImage,
            },
            location: item.location,
            rating: item.avg_rating ? item.avg_rating : undefined,
            title: item.name,
          }))}
          text={i18n.course.nearbyText}
          title={i18n.course.nearbyTitle}
        />
        <CTABanner
          title={i18n.ctaBanner.lg.title}
          text={i18n.ctaBanner.lg.text}
          variant="lg"
        />
      </Content>
    </Layout>
  )
}

export default CourseProfilePage
