import i18n from '../../assets/locales.json'

export const LeaderboardLegend = () => (
  <div className="font-bold hidden md:flex items-center space-x-2 text-xs uppercase">
    <div className="text-score-solid-eagle">{i18n.global.eagle}</div>
    <div className="text-score-solid-birdie">{i18n.global.birdie}</div>
    <div className="text-score-solid-par">{i18n.global.par}</div>
    <div className="text-score-solid-bogey">{i18n.global.bogey}</div>
    <div className="text-score-solid-2-bogey">{i18n.global['2bogey']}</div>
  </div>
)
