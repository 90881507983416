import { useCallback, useRef, useState } from 'react'

import CourseIdentitiesHelper from '../../../../helpers/courseIdentities'
import constants from '../../assets/constants.json'
import i18n from '../../assets/locales.json'

import {
  CourseIdentitiesResponse,
  PartialCourseIdentity,
} from '../../../../types'
import { QueryPanel } from '../QueryPanel/QueryPanel'
import { QueryResults } from '../QueryResults/QueryResults'
import { useApi } from '../../hooks'
import { getTotalPages, mapCourseIdentities } from '../../helpers'
import { Pagination } from '../../molecules'

type State = {
  count: number
  query: string
  orderBy: string
  page: number
  sortBy: string
}

interface Props {
  defaultCourseIdentityImages: string[]
  dropdownProps: {
    options: { key: string; value: string }[]
    prefix?: string
  }
  initData: PartialCourseIdentity[]
  initState: State
  inputProps: {
    placeholder: string
  }
  slug: string
  totalCount: number
}

export const Query = ({
  defaultCourseIdentityImages = [],
  dropdownProps,
  initData = [],
  initState,
  inputProps,
  slug,
  totalCount,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { pop } = useApi()

  const [data, setData] = useState(
    mapCourseIdentities(initData, defaultCourseIdentityImages)
  )
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<State>(initState)
  const [total, setTotal] = useState<number>(totalCount)

  const fetchData = useCallback(
    async (newState: State) => {
      setLoading(true)
      try {
        const res: CourseIdentitiesResponse =
          await CourseIdentitiesHelper.searchIdentities(
            pop,
            slug,
            newState.query,
            newState.page,
            newState.count,
            newState.orderBy,
            newState.sortBy
          )
        setData(
          mapCourseIdentities(
            res.course_identities,
            defaultCourseIdentityImages
          )
        )
        setLoading(false)
        setState(newState)
        setTotal(res.total_count)
      } catch (err) {
        throw err
      }
    },
    [pop, ref, slug]
  )

  const mergeState = useCallback(
    (prevState: State, key: string, val: string | number) => {
      if (key === 'orderBy') {
        const [orderBy, sortBy] = `${val}`.split(':')
        return { ...prevState, orderBy, sortBy }
      } else {
        return { ...prevState, [key]: val }
      }
    },
    []
  )

  const onQueryChange = useCallback(
    async (prevState: State, key: string, val: string | number) => {
      const nextState = mergeState(prevState, key, val)
      try {
        await fetchData(nextState)
        if (key === 'page' && ref.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: ref.current.offsetTop - (constants.navbarHeight + 3),
          })
        }
      } catch (err) {}
    },
    [fetchData, mergeState, ref]
  )

  return (
    <section ref={ref} className="flex flex-col space-y-4 md:space-y-6">
      <QueryPanel
        dropdownProps={dropdownProps}
        inputProps={inputProps}
        query={state.query}
        onChange={(key, value) => onQueryChange(state, key, value)}
        orderBy={`${state.orderBy}:${state.sortBy}`}
      />
      <QueryResults
        caption={
          Boolean(!loading && state.query)
            ? data.length
              ? `${i18n.locationCourses.search.results} ${total}`
              : i18n.locationCourses.search.noResults
            : undefined
        }
        items={data}
      />
      <Pagination
        current={state.page}
        onClick={(value) => onQueryChange(state, 'page', value)}
        total={getTotalPages(total, state.count)}
      />
    </section>
  )
}
