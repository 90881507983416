import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  cols: '3' | '4'
  gap?: '2' | '7'
}

export const CarouselGrid = ({ children, cols, gap = '7' }: Props) => (
  <section
    className={clsx('lg:ml-0 lg:w-full', {
      '-ml-6 w-[calc(100%+40px)]': gap === '2',
      '-ml-11 w-[calc(100%+110px)]': gap === '7',
    })}
  >
    <ol
      className={clsx(
        'flex lg:grid overflow-x-auto snap-x snap-mandatory relative w-full',
        {
          'gap-2': gap === '2',
          'gap-7': gap === '7',
          'lg:grid-cols-3': cols === '3',
          'lg:grid-cols-4': cols === '4',
        }
      )}
    >
      <li className="lg:hidden pl-4 snap-start shrink-0">
        <span className="block shrink-0 w-0"></span>
      </li>
      {children}
      <li className="lg:hidden pr-4 snap-start shrink-0">
        <span className="block shrink-0 w-0"></span>
      </li>
    </ol>
  </section>
)

export const CarouselGridItem = ({
  children,
  className = 'w-[260px]',
}: {
  children: ReactNode
  className?: string
}) => (
  <li className={clsx(className, 'h-full lg:w-full snap-start shrink-0')}>
    {children}
  </li>
)
