import { Tag } from '../Tag/Tag'
import i18n from '../../assets/locales.json'

export const PremiumTag = () => (
  <Tag
    color="primary"
    icon="star"
    iconClass="text-primary-solid-6"
    text={i18n.global.premium}
  />
)

export const FreeTag = () => <Tag color="white" text={i18n.global.free} />

export const RatingTag = ({ rating }: { rating?: number | string }) => (
  <Tag
    className="rounded-xs text-white-solid-1"
    color={rating ? "highlights-best-drive" : "grey"}
    icon="star"
    iconSize="18"
    px="px-[6px]"
    textClass="text-xs"
    text={rating ? `${rating}` : '-'}
  />
)
