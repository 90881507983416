import { ReactNode } from 'react'
import clsx from 'clsx'

import { Icon } from '../../atoms/Icon'
import Icons from '../../atoms/Icons'

interface Props {
  className?: string
  children: ReactNode
  icon?: keyof typeof Icons
  iconClass?: string
  href: string
  onClick?: () => void
  textClass?: string
}

export const Link = ({
  className = '',
  children,
  icon,
  iconClass = '',
  href,
  onClick,
  textClass = 'font-semibold text-grey-solid-2 text-sm lg:text-md',
}: Props) => (
  <a
    className={clsx(className, 'inline-flex items-center gap-x-2')}
    href={href}
    onClick={onClick}
  >
    <span className={textClass}>{children}</span>{' '}
    {icon && <Icon className={iconClass} icon={icon} />}
  </a>
)
