import { ReviewStoryblok } from '../../../../types/storyblok'
import { Rating, Props as RatingProps } from '../Rating/Rating'

interface Props {
  author: string
  caption: string
  quote: string
  rating: RatingProps['rating']
}

export const Review = ({ author, caption, quote, rating }: Props) => (
  <div className="flex flex-col items-center lg:items-start py-8 space-y-8 text-center lg:text-left">
    <div className="flex flex-col items-center lg:items-start space-y-3">
      <Rating rating={rating} />
      <blockquote>
        <p className="font-medium text-grey-solid-2 text-md lg:text-xl">
          {quote}
        </p>
      </blockquote>
    </div>
    <div className="flex flex-col space-y-1">
      <p className="font-medium text-sm">&mdash; {author}</p>
      <small className="text-xs text-grey-solid-2">{caption}</small>
    </div>
  </div>
)

export const ReviewSb = ({ blok }: { blok: ReviewStoryblok }) => (
  <Review {...blok} />
)
