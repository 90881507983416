import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { MultilinkStoryblok } from '../../../types'
import { format, formatDistanceToNow, isValid } from 'date-fns'

export const toSlug = (value: string): string => {
  return value
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

export const slugToLabel = (slug: string): string => {
  return slug
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

export const getPageUrl = (url = '', prefix: string, page: number) => {
  const params = new URLSearchParams(url)
  params.set('page', `${page}`)
  return { key: `${page}`, url: `/${prefix}?${params}` }
}

export const removePunctuation = (input = '') => {
  return input.replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, '')
}

export const stringToRichText = (text: string): StoryblokRichtext => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          text,
          type: 'text',
        },
      ],
    },
  ],
})

export const storyblokLink = (link?: MultilinkStoryblok) => {
  switch (link?.linktype) {
    case 'email':
      return `mailto:${link.cached_url}`
    case 'story':
      return `/${link?.cached_url || ''}`
    default:
      return link?.cached_url || ''
  }
}

export const formatRoundDate = (date: string) => {
  if (!isValid(new Date(date))) {
    return date
  }
  return format(date, 'dd/MM/yyyy')
}
