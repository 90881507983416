import clsx from 'clsx'
import React, { useCallback, useRef, useState } from 'react'

import { data } from './Nav.helpers'
import { Icon, NavButton } from '../../atoms'
import { NavAuthentication } from './NavAuthentication'
import { NavLogo } from './NavLogo'
import { useClickOutside } from '../../hooks'

interface Props {
  color?: string
  className?: string
}

export const NavMobile = ({
  color = 'bg-white-solid-1',
  className = '',
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null)

  const [openMenu, setOpenMenu] = useState(false)
  const [openApps, setOpenApps] = useState(false)
  const [app, setApp] = useState<'hole19' | 'core'>()
  const [features, setFeatures] = useState<'hole19' | 'core'>()

  const reset = useCallback(() => {
    setOpenApps(false)
    setOpenMenu(false)
    setApp(undefined)
    setFeatures(undefined)
  }, [])

  const handleApp = useCallback((key: 'hole19' | 'core') => {
    setApp((prev) => (prev === key ? undefined : key))
    setFeatures(undefined)
  }, [])

  useClickOutside(divRef, reset)

  return (
    <div
      className={clsx(
        openMenu ? 'bg-white-solid-1 shadow-nav' : color,
        className,
        'backdrop-blur-md ease-in-out duration-100 transition-colors w-full'
      )}
      ref={divRef}
      role="banner"
    >
      <nav className="flex flex-col" role="navigation">
        <div className="flex justify-between items-start px-4">
          <NavLogo />
          <button
            className="pl-4 py-5"
            onClick={() => (openMenu ? reset() : setOpenMenu(true))}
            type="button"
          >
            <Icon
              className="text-primary-solid-2"
              icon={openMenu ? 'close' : 'menu'}
              size="lg"
            />
          </button>
        </div>
        {openMenu && (
          <div className="flex flex-col divide-y divide-separator-solid-1 overflow-y-auto max-h-[554px] h-full">
            <div className="flex flex-col items-start pb-4 space-y-1 px-4">
              <NavButton
                active={openApps}
                fontWeight="semibold"
                handleClick={() => setOpenApps((prev) => !prev)}
                icon="chevron_down"
                text={data.main.dropdown}
              />
              {openApps && (
                <div className="flex flex-col items-start space-y-1">
                  {[data.hole19, data.core].map((item) => (
                    <React.Fragment key={`${item.key}-${item.featuresText}`}>
                      <NavButton
                        active={app === item.key}
                        handleClick={() => handleApp(item.key)}
                        icon={
                          app === item.key ? 'chevron_down' : 'chevron_right'
                        }
                        text={item.appText}
                      />
                      {app === item.key && (
                        <div className="flex flex-col items-start pl-4 space-y-1">
                          <NavButton
                            active={Boolean(features)}
                            handleClick={() =>
                              setFeatures((prev) =>
                                prev === item.key ? undefined : item.key
                              )
                            }
                            icon={
                              Boolean(features)
                                ? 'chevron_down'
                                : 'chevron_right'
                            }
                            text={item.featuresText}
                          />
                          {features === item.key && (
                            <div className="flex flex-col items-start pl-4 space-y-1">
                              {item.features.map((link) => (
                                <NavButton
                                  key={link.text}
                                  href={link.href}
                                  text={link.text}
                                />
                              ))}
                            </div>
                          )}
                          {item.platforms.map((link) => (
                            <NavButton
                              key={link.text}
                              href={link.href}
                              target={link.target}
                              text={link.text}
                            />
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
              {data.main.links.map((link) => (
                <NavButton
                  key={link.text}
                  fontWeight="semibold"
                  href={link.href}
                  target={link.target}
                  text={link.text}
                />
              ))}
            </div>
            <NavAuthentication />
          </div>
        )}
      </nav>
    </div>
  )
}
