import { ISbStoryData } from '@storyblok/react'

import {
  AuthorStoryblok,
  BlogPostPageStoryblok,
  DatasourceEntries,
} from '../../../types'

import { formatDate } from './dates'
import { PostCardProps } from '../molecules'

export const mapStoriesToPosts = (
  stories: ISbStoryData<BlogPostPageStoryblok>[],
  rels: ISbStoryData<AuthorStoryblok>[],
  categories: DatasourceEntries['datasource_entries'] = []
): PostCardProps[] =>
  stories.map((story) => {
    const [author] = rels
      .filter((rel) => story.content.author.includes(rel.uuid))
      .map((author) => ({
        ...author.content,
        text: formatDate(story.content.publishDate),
      }))

    return {
      author,
      category: story.content.categories.map(
        (category) =>
          (categories || []).find((c) => c.value === category)?.name || ''
      ),
      image: story.content.seoImage,
      text: story.content.subtitle,
      title: story.content.title,
      url: story.full_slug,
    }
  })
