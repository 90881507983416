import { NavDesktop, NavMobile } from '../../molecules'

interface Props {
  color?: string
  className?: string
}

export const Navbar = ({
  color = 'bg-white-solid-1/85',
  className = '',
}: Props) => (
  <>
    <div className="hidden lg:block">
      <NavDesktop className={className} color={color} />
    </div>
    <div className="block lg:hidden">
      <NavMobile className={className} color={color} />
    </div>
  </>
)
