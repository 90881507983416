import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import { PlatformPageStoryblok } from '../../../types/storyblok'
import { Content, Header, Hero, Layout } from '../templates'

export const PlatformPage = ({ blok }: { blok: PlatformPageStoryblok }) => {
  return (
    <Layout navColor="bg-separator-solid-0/85" {...storyblokEditable(blok)}>
      <Header className="bg-separator-solid-0">
        <Hero content={blok.head} />
      </Header>
      <Content>
        {blok.body.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </Content>
    </Layout>
  )
}

export default PlatformPage
