import { useState } from 'react'

import androidSvg from '../assets/android.svg'
import iosSvg from '../assets/ios.svg'
import iosQR from '../assets/ios-qrcode.svg'
import COREiosQR from '../assets/CORE-ios-qrcode.svg'
import i18n from '../assets/locales.json'

const apps = {
  'hole19': {
    android: {
      alt: i18n.global.androidButton,
      qrCode: iosQR,
      svg: androidSvg,
      url: 'https://hole19.smart.link/zgokqd353',
    },
    ios: {
      alt: i18n.global.iosButton,
      qrCode: iosQR,
      svg: iosSvg,
      url: 'https://hole19.smart.link/7th5qzcmg',
    },
  },
  'CORE': {
    android: null,
    ios: { 
      alt: i18n.global.iosButton,
      qrCode: COREiosQR,
      svg: iosSvg,
      url: 'https://apps.apple.com/app/apple-store/id1480373673?pt=602915&ct=H19Website&mt=8',
    }
  }
}

interface Props {
  app: keyof typeof apps
  onClick: () => void
  variant: 'android' | 'ios'
}

export const PlatformButton = ({ app, onClick, variant }: Props) => {
  const [showCode, setShowCode] = useState(false)

  const platform = apps[app]?.[variant]
  if (!platform) {
    return null
  }

  return (
    <a
      aria-label={platform.alt}
      className={`max-w-[200px] relative w-full`}
      href={platform.url}
      onClick={onClick}
      onMouseEnter={() => setShowCode(true)}
      onMouseLeave={() => setShowCode(false)}
      target="_blank"
    >
      <img
        alt={platform.alt}
        className="w-full"
        src={platform.svg}
      />
      {Boolean(platform.qrCode && showCode) && (
        <div className="absolute bg-white-solid-1 flex flex-col items-center mt-1 top-full shadow-platform rounded-sm w-full">
          <div className="absolute -mt-3 overflow-hidden w-4">
            <div className="rounded-[2px] h-3 w-3 bg-white-solid-1 rotate-45 transform origin-bottom-left" />
          </div>
          <div className="flex flex-col items-center gap-y-3 p-4">
            <img
              alt={platform.alt}
              className="max-w-16 w-full"
              src={platform.qrCode}
            />
            <p className="font-bold text-grey-solid-3 text-center text-sm">
              {i18n.global.scan}
            </p>
          </div>
        </div>
      )}
    </a>
  )
}
