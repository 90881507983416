import { AdvertiseSectionHeadingStoryblok } from '../../../../types'

interface Props {
  title: string
}

export const AdvertiseSectionHeading = ({ title }: Props) => (
  <section className="flex flex-col items-center text-center w-full">
    <h2 className="font-semibold text-dxs md:text-dmd">{title}</h2>
  </section>
)

export const AdvertiseSectionHeadingSb = ({
  blok,
}: AdvertiseSectionHeadingStoryblok) => <AdvertiseSectionHeading {...blok} />
