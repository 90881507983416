import { PageCard, PageCardProps } from '../../molecules'
import { CarouselGrid, CarouselGridItem } from '../CarouselGrid/CarouselGrid'

interface Props {
  items: PageCardProps[]
  title: string
}

export const PagesGrid = ({ items, title }: Props) => (
  <section className="flex flex-col space-y-5">
    <h2 className="font-bold text-xl">{title}</h2>
    <CarouselGrid cols="3" gap="2">
      {items.map((item, index) => (
        <CarouselGridItem key={index} className="w-[390px]">
          <PageCard {...item} />
        </CarouselGridItem>
      ))}
    </CarouselGrid>
  </section>
)
