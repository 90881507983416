import { useCallback, useMemo, useState } from 'react'

import i18n from '../assets/locales.json'

type Key = 'mode' | 'type'
type Value = string | 'stableford' | 'stroke_play' | 'net' | 'gross'

export interface ScoreMode {
  handleChange: (key: Key, value: Value) => void
  modeKey: string
  modeValue: string
  modeOpts: { key: string; value: string }[]
  typeKey: string
  typeValue: string
  typeOpts: { key: string; value: string }[]
  value: string
}

const modeOpts = [
  { key: i18n.global.stableford, value: 'stableford' },
  { key: i18n.global.strokePlay, value: 'stroke_play' },
]

const typeOpts = [
  { key: i18n.global.net, value: 'net' },
  { key: i18n.global.gross, value: 'gross' },
]

export const useScoreMode = (defaultValue: ScoringMode): ScoreMode => {
  const [state, setState] = useState({
    mode: defaultValue.includes('stableford') ? 'stableford' : 'stroke_play',
    type: defaultValue.includes('gross') ? 'gross' : 'net',
  })

  const handleChange = useCallback((key: Key, value: Value) => {
    if (key === 'mode') {
      setState({
        mode: value,
        type: value === 'stroke_play' ? 'gross' : 'net',
      })
    } else {
      setState((prev) => ({ ...prev, type: value }))
    }
  }, [])

  return {
    handleChange,
    modeKey: (modeOpts.find((o) => o.value === state.mode) || modeOpts[0]).key,
    modeValue: state.mode,
    modeOpts: useMemo(() => modeOpts, []),
    typeKey: (typeOpts.find((o) => o.value === state.type) || typeOpts[0]).key,
    typeValue: state.type,
    typeOpts: useMemo(() => typeOpts, []),
    value: `${state.mode}_${state.type}`,
  }
}
