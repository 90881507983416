import { Icon } from '../../atoms'

interface Props {
  isOpen: boolean
  score: number
  thru: string
  topar: string
}

export const RoundScore = ({ isOpen, score, thru, topar }: Props) => (
  <div className="flex font-semibold items-center shrink-0 text-grey-solid-1 text-xl space-x-2 md:space-x-4">
    <div>{score}</div>
    <div>{topar}</div>
    <div className="flex items-center">
      {thru}
      <div className="flex items-center justify-center h-5 w-5">
        <Icon
          className="text-grey-solid-4"
          icon={isOpen ? 'chevron_up' : 'chevron_down'}
          size="sm"
        />
      </div>
    </div>
  </div>
)
