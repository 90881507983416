import { ISbComponentType } from 'storyblok-js-client'

import { GlossaryText } from '../GlossaryText/GlossaryText'
import { RatingTag } from '../Tags/Tags'

interface Props {
  location: string
  message?: string
  name: string
  rating: string | number
  text: string
}

export const CourseHeading = ({
  location,
  message,
  name,
  rating,
  text,
}: Props) => (
  <section className="flex flex-col items-start">
    <RatingTag rating={rating} />
    <h1 className="font-semibold mt-4 text-dmd lg:text-dxl">{name}</h1>
    <p className="font-semibold mb-5 text-grey-solid-2 text-md lg:text-lg">
      {location}
    </p>
    {message && (
      <div className="bg-primary-solid-7 font-semibold mb-2 p-2 text-xs md:text-sm rounded-xs text-primary-solid-3">
        {message}
      </div>
    )}
    <GlossaryText
      className="text-grey-solid-2 text-md lg:text-xl"
      text={text}
    />
  </section>
)

export type CourseHeadingStoryblok = ISbComponentType<'CourseHeading'> & Props

export const CourseHeadingSb = ({ blok }: { blok: CourseHeadingStoryblok }) => (
  <CourseHeading {...blok} />
)
