import { ISbComponentType } from 'storyblok-js-client'

import { Label } from '../Label/Label'
import { Rating, Props as RatingProps } from '../Rating/Rating'

interface Props {
  data: {
    avatar: string
    date: string
    username: string
    rating: number | string | RatingProps['rating']
    text: string
  }[]
  title: string
}

export const CourseReviews = ({ data, title }: Props) => data.length ? (
  <Label text={title}>
    <ul className="border border-grey-solid-6 px-4 md:px-5 py-4 md:py-5 rounded space-y-4">
      {data.map((item) => (
        <li key={item.username} className="flex items-start space-x-3 w-full">
          <div className="aspect-1/1 relative shrink-0 w-8">
            <img
              alt={item.username}
              className="absolute inset-0 object-cover rounded-xs"
              src={item.avatar}
            />
          </div>
          <div className="flex flex-col flex-grow min-w-0 space-y-3">
            <div className="flex items-center justify-between space-x-2 md:space-x-4">
              <div className="flex flex-col truncate">
                <div className="font-medium text-md truncate">
                  {item.username}
                </div>
                <div className="text-grey-solid-3 text-sm truncate">
                  {item.date}
                </div>
              </div>
              <div className="shrink-0">
                <Rating className="space-x-0" rating={item.rating} size="sm" />
              </div>
            </div>
            <p className="pb-4 text-md">{item.text}</p>
          </div>
        </li>
      ))}
    </ul>
  </Label>
) : null

export type CourseReviewsStoryblok = ISbComponentType<'CourseReviews'> & Props

export const CourseReviewsSb = ({ blok }: { blok: CourseReviewsStoryblok }) => (
  <CourseReviews {...blok} />
)
