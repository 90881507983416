import { useCallback, useMemo, useState } from 'react'

import DiscoverCoursesHelper from '../../../helpers/discoverCourses'
import i18n from '../assets/locales.json'
import constants from '../assets/constants.json'

import { Content, Layout } from '../templates'
import { CoursesLinks, CoursesSection, CTABanner, ImageBannerGrid } from '../organisms'
import { Heading } from '../molecules'
import { LocationsResponse } from '../../../types'
import { useApi } from '../hooks/useApi'
import { getRandomEntry } from '../helpers'

type Suggestion = {
  key: string;
  icon: string;
  value: string;
}

type State = {
  query: string
  response: LocationsResponse | null
  suggestions: Suggestion[]
}

interface Props {
  default_course_identity_images: string[]
  top_played_worldwide: {
    avg_rating: number
    country_code: string
    images: {
      id: number
      url: string
    }[]
    latitude: number
    location: string
    longitude: number
    name: string
    public_id: string
    profile_url: string
    slug: string
    region_slug: string
  }[]
  world_markets: {
    countries: {
      courses_count: number
      name: string
      slug: string
    }[]
    name: string
  }[]
}

export const DiscoverCoursesPage = ({
  default_course_identity_images,
  top_played_worldwide,
  world_markets,
}: Props) => {
  const { pop } = useApi()

  const [state, setState] = useState<State>({
    query: '',
    response: null,
    suggestions: [],
  })

  const handleQuery = useCallback(
    async (query: string) => {
      setState({ query, response: null, suggestions: [] })
      if (!query) return
      try {
        const r: LocationsResponse = await DiscoverCoursesHelper.fetchLocation(
          pop,
          query,
          5
        )
        const suggestions = [
          ...r.course_identities.map((item) => ({
            key: item.location ? `${item.name}, ${item.location}` : item.name,
            icon: 'course',
            value: item.slug,
          })),
          ...r.places.map((item) => ({
            key: item.name,
            icon: 'location',
            value: item.slug,
          })),
        ]
        setState({ query, response: r, suggestions })
      } catch (err) {
        console.log(err)
      }
    },
    [pop]
  )

  const handleSelect = useCallback((opt: Suggestion) => {
    if (opt.icon === 'location') {
      window.location.href = `${constants.routes.places}/${opt.value}`
      return
    }
    window.location.href = `${constants.routes.courses}/${opt.value}`
  }, [])

  const mostPlayed = useMemo(
    () =>
      top_played_worldwide.map((course) => ({
        href: `${constants.routes.courses}/${course.slug}`,
        image: {
          alt: course.name,
          filename:
            course.images[0]?.url ||
            getRandomEntry(default_course_identity_images) ||
            i18n.placeholderImage,
        },
        location: course.location,
        rating: course.avg_rating,
        title: course.name,
      })),
    [top_played_worldwide]
  )

  const byRegion = useMemo(
    () =>
      world_markets.map((market) => ({
        items: market.countries.map((country) => ({
          text: `${country.name} (${country.courses_count})`,
          url: `${constants.routes.courses}/countries/${country.slug}`,
        })),
        title: market.name,
      })),
    [world_markets]
  )

  return (
    <Layout>
      <Content padding="py-20">
        <Heading
          searchProps={{
            helpText: i18n.courses.helpText,
            name: 'query',
            onChange: handleQuery,
            onSelect: handleSelect,
            placeholder: i18n.courses.placeholder,
            suggestions: state.suggestions,
          }}
          text={i18n.courses.text}
          title={i18n.courses.title}
        />
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
        <CoursesSection
          items={mostPlayed}
          title={i18n.courses.mostPlayed.title}
          variant="grid"
        />
        <ImageBannerGrid />
        <CoursesLinks
          items={byRegion}
          title={i18n.courses.regions.title}
          text={i18n.courses.regions.text}
        />
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
      </Content>
    </Layout>
  )
}

export default DiscoverCoursesPage
