import { createRef, useCallback, useEffect, useState } from 'react'

import { Button, InputField, Search, SearchRef } from '../../atoms'
import { CourseIdentitiesResponse, CourseIdentity } from '../../../../types'
import { Dropdown, FormField } from '..'
import { useApi } from '../../hooks'

import CourseIdentitiesHelper from '../../../../helpers/courseIdentities'
import i18n from '../../assets/locales.json'
import { calculateCourseHandicap } from '../../helpers'

type Option = {
  key: string
  value: string
}

type State = {
  query: string
  results: CourseIdentitiesResponse | null
  suggestions: Option[]
}

interface Props {
  onSubmit: (state: {
    courseName: string
    handicap: string
    holes: string
    par: string
    rating: string
    slope: string
    tees: string
  }) => void
}

export const HandicapForm = ({ onSubmit }: Props) => {
  const searchRef = createRef<SearchRef>()
  const { pop } = useApi()

  const [course, setCourse] = useState<CourseIdentity>()
  const [combination, setCombination] = useState<Option>()
  const [index, setIndex] = useState(0)
  const [publicId, setPublicId] = useState('')
  const [tee, setTee] = useState<Option>()

  const [state, setState] = useState<State>({
    query: '',
    results: null,
    suggestions: [],
  })

  const handleSearch = useCallback(
    async (query: string) => {
      if (query === '') {
        setPublicId('')
        setCourse(undefined)
        setCombination(undefined)
        setTee(undefined)
        return
      }
      setState({ query, results: null, suggestions: [] })
      try {
        const res: CourseIdentitiesResponse =
          await CourseIdentitiesHelper.fetchIdentities(pop, query)
        setState({
          query,
          results: res,
          suggestions: res.course_identities.map((identity) => ({
            key: identity.name,
            value: identity.public_id,
          })),
        })
      } catch (err) {
        console.log(err)
      }
    },
    [pop]
  )

  const handleSelect = useCallback((option: Option) => {
    setPublicId(option.value)
    setCombination(undefined)
    setTee(undefined)
  }, [])

  const fetchCourseIdentity = useCallback(
    async (slug: string) => {
      try {
        const res: CourseIdentity = await CourseIdentitiesHelper.fetchIdentity(
          pop,
          slug
        )
        setCourse(res)
        if (res.courses.length > 0) {
          const [course] = res.courses
          setCombination({
            key: `${course.name} , ${course.holes_count} ${i18n.global.holes}`,
            value: course.public_id,
          })
        }
        return res
      } catch (err) {
        console.log(err)
      }
    },
    [pop]
  )

  const handleSubmit = useCallback(() => {
    if (!course || !combination || !tee || !index) {
      return
    }
    const cid = course.courses.find((c) => c.public_id === combination.value)
    if (!cid) return

    const tid = cid.active_version.tees.find(
      (t) => t.id === parseInt(tee.value)
    )
    if (!tid) return

    const handicap = calculateCourseHandicap(
      cid.holes_count,
      tid.course_rating,
      tid.slope_rating,
      tid.par,
      index,
      course.country_code
    )

    onSubmit({
      courseName: course.name,
      handicap: `${handicap}`,
      holes: `${cid.holes_count}`,
      par: `${tid.par}`,
      rating: `${tid.course_rating}`,
      slope: `${tid.slope_rating}`,
      tees: `${tid.name} (${tid.distance}m)`,
    })
  }, [course, combination, tee, index])

  useEffect(() => {
    if (!publicId || !state.results) return
    const id = state.results.course_identities.find(
      (identity) => identity.public_id === publicId
    )
    if (!id) return
    fetchCourseIdentity(id.slug)
  }, [fetchCourseIdentity, publicId, state.results])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const slug = params.get('slug')
    if (slug) {
      fetchCourseIdentity(slug).then((res) => {
        if (searchRef.current && res?.name) {
          searchRef.current.setValue(res.name)
        }
      })
    }
  }, [])

  return (
    <form className="flex flex-col flex-grow space-y-4">
      <FormField
        legend={i18n.handicap.course.legend}
        helperText={i18n.handicap.course.helperText}
      >
        <Search
          ref={searchRef}
          name="course"
          onChange={handleSearch}
          onSelect={handleSelect}
          placeholder={i18n.handicap.course.placeholder}
          suggestions={state.suggestions}
        />
      </FormField>
      <div className="border-t border-separator-solid-1 mx-4" />
      <FormField legend={i18n.handicap.combination.legend}>
        <Dropdown
          disabled={!course || !course.courses.length}
          options={(course?.courses || []).map((item) => ({
            key: `${item.name} , ${item.holes_count} ${i18n.global.holes}`,
            value: item.public_id,
          }))}
          onChange={({ key, value }) =>
            setCombination({ key: `${key}`, value: `${value}` })
          }
          text={
            combination
              ? `${combination.key}`
              : i18n.handicap.combination.placeholder
          }
        />
      </FormField>
      <FormField legend={i18n.handicap.tees.legend}>
        <Dropdown
          disabled={!combination}
          onChange={({ key, value }) =>
            setTee({ key: `${key}`, value: `${value}` })
          }
          options={(
            course?.courses.find((c) => c.public_id === combination?.value)
              ?.active_version.tees || []
          ).map((t) => ({
            key: t.name,
            tee: t.rgb,
            value: t.id,
          }))}
          text={tee ? `${tee.key}` : i18n.handicap.tees.placeholder}
        />
      </FormField>
      <FormField legend={i18n.handicap.index.legend}>
        <InputField
          name="index"
          onChange={(ev) => setIndex(parseInt(ev.target.value))}
          placeholder={i18n.handicap.index.placeholder}
        />
      </FormField>
      <div className="flex flex-col items-start">
        <Button
          color="primary-solid-2"
          disabled={!course || !combination || !tee || !index}
          onClick={handleSubmit}
          size="2xl"
          text={i18n.handicap.form.submit}
        />
      </div>
    </form>
  )
}
