import { ISbComponentType } from 'storyblok-js-client'

import { CarouselGrid, CarouselGridItem } from '..'
import { CourseCard, CourseCardProps, GlossaryText } from '../../molecules'

interface Props {
  items: CourseCardProps[]
  text: string
  title: string
}

export const CoursesGrid = ({ items, text, title }: Props) => (
  <section className="flex flex-col">
    <div className="flex flex-col mb-4 py-2 space-y-1 md:mb-8 md:py-4 md:space-y-2 lg:w-2/3">
      <h2 className="font-semibold text-dsm md:text-dlg">{title}</h2>
      <GlossaryText
        className="text-grey-solid-3 text-md md:text-lg"
        text={text}
      />
    </div>
    <CarouselGrid cols="3" gap="2">
      {items.map((item, index) => (
        <CarouselGridItem key={index} className="w-[350px]">
          <CourseCard {...item} compact={false} size="sm" />
        </CarouselGridItem>
      ))}
    </CarouselGrid>
  </section>
)

export type CoursesGridStoryblok = ISbComponentType<'CoursesGrid'> & Props

export const CoursesGridSb = ({ blok }: { blok: CoursesGridStoryblok }) => (
  <CoursesGrid {...blok} />
)
