import { Label } from '../Label/Label'
import { useLeaderboard } from '../../hooks'

import i18n from '../../assets/locales.json'

interface Props {
  publicId?: string
  title: string
}

export const CourseLeaderboard = ({ publicId, title }: Props) => {
  const { data, loading, error } = useLeaderboard(publicId)

  if (loading || error || data.length === 0) {
    return (
      <Label text={title}>
        <p>
          {loading ? i18n.global.loading : i18n.course.leaderboard.unavailable}
        </p>
      </Label>
    )
  }

  return (
    <Label text={title}>
      <ul className="border border-grey-solid-6 px-4 md:px-5 py-4 rounded space-y-4">
        {data.map((item, index) => (
          <li
            key={item.username}
            className="flex items-center justify-between py-2 space-x-2 md:space-x-4"
          >
            <div className="flex items-center space-x-2 truncate">
              <div className="aspect-1/1 bg-primary-solid-2 flex items-center justify-center rounded-full shrink-0 w-[24px]">
                <span className="font-semibold text-white-solid-1 text-sm">
                  {index + 1}
                </span>
              </div>
              <div className="aspect-1/1 bg-grey-solid-7 relative shrink-0 w-8">
                <img
                  alt={item.username}
                  className="absolute inset-0 object-cover rounded-xs"
                  src={item.avatar}
                />
              </div>
              <div className="font-medium truncate text-md">
                {item.username}
              </div>
            </div>
            <div className="font-bold text-primary-solid-2 text-md shrink-0">
              {item.score}
            </div>
          </li>
        ))}
      </ul>
    </Label>
  )
}
