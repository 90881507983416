import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { Content, Header, Layout } from '../templates'
import { CompanyPageStoryblok } from '../../../types'

export const CompanyPage = ({ blok }: { blok: CompanyPageStoryblok }) => (
  <Layout>
    <Header padding="p-0">
      {blok.head?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Header>
    <Content>
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
      {blok.footer?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
