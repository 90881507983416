export interface LinkGridProps {
  items: { text: string; url: string }[]
  title?: string
}

export const LinkGrid = ({ items, title }: LinkGridProps) => (
  <section className="flex flex-col space-y-4 lg:space-y-6">
    {title && (
      <h3 className="font-semibold px-6 lg:px-12 text-xl lg:text-dsm">
        {title}
      </h3>
    )}
    <div className="block bg-grey-alpha-6 columns-3 gap-x-3 space-y-2 py-5 px-6 lg:py-10 lg:px-12 rounded-md">
      {items.map((link, index) => (
        <div key={`${link.url}-${index}`} className="flex break-words">
          <a
            className="break-inside-avoid hover:text-grey-solid-2/80 text-grey-solid-2 text-sm"
            href={link.url}
          >
            {link.text}
          </a>
        </div>
      ))}
    </div>
  </section>
)
