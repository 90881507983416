import { ReactNode } from 'react'
import { CarouselGrid, CarouselGridItem } from '../CarouselGrid/CarouselGrid'
import { FeatureStepsStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  children: ReactNode[]
}

export const FeatureSteps = ({ children }: Props) => (
  <CarouselGrid cols="4">
    {children.map((item, index) => (
      <CarouselGridItem key={index}>{item}</CarouselGridItem>
    ))}
  </CarouselGrid>
)

export const FeatureStepsSb = ({ blok }: { blok: FeatureStepsStoryblok }) => (
  <CarouselGrid cols="4">
    {blok.items.map((nestedBlok) => (
      <CarouselGridItem key={nestedBlok._uid}>
        <StoryblokServerComponent blok={nestedBlok} />
      </CarouselGridItem>
    ))}
  </CarouselGrid>
)
