import clsx from 'clsx'
import { Logo } from '../../atoms/Logo'

interface Props {
  app: 'hole19' | 'CORE'
  className?: string
  showLogo: boolean
  text: string
  title: string
  titleClass: string
}

export const BannerText = ({
  app,
  className,
  showLogo,
  text,
  title,
  titleClass,
}: Props) => (
  <div
    className={clsx(
      className,
      'flex flex-col items-center lg:items-start lg:flex-row gap-x-4'
    )}
  >
    {showLogo && <Logo app={app} className="flex-shrink-0 mt-2 mb-4" variant="symbol" />}
    <div className="flex flex-col gap-y-2 text-center lg:text-left">
      <h3 className={clsx(titleClass, 'font-semibold text-grey-solid-0')}>
        {title}
      </h3>
      <p className="text-sm lg:text-md text-grey-solid-2">{text}</p>
    </div>
  </div>
)
