import {
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { debounce } from 'throttle-debounce'

import { Icon, IconProps } from '../Icon'
import { InputField, InputFieldProps } from '../InputField/InputField'
import { useClickOutside } from '../../hooks'

type Option = {
  key: string
  icon?: IconProps['icon']
  value: string
}

export type SearchRef = {
  setValue: (v: string) => void
}

export interface SearchProps {
  defaultValue?: string
  delay?: number
  name: string
  onChange?: (val: string) => void
  onSelect?: (option: Option) => void
  placeholder: string
  size?: InputFieldProps['size']
  suggestions?: Option[]
}

export const Search = forwardRef<SearchRef, SearchProps>(
  (
    {
      defaultValue = '',
      delay = 200,
      name,
      onChange,
      onSelect,
      placeholder,
      size = '2xl',
      suggestions = [],
    },
    ref
  ) => {
    const inputRef = createRef<HTMLInputElement>()
    const divRef = useRef<HTMLDivElement>(null)

    const [inputFocus, setInputFocus] = useState(false)
    const [value, setValue] = useState(defaultValue)

    useImperativeHandle(
      ref,
      () => ({
        setValue: (val: string) => setValue(val),
      }),
      []
    )

    const debounceUpdates = useMemo(
      () =>
        debounce(delay, (query: string) => {
          onChange?.(query)
        }),
      [delay, onChange]
    )

    const handleChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value
        setValue(val)
        debounceUpdates(val)
      },
      [debounceUpdates]
    )

    const handleClear = useCallback(() => {
      setValue('')
      onChange?.('')
    }, [onChange])

    const handleSelect = useCallback((option: Option) => {
      onSelect?.(option)
      setInputFocus(false)
      setValue(option.key)
    }, [])

    const highlightMatch = useCallback((text: string, query?: string) => {
      if (!query) return text
      const parts = text.split(new RegExp(`(${query})`, 'gi'))
      return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <span key={`${text}-${query}-${index}`} className="font-bold">
            {part}
          </span>
        ) : (
          part
        )
      )
    }, [])

    const options = useMemo(
      () => (
        <div className="flex flex-col items-start">
          {suggestions.map((opt) => (
            <button
              key={opt.value}
              className={clsx(
                'flex space-x-2',
                'hover:bg-grey-solid-5/20 hover:text-grey-solid-2',
                'px-4 py-3 text-left text-grey-solid-4 text-md w-full'
              )}
              onClick={() => handleSelect(opt)}
              type="button"
            >
              {opt.icon && (
                <span className="min-w-5">
                  <Icon icon={opt.icon} />
                </span>
              )}
              <span>{highlightMatch(opt.key, value)}</span>
            </button>
          ))}
        </div>
      ),
      [suggestions]
    )

    useClickOutside(divRef, () => {
      setInputFocus(false)
    })

    return (
      <div ref={divRef} className="flex flex-col relative w-full">
        <InputField
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            inputMode: 'search',
          }}
          name={name}
          onChange={handleChange}
          onFocus={(focus) => {
            if (focus) {
              setInputFocus(true)
            }
          }}
          placeholder={placeholder}
          prefix={<Icon icon="search" size={size === 'xl' ? '18' : '20'} />}
          ref={inputRef}
          suffix={
            value && (
              <button className="flex" onClick={handleClear} type="button">
                <Icon icon="close" size={size === 'xl' ? '18' : '20'} />
              </button>
            )
          }
          size={size}
          value={value}
        />
        {onSelect && inputFocus && suggestions.length > 0 && (
          <div className="absolute bg-white-solid-1 border border-separator-solid-1 shadow-dropdown left-0 py-2 rounded-sm top-[63px] w-full z-50">
            {options}
          </div>
        )}
      </div>
    )
  }
)
