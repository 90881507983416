import { Icon } from '../../atoms'
import { PressKitNewsStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'

interface Props {
  caption: string
  image: {
    alt: string | null
    filename: string | null
  }
  title: string
  url: string
}

export const PressKitNews = ({ caption, image, title, url }: Props) => (
  <a href={url} target="_blank">
    <article className="flex flex-col border border-separator-solid-1 p-4 rounded space-y-3">
      <p className="font-semibold text-primary-solid-2 text-sm">{caption}</p>
      {image.filename && (
        <div className="h-[34px] relative w-full">
          <img
            alt={image.alt || title}
            className="absolute inset-0 object-contain h-full"
            src={image.filename}
          />
        </div>
      )}
      <div className="flex items-start space-x-2">
        <h4 className="font-semibold text-lg lg:text-dxs">{title}</h4>
        <div className="shrink-0">
          <Icon icon="arrow_top_right" />
        </div>
      </div>
    </article>
  </a>
)

export const PressKitNewsSb = ({ blok }: { blok: PressKitNewsStoryblok }) => (
  <PressKitNews {...blok} url={storyblokLink(blok.url)} />
)
