import { useMemo } from 'react'

import i18n from '../assets/locales.json'
import constants from '../assets/constants.json'

import { Breadcrumbs, Heading } from '../molecules'
import { Content, Layout } from '../templates'
import { CoursesLinks, CTABanner, Query } from '../organisms'
import { mapLocationBreadcrumbs, orderOptions } from '../helpers'
import {
  LocationBreadcrumb,
  PartialCourseIdentity,
  Place,
} from '../../../types'

type State = {
  count: number
  query: string
  orderBy: string
  page: number
  sortBy: string
}

interface Props {
  breadcrumbs: LocationBreadcrumb[]
  default_course_identity_images: string[]
  city_slug: string | null
  country_slug: string
  course_identities: PartialCourseIdentity[]
  initState?: State
  places?: Place[]
  region_slug: string | null
  total_count: number
}

export const LocationCoursesPage = ({
  breadcrumbs = [],
  default_course_identity_images,
  city_slug,
  country_slug,
  course_identities,
  initState = {
    count: 10,
    query: '',
    orderBy: 'name',
    page: 1,
    sortBy: 'asc',
  },
  places = [],
  region_slug,
  total_count,
}: Props) => {
  const crumbs = useMemo(
    () =>
      mapLocationBreadcrumbs(breadcrumbs, city_slug, country_slug, region_slug),
    [breadcrumbs, city_slug, country_slug, region_slug]
  )

  const name = useMemo(() => {
    const [last] = crumbs.slice(-1)
    return last?.text || ''
  }, [crumbs])

  const byRegionTitle = useMemo(() => {
    if (city_slug) {
      return i18n.locationCourses.regions.cityTitle
    }
    if (region_slug) {
      return i18n.locationCourses.regions.regionTitle
    }
    return i18n.locationCourses.regions.countryTitle
  }, [city_slug, region_slug])

  const byRegion = useMemo(() => {
    const location = region_slug || city_slug ? 'cities' : 'regions'
    return [
      {
        title: '',
        items: places.map((place) => ({
          text: place.course_identities_count
            ? `${place.name} (${place.course_identities_count})`
            : place.name,
          url: `${constants.routes.courses}/countries/${country_slug}/${location}/${place.slug}`,
        })),
      },
    ]
  }, [city_slug, places, region_slug])

  return (
    <Layout>
      <Content padding="py-16">
        <Breadcrumbs items={crumbs} />
        <Heading
          caption={`${total_count} ${i18n.global.courses}`}
          text={i18n.locationCourses.description.replace(/#\{name\}/g, name)}
          title={`${i18n.locationCourses.title} ${name}`}
        />
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
        <Query
          defaultCourseIdentityImages={default_course_identity_images}
          dropdownProps={{
            options: orderOptions,
            prefix: `${i18n.global.orderBy}:`,
          }}
          initData={course_identities}
          initState={initState}
          inputProps={{
            placeholder: `${i18n.locationCourses.search.placeholder} ${name}`,
          }}
          slug={country_slug}
          totalCount={total_count}
        />
        <CoursesLinks
          items={byRegion}
          title={`${byRegionTitle} ${name}`}
          text={i18n.courses.regions.text}
        />
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
      </Content>
    </Layout>
  )
}

export default LocationCoursesPage
