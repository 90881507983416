import { PostCard, PostCardProps } from '..'

interface Props {
  items: PostCardProps[]
  title: string
}

export const BlogPosts = ({ items, title }: Props) => (
  <div className="flex flex-col mt-6 md:mt-0">
    <p className="font-semibold mb-4 text-md text-grey-solid-3">{title}</p>
    <section className="grid grid-cols-1 mb-8 md:grid-cols-2 gap-8">
      {items.map((item) => (
        <PostCard key={item.url} {...item} />
      ))}
    </section>
  </div>
)
