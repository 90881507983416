import i18n from '../assets/locales.json'

import { Content, Layout } from '../templates'
import { CTABanner } from '../organisms'
import { LeaderboardBar, LeaderboardCard, LeaderboardTable } from '../molecules'

interface Props {
  webview: boolean
}

export const RoundGroupsPage = ({ webview }: Props) => (
  <Layout webview={webview}>
    <Content padding={webview ? 'py-6' : 'py-20'} spacing="space-y-8">
      {webview === false && (
        <CTABanner
          title={i18n.ctaBanner.sm.title}
          text={i18n.ctaBanner.sm.text}
          variant="sm"
        />
      )}
      <LeaderboardCard />
      <div className="space-y-2 md:space-y-4">
        <LeaderboardBar />
        <LeaderboardTable />
      </div>
    </Content>
  </Layout>
)
