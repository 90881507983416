import { useState, useMemo, useCallback } from 'react'
import groupBy from 'lodash.groupby'
import Fuse from 'fuse.js'

import i18n from '../../assets/locales.json'
import { ButtonGroup, GlossaryGrid, Heading } from '../../molecules'
import { GlossaryTerm } from '../../../../types'
import { Search } from '../../atoms'
import { useGlossary } from '../../hooks'

interface Props {
  defaultState?: {
    filterBy?: string
    query: string
  }
  text: string
  title: string
}

export const Glossary = ({
  defaultState = { filterBy: undefined, query: '' },
  text,
  title,
}: Props) => {
  const {
    glossary: { terms },
  } = useGlossary()

  const [results, setResults] = useState(terms)
  const [state, setState] = useState(defaultState)

  const fuse = useMemo(
    () =>
      new Fuse(terms, {
        includeScore: false,
        keys: ['term'],
        shouldSort: true,
      }),
    [terms]
  )

  const letters = useMemo(
    () =>
      Object.keys(groupBy(terms, ({ term }) => term[0]))
        .map((key) => ({
          key,
          value: key,
        }))
        .sort((a, b) => a.key.localeCompare(b.key)),
    [terms]
  )

  const groupAndFilter = useCallback(
    (entries: GlossaryTerm[], filterBy?: string) => {
      const group = Object.entries(groupBy(entries, ({ term }) => term[0])).map(
        ([letter, terms]) => ({ letter, terms })
      )
      return filterBy
        ? group.filter(
            ({ letter }) => letter.toLowerCase() === filterBy.toLowerCase()
          )
        : group
    },
    []
  )

  const handleFilter = useCallback(({ key }: { key?: string }) => {
    setState((prev) => ({ ...prev, filterBy: key }))
  }, [])

  const handleQuery = useCallback(
    (query: string) => {
      setResults(!query ? terms : fuse.search(query).map(({ item }) => item))
      setState((prev) => ({ ...prev, query }))
    },
    [fuse, terms]
  )

  return (
    <section>
      <Heading text={text} title={title} />
      <div className="flex flex-col lg:w-2/3 my-6">
        <Search
          defaultValue={state.query}
          name="query"
          onChange={handleQuery}
          placeholder={i18n.global.search}
          size="xl"
        />
      </div>
      <ButtonGroup
        active={state.filterBy}
        className="mb-8 lg:mb-24"
        items={letters}
        onClick={handleFilter}
      />
      <GlossaryGrid
        entries={
          state.query !== ''
            ? groupAndFilter(results, state.filterBy)
            : groupAndFilter(results, state.filterBy).sort((a, b) =>
                a.letter.localeCompare(b.letter)
              )
        }
      />
    </section>
  )
}
