import { useCallback, useEffect, useRef, useState } from 'react'
import omitBy from 'lodash.omitby'
import isUndefined from 'lodash.isundefined'

import { PaginationContext, State } from './PaginationContext'
import { fetchBlogposts } from '../../../storyblok'

export interface Pagination {
  data: unknown[]
  rels: unknown[]
  state: State
  total: number
}

interface Props {
  children: React.ReactNode
  pagination?: Pagination
}

export const PaginationProvider = ({
  children,
  pagination = {
    data: [],
    rels: [],
    state: {},
    total: 0,
  },
}: Props) => {
  const isFirstRender = useRef(true)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState(pagination?.data || [])
  const [rels, setRels] = useState(pagination?.rels || [])
  const [state, setState] = useState(pagination?.state || {})
  const [total, setTotal] = useState(parseInt(`${pagination?.total || 0}`))

  const updateState = useCallback((value: State) => {
    setError(false)
    setLoading(true)
    setState((prev) => omitBy({ ...prev, ...value }, isUndefined))
  }, [])

  const fetchData = useCallback(async (options: State) => {
    setLoading(true)
    const { error, rels, stories, total } = await fetchBlogposts<unknown>(
      options
    )
    setData(stories)
    setRels(rels)
    setError(error)
    setLoading(false)
    setTotal(total)
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    fetchData(state)
  }, [fetchData, state])

  const contextValue = {
    data,
    loading,
    error,
    rels,
    state,
    total,
    updateState,
  }

  return (
    <PaginationContext.Provider value={contextValue}>
      {children}
    </PaginationContext.Provider>
  )
}
