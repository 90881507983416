import i18n from '../../assets/locales.json'

export const HolesTitles = () => (
  <div className="divide-y divide-grey-alpha-3 flex flex-col font-semibold text-grey-solid-3">
    <div className="py-1 text-xs">&nbsp;</div>
    <div className="py-1 text-sm">{i18n.global.par}</div>
    <div className="py-1 text-sm">{i18n.global.scoreIndex}</div>
    <div className="flex font-bold items-center h-11 text-grey-solid-2 text-md">
      {i18n.global.scores}
    </div>
  </div>
)
