export interface BadgeProps {
  text: string
  url?: string
}

export const Badge = ({ text, url }: BadgeProps) => {
  const content = (
    <span className="font-medium text-primary-solid-2 text-sm text-nowrap">
      {text}
    </span>
  )

  if (url) {
    return (
      <a
        className="bg-primary-solid-7 border border-primary-solid-2 flex flex-col py-1 px-3 rounded-full"
        href={url}
      >
        {content}
      </a>
    )
  }
  return (
    <div className="bg-primary-solid-7 border border-primary-solid-2 flex flex-col py-1 px-3 rounded-full">
      {content}
    </div>
  )
}
