import clsx from 'clsx'

import { Icon } from '../../atoms/Icon'

interface ListItemProps {
  active: boolean
  disabled: boolean
  onClick: () => void
  text: string
  variant: 'page' | 'prev' | 'next'
}

export const PageListItem = ({
  active,
  disabled,
  onClick,
  text,
  variant,
}: ListItemProps) => {
  const content = (
    <span
      className={clsx('flex items-center px-3 py-2 space-x-1', {
        'bg-grey-alpha-7': active,
        'text-grey-solid-4': disabled,
      })}
    >
      {variant === 'prev' && <Icon icon="arrow_left_slimer" size="20" />}
      <span className={clsx({ 'hidden md:inline': variant !== 'page' })}>
        {text}
      </span>
      {variant === 'next' && <Icon icon="arrow_right_slimer" size="20" />}
    </span>
  )

  return (
    <li className="border-r border-grey-solid-6 last:border-r-0">
      {active || disabled ? (
        content
      ) : (
        <button className="flex hover:bg-grey-alpha-7" onClick={onClick} type="button">
          {content}
        </button>
      )}
    </li>
  )
}
