import { Button } from '../../atoms'
import { GlossaryText } from '../GlossaryText/GlossaryText'
import { PageHeadingStoryblok, RichtextStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'

interface Props {
  buttonText: string
  buttonUrl: string
  text: string | RichtextStoryblok
  title: string
}

export const PageHeading = ({ buttonText, buttonUrl, text, title }: Props) => (
  <section className="flex flex-col items-start lg:max-w-3xl">
    <h1 className="font-semibold mb-2 text-dmd md:text-dxl">{title}</h1>
    <GlossaryText
      className="text-grey-solid-2 text-dm md:text-xl"
      text={text}
    />
    {buttonText && buttonUrl && (
      <Button
        className="mt-6"
        color="primary-solid-2"
        href={buttonUrl}
        size="xl"
        text={buttonText}
      />
    )}
  </section>
)

export const PageHeadingSb = ({ blok }: PageHeadingStoryblok) => (
  <PageHeading {...blok} buttonUrl={storyblokLink(blok.buttonUrl)} />
)
