import constants from '../../assets/constants.json'

export const FooterLdJson = () => {
  const json = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Hole19',
    url: constants.routes.base,
    sameAs: [
      constants.routes.twitter,
      constants.routes.linkedin,
      constants.routes.facebook,
    ],
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
    />
  )
}
