import clsx from 'clsx'

import { Step } from '../Step/Step'
import {
  FeatureStepItemStoryblok,
  RichtextStoryblok,
} from '../../../../types/storyblok'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  image: {
    alt: string | null
    filename: string | null
  }
  imageRatio: '1/2' | '2/3'
  step: string
  text: string | RichtextStoryblok
}

export const FeatureStepItem = ({ image, imageRatio, step, text }: Props) => (
  <div className="flex flex-col items-center space-y-6">
    <Step value={step} />
    <span
      className={clsx('aspect-4/5 relative w-full', {
        'md:aspect-1/2 max-h-[310px] md:max-h-[540px]': imageRatio === '1/2',
        'md:aspect-2/3 max-h-[250px] md:max-h-[420px]': imageRatio === '2/3',
      })}
    >
      <img
        alt={image.alt || ''}
        className="absolute inset-0 h-full object-contain w-full"
        src={image.filename || ''}
      />
    </span>
    <div className="space-y-4 text-sm md:text-lg text-grey-solid-2">
      <GlossaryText text={text} />
    </div>
  </div>
)

export const FeatureStepItemSb = ({
  blok,
}: {
  blok: FeatureStepItemStoryblok
}) => <FeatureStepItem {...blok} />
