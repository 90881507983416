import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  className?: string
  main: ReactNode[]
  side: ReactNode[]
}

export const Sidebar = ({ className = '', main, side }: Props) => (
  <section
    className={clsx(
      className,
      'flex flex-col md:flex-row space-x-0 md:space-x-8 space-y-8 md:space-y-0'
    )}
  >
    <section className="flex flex-col md:w-3/5 space-y-8">{main}</section>
    <aside className="flex flex-col md:flex-1 space-y-8">{side}</aside>
  </section>
)
