import { ReactNode, useMemo } from 'react';
import { ISbStories, ISbStoryData } from 'storyblok-js-client';

import { SbRelationsContext } from './SbRelationsContext';

interface Props {
  children: ReactNode;
  recentBlogPosts?: ISbStories['data'];
  rels: ISbStoryData[];
}

export const SbRelationsProvider = ({
  children,
  recentBlogPosts = undefined,
  rels = [],
}: Props) => {
  const value = useMemo(
    () => ({ recentBlogPosts, rels }),
    [recentBlogPosts, rels]
  );

  return (
    <SbRelationsContext.Provider value={value}>
      {children}
    </SbRelationsContext.Provider>
  );
};
