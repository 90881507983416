import { PageSectionStoryblok, RichtextStoryblok } from '../../../../types'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  text: string | RichtextStoryblok
  title: string
}

export const PageSection = ({ text, title }: Props) => (
  <section className="flex flex-col">
    <h2 className="font-semibold mb-4 text-dsm">{title}</h2>
    <GlossaryText className="text-lg text-grey-solid-2" text={text} />
  </section>
)

export const PageSectionSb = ({ blok }: { blok: PageSectionStoryblok }) => (
  <PageSection {...blok} />
)
