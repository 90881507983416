import i18n from '../../assets/locales.json'

import { data } from './Nav.helpers'
import { Button, NavButton } from '../../atoms'
import { useAuthentication } from '../../hooks'

interface Props {
  handleMouseEnter?: () => void
}

export const NavAuthentication = ({ handleMouseEnter }: Props) => {
  const { user } = useAuthentication()

  return user ? (
    <>
      <div className="flex flex-col p-4 lg:px-0 lg:py-[7px] space-y-4">
        <div className="flex items-center gap-x-3 pl-4 min-w-0">
          <button
            className="inline-flex items-center justify-center"
            onMouseEnter={() => handleMouseEnter?.()}
            type="button"
          >
            <img
              alt="avatar"
              className="rounded-[7px]"
              height="34px"
              src={user.avatar_url}
              width="34px"
            />
          </button>
          <p className="lg:hidden text-md text-grey-solid-2 truncate">
            {user.full_name}
          </p>
        </div>
        <div className="flex flex-col items-start lg:hidden space-y-1">
          {data.profile.links.map((link) => (
            <NavButton
              key={link.text}
              icon="chevron_right"
              href={link.href}
              text={link.text}
            />
          ))}
        </div>
      </div>
      <div className="lg:hidden p-4">
        <Button
          className="text-nowrap"
          color="danger-solid-1"
          href="/users/sign_out"
          outline={true}
          size="xl"
          text={i18n.global.signOut}
        />
      </div>
    </>
  ) : (
    <div className="flex lg:justify-end gap-x-2 shrink-0 p-4 lg:p-0">
      <Button
        className="text-nowrap"
        color="primary-solid-2"
        href="/users/sign_in"
        size="xl"
        text={i18n.global.signIn}
      />
    </div>
  )
}
