import clsx from 'clsx'

import { BannerText } from './BannerText'
import { BannerButtons } from './BannerButtons'
import { CtaBannerStoryblok } from '../../../../types/storyblok'

import storyblok from '../../../../storyblok/presets.1022273.json'

const styles = {
  xl: {
    captionClass: 'hidden lg:block',
    showLogo: true,
    textClass: 'lg:pl-12 lg:pr-8',
    titleClass: 'text-dxs lg:text-dsm',
    wrapperClass: 'lg:flex-row lg:p-12',
  },
  lg: {
    captionClass: 'block',
    showLogo: true,
    textClass: 'p-0',
    titleClass: 'text-dxs lg:text-dsm',
    wrapperClass: 'lg:flex-row lg:p-12 lg:gap-x-10',
  },
  md: {
    captionClass: 'hidden',
    showLogo: false,
    textClass: 'p-0',
    titleClass: 'text-dxs lg:text-dsm',
    wrapperClass: 'lg:p-12',
  },
  sm: {
    captionClass: 'hidden',
    showLogo: false,
    textClass: 'p-0',
    titleClass: 'text-dxs',
    wrapperClass: 'lg:flex-row lg:px-12 lg:py-6 lg:gap-x-4',
  },
}

interface Props {
  app?: 'hole19' | 'CORE'
  caption?: string
  onClick?: () => void
  text: string
  title: string
  variant: keyof typeof styles
}

export const CTABanner = ({
  app = 'hole19',
  caption,
  onClick,
  title,
  text,
  variant,
}: Props) => (
  <section
    className={clsx(
      'bg-primary-solid-6 flex flex-col items-center justify-between gap-y-4 px-3 py-6 rounded w-full z-40',
      styles[variant]?.wrapperClass
    )}
  >
    <div className="flex-1">
      <BannerText
        app={app}
        className={styles[variant]?.textClass}
        showLogo={styles[variant]?.showLogo}
        titleClass={styles[variant]?.titleClass}
        title={title}
        text={text}
      />
    </div>
    <BannerButtons
      app={app}
      caption={caption}
      captionClass={styles[variant]?.captionClass}
      onClick={onClick || (() => {})}
      variant={variant}
    />
  </section>
)

export const CTABannerSb = ({ blok }: { blok: CtaBannerStoryblok }) => {
  const variant = (storyblok.presets || []).find(
    ({ preset: { component, variant } }) =>
      component === blok.component && variant === blok.variant
  )

  const withDefaults = {
    ...blok,
    caption: blok.caption || variant?.preset?.caption || '',
    text: blok.text || variant?.preset?.text || '',
    title: blok.title || variant?.preset?.title || '',
  }

  return <CTABanner {...withDefaults} onClick={() => {}} />
}
