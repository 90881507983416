import { ReactNode, useCallback, useMemo } from 'react'

import { GlossaryContext } from './GlossaryContext'
import { Glossary, RichtextStoryblok } from '../../../types'
import { withGlossaryLinks } from '../helpers/glossary'

interface Props {
  children: ReactNode
  glossary: Glossary
}

export const GlossaryProvider = ({
  children,
  glossary = { links: undefined, terms: [] },
}: Props) => {
  const withGlossary = useCallback(
    (text: string | RichtextStoryblok) =>
      withGlossaryLinks(text || '', glossary?.terms || []),
    [glossary]
  )

  const value = useMemo(
    () => ({ glossary, withGlossary }),
    [glossary, withGlossary]
  )

  return (
    <GlossaryContext.Provider value={value}>
      {children}
    </GlossaryContext.Provider>
  )
}
