import { ReactNode } from 'react'

import { PressKitCardStoryblok } from '../../../../types'
import { RichText } from '../../atoms'

export interface PressKitCardProps {
  image: {
    alt: string | null
    filename: string | null
  }
  text: ReactNode
}

export const PressKitCard = ({ image, text }: PressKitCardProps) => (
  <figure className="flex flex-col space-y-2 w-full">
    {image.filename && (
      <div className="aspect-2/1 border border-separator-solid-1 overflow-hidden relative rounded-md w-full">
        <img
          alt={image.alt || ''}
          className="absolute inset-0 object-contain h-full w-full"
          src={image.filename}
        />
      </div>
    )}
    <figcaption className="text-grey-solid-3">{text}</figcaption>
  </figure>
)

export const PressKitCardSb = ({ blok }: { blok: PressKitCardStoryblok }) => (
  <PressKitCard image={blok.image} text={<RichText content={blok.text} />} />
)
