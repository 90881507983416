import i18n from '../../assets/locales.json'

import { RoundStatus } from '../RoundStatus/RoundStatus'
import { RoundDate } from '../RoundDate/RoundDate'
import { useRoundGroups } from '../../hooks'

export const LeaderboardCard = () => {
  const { courseName, live, rounds, startedAt } = useRoundGroups()
  const [round] = rounds
  const holes = (round?.holes || []).length
  const par = (round?.holes || []).reduce((acc, curr) => acc + curr.par, 0)

  return (
    <section className="border border-grey-solid-7 flex flex-col p-4 rounded-md space-y-2">
      <div className="flex items-center justify-between">
        <RoundStatus live={live} />
        <RoundDate startedAt={startedAt} />
      </div>
      <div className="flex flex-col">
        <h1 className="font-bold text-dxs">{courseName}</h1>
        <p className="font-semibold space-x-1 text-grey-solid-3 text-xs uppercase">
          <span>
            {holes} {i18n.global.holes}
          </span>
          <span>
            {i18n.global.par}: {par}
          </span>
        </p>
      </div>
    </section>
  )
}
