import { ReactNode, useMemo } from 'react'

import { AppStateContext } from './AppStateContext'

interface Props {
  children: ReactNode
  webview?: boolean | null
}

export const AppStateProvider = ({ children, webview = false }: Props) => {
  const value = useMemo(
    () => ({
      webview: Boolean(webview),
    }),
    [webview]
  )

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  )
}
