import { ISbStoryData, ISbStories } from 'storyblok-js-client'
import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { useStoryblokState } from '@storyblok/react'

import {
  DatasourceEntries,
  GlossaryTerm,
  GlossaryTermLink,
} from '../../../types'

import {
  ApiService,
  ApiProvider,
  AppStateProvider,
  AuthenticationProvider,
  CategoriesProvider,
  GlossaryProvider,
  Pagination,
  PaginationProvider,
  SbRelationsProvider,
} from '../providers'

interface Props {
  blok?: ISbStoryData | null
  blog_page_data?: Pagination
  categories?: DatasourceEntries['datasource_entries']
  glossary?: {
    terms: GlossaryTerm[]
    links?: GlossaryTermLink[]
  }
  pop?: ApiService
  recent_blog_posts?: ISbStories['data']
  rels?: ISbStoryData[]
  rex?: ApiService
  webview?: boolean
}

export const Page = ({
  blok = null,
  blog_page_data,
  categories = [],
  glossary = { terms: [] },
  pop,
  recent_blog_posts = undefined,
  rels = [],
  rex,
  webview = false,
}: Props) => {
  const story = useStoryblokState(blok)

  if (!story?.content) {
    return <div>Loading..</div>
  }

  return (
    <AppStateProvider webview={webview}>
      <ApiProvider rex={rex} pop={pop}>
        <AuthenticationProvider>
          <SbRelationsProvider recentBlogPosts={recent_blog_posts} rels={rels}>
            <CategoriesProvider categories={categories}>
              <GlossaryProvider glossary={glossary}>
                <PaginationProvider pagination={blog_page_data}>
                  <StoryblokServerComponent blok={story.content} />
                </PaginationProvider>
              </GlossaryProvider>
            </CategoriesProvider>
          </SbRelationsProvider>
        </AuthenticationProvider>
      </ApiProvider>
    </AppStateProvider>
  )
}

export default Page
