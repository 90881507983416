import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { storyblokEditable } from '@storyblok/react'

import i18n from '../assets/locales.json'
import constants from '../assets/constants.json'

import { Breadcrumbs, Heading } from '../molecules'
import { Content, Layout } from '../templates'
import { GlossaryTermLinks } from '../organisms'
import { GlossaryTermPageStoryblok } from '../../../types'
import { toSlug } from '../helpers'

export const GlossaryTermPage = ({
  blok,
}: {
  blok: GlossaryTermPageStoryblok
}) => (
  <Layout {...storyblokEditable(blok)}>
    <Content className="pt-16">
      <section className="flex flex-col space-y-8">
        <Breadcrumbs
          items={[
            { text: i18n.global.glossary, url: constants.routes.glossary },
            {
              current: true,
              text: blok.term,
              url: `${constants.routes.glossary}/${toSlug(blok.term)}`,
            },
          ]}
        />
        {blok.head?.map((nestedBlok) => (
          <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </section>
      <Heading
        disableGlossary={true}
        title={blok.term}
        text={blok.definition}
      />
      <GlossaryTermLinks title={i18n.glossary.linksTitle} />
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)

export default GlossaryTermPage
