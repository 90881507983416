import { StoryblokServerComponent } from '@storyblok/react/rsc'
import { AdvertiseOptionStoryblok } from '../../../../types'

export const AdvertiseOptionSb = ({
  blok,
}: {
  blok: AdvertiseOptionStoryblok
}) => (
  <section className="flex flex-col">
    {blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  </section>
)
