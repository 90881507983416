export const calculateCourseHandicap = (
  holesCount: number,
  courseRating: number,
  slopeRating: number,
  par: number,
  handicapIndex : number,
  countryIso2Code: string,
) : number => {

  const is9Hole = holesCount <= 9

  if (is9Hole && courseRating > 40) {
    // Rating bug:
    // We're assuming that the rating is the official one since that's what happened
    // when we mapped these courses. It seems like this course was rated with a course
    // rating suitable for an 18-hole course, but we're playing a 9-hole course.
    // This will result in a playing handicap that just doesn't make sense because
    // "courseRating - par" will result in a huge number when it is meant to be a single
    // digit value. This will result in a playing handicap that is way too high.v

    return calculateCourseHandicap(
      holesCount,
      courseRating / 2,
      slopeRating,
      par,
      handicapIndex,
      countryIso2Code
    )
  }

  // Documentation:
  // https://www.usga.org/handicapping/roh/2020-rules-of-handicapping.html
  const slopeRatingFactor =  (slopeRating > 0)
    ? slopeRating / 113
    : 1 // According to the Documentation this use-case does not exist. This is a way to fail gracefully.

  const courseRatingAdjustment = (courseRating > 0)
    ? courseRating - par
    : 0 // It is possible (under WHS) for some courses to have Slope Rating and not have Course Rating
        // with CR: https://www.whs.com/articles/2020/playing_handicap_cr_par.html
        // without CR: https://www.whs.com/articles/2020/playing_handicap.html
        //
        // Courses in the UK and Ireland also don't have this adjustment into account

  const  multiplier9Hole = is9Hole ? 2 : 1

  const courseHcp =
    handicapIndex * slopeRatingFactor / multiplier9Hole + courseRatingAdjustment

  // WHS Allowance:
  //
  // The WHS recommends a different allowance depending on the type of game and competition size
  // (source: https://www.usga.org/handicapping/roh/2020-rules-of-handicapping.html)
  // The friendly match use-case has no allowance.
  //
  // In Australia, GA defines the allowance to always be 0.93
  // (source: https://www.golf.org.au/whs/)
  const allowance = getDefaultHandicapAllowance(countryIso2Code)

  return Math.round(courseHcp * allowance)
}

const HANDICAP_ALLOWANCE_DEFAULT = 1
const HANDICAP_ALLOWANCE_AUSTRALIA = 0.93

const getDefaultHandicapAllowance = (countryIso2Code : string) : number => {
  switch (countryIso2Code) {
    case "AU": // Australia
      return HANDICAP_ALLOWANCE_AUSTRALIA // 0.93f
    default:
      return HANDICAP_ALLOWANCE_DEFAULT // 1f
  }
}
