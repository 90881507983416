import { Button } from '../../atoms'
import { Logo } from '../../atoms/Logo'

import i18n from '../../assets/locales.json'

interface Props {
  courseName: string
  image: {
    alt: string | null
    filename: string | null
  }
  handicap: string
  holes: string
  par: string
  rating: string
  slope: string
  tees: string
  title: string
}

export const HandicapCard = ({
  courseName,
  image,
  handicap,
  holes,
  par,
  rating,
  slope,
  tees,
  title,
}: Props) => (
  <article className="bg-separator-solid-2 flex flex-col flex-grow max-w-[600px] overflow-hidden relative rounded z-0">
    {image.filename && (
      <img
        alt={image.alt || title}
        className="absolute top-0 right-0 object-contain w-full -z-10"
        src={image.filename}
      />
    )}
    <div className="bg-gradient-to-t from-separator-solid-2 from-25% to-transparent flex flex-col py-8 md:py-12 px-4 md:px-7 gap-y-12">
      <div className="max-w-[116px]">
        <Logo variant="default" />
      </div>
      <div className="flex flex-col gap-y-4">
        <div className="w-full md:w-1/2">
          <h2 className="font-semibold text-dmd text-grey-solid-1">{title}</h2>
          <p className="font-bold leading-none text-[120px] text-primary-solid-2">
            {handicap}
          </p>
        </div>
        <div className="flex items-center justify-between gap-x-4">
          <div className="md:flex-grow w-full">
            <h3 className="font-bold text-lg text-grey-solid-1">
              {courseName}
            </h3>
            <p className="font-medium text-grey-solid-3 text-xs">{tees}</p>
          </div>
          <div className="flex justify-end md:flex-grow gap-x-2 md:gap-x-4 text-center w-full">
            {[
              { key: i18n.global.holes, value: holes },
              { key: i18n.global.par, value: par },
              { key: i18n.global.rating, value: rating },
              { key: i18n.global.slope, value: slope },
            ].map((item) => (
              <div key={item.key} className="flex flex-col gap-y-1">
                <div className="font-semibold text-grey-solid-3 text-xs">
                  {item.key}
                </div>
                <div className="font-bold text-xl">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start">
        <Button
          color="grey-solid-0"
          icon="share"
          onClick={() => {
            try {
              navigator.share({
                title: i18n.handicap.share.text,
                url: i18n.handicap.share.url,
              })
            } catch (err) {
              console.warn(err)
            }
          }}
          outline={true}
          text={i18n.handicap.share.button}
        />
      </div>
    </div>
  </article>
)
