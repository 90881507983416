import { ReactNode } from 'react'
import { FeatureContentStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  children: ReactNode[]
}

export const FeatureContent = ({ children }: Props) => (
  <div className="flex flex-col items-center lg:justify-between lg:flex-row lg:space-x-12">
    {children}
  </div>
)

export const FeatureContentSb = ({
  blok,
}: {
  blok: FeatureContentStoryblok
}) => (
  <FeatureContent
    children={blok.items.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  />
)
