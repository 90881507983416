import { Author, AuthorProps } from '../Author/Author'
import { AuthorStoryblok, QuoteStoryblok } from '../../../../types'
import { GlossaryText } from '../GlossaryText/GlossaryText'
import { useSbRelations } from '../../hooks'

interface Props {
  author?: AuthorProps
  text: string
}

export const Quote = ({ author, text }: Props) => (
  <blockquote className="border-l-2 border-0 border-primary-solid-2 flex flex-col py-2 space-y-6 pl-4">
    <GlossaryText className="font-medium text-dxs italic" text={text} />
    {author && <Author {...author} />}
  </blockquote>
)

export const QuoteSb = ({ blok }: QuoteStoryblok) => {
  const { rels } = useSbRelations()
  const author = rels.find(({ uuid }) => blok.author === uuid)

  return (
    <Quote
      author={(author as unknown as AuthorStoryblok)?.content || undefined}
      text={blok.text}
    />
  )
}
