import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { Content, Layout } from '../templates'
import { CareersPageStoryblok } from '../../../types'

export const CareersPage = ({ blok }: { blok: CareersPageStoryblok }) => (
  <Layout>
    <Content padding="py-16 md:pt-24">
      {blok.body?.map((nestedBlok) => (
        <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </Content>
  </Layout>
)
