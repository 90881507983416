import { MembersGridStoryblok } from "../../../../types"
import { MemberItem, MemberItemProps } from "../../molecules"

interface Props {
  items: MemberItemProps[]
  text: string
  title: string
}

export const MembersGrid = ({ items, text, title }: Props) => (
  <section className="flex flex-col">
    <h2 className="font-semibold mb-4 text-dsm">{title}</h2>
    <p className="text-grey-solid-2 mb-8 text-lg">{text}</p>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-x-6 gap-y-8">
      {items.map((item) => <MemberItem key={item.name} {...item} />)}
    </div>
  </section>
)

export const MembersGridSb = ({ blok }: MembersGridStoryblok) => (
  <MembersGrid {...blok} />
)
