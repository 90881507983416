import clsx from 'clsx'
import { ScoreValue } from './ScoreValue'

interface Props {
  colorName: string | 'birdie' | 'bogey' | 'bogey2' | 'eagle' | 'par'
  score: number
  scratched: boolean
  sup?: number
}

export const Score = ({ colorName, score, scratched, sup }: Props) => {
  const ring = ['bogey2', 'eagle'].includes(colorName)
  const rounded = ['birdie', 'eagle', 'par'].includes(colorName)
  const lighter = ['bogey', 'bogey2', 'birdie'].includes(colorName)

  if (!score) {
    return (
      <div className="flex items-center justify-center h-[36px] text-grey-solid-3 text-[15px] w-[36px]">
        &ndash;
      </div>
    )
  }

  return (
    <div
      className={clsx('flex flex-col items-center justify-center', {
        'bg-score-solid-birdie': colorName === 'birdie',
        'bg-score-solid-bogey': colorName === 'bogey',
        'bg-score-solid-eagle ring-score-solid-eagle': colorName === 'eagle',
        'bg-score-solid-2-bogey ring-score-solid-2-bogey':
          colorName === 'bogey2',
        'bg-score-solid-par': colorName === 'par',
        'h-[36px] w-[36px]': !ring,
        'h-[28px] w-[28px] ring-offset-2 ring-2': ring,
        'rounded-full': rounded,
        'rounded-xs': !rounded,
        'text-grey-solid-2': !lighter,
        'text-white-solid-1': lighter,
      })}
    >
      <div className="flex flex-col -space-y-2 text-[15px]">
        <span>
          <ScoreValue
            colorName={colorName}
            score={score}
            scratched={scratched}
          />
          {sup !== undefined && <sup>{sup}</sup>}
        </span>
        <span className="font-bold leading-none text-xs">&hellip;</span>
      </div>
    </div>
  )
}
