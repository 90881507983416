import clsx from 'clsx'

import Icons from './Icons'

export interface IconProps {
  className?: string
  icon: keyof typeof Icons
  size?: 'sm' | 'md' | 'lg' | 'xl' | '20' | '18' | 'full'
}

export const Icon = ({ className, icon, size = 'md' }: IconProps) => (
  <div
    aria-hidden="true"
    className={clsx('block', className, {
      'h-[16px] w-[16px]': size === 'sm',
      'h-[18px] w-[18px]': size === '18',
      'h-[20px] w-[20px]': size === '20',
      'h-[24px] w-[24px]': size === 'md',
      'h-[30px] w-[30px]': size === 'lg',
      'h-[42px] w-[42px]': size === 'xl',
      'h-full w-full': size === 'full',
    })}
  >
    <svg
      className="h-full w-full"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        d={Icons[icon]}
        fill="currentColor"
        clipRule="evenodd"
      />
    </svg>
  </div>
)
