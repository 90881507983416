import clsx from 'clsx'

import i18n from '../../assets/locales.json'

const DEFAULT = i18n.global.all

type Item = {
  key?: string
  value: string
}

interface Props {
  active?: Item['key']
  className?: string
  items: Item[]
  onClick: (item: Item) => void
}

export const ButtonGroup = ({
  active = undefined,
  className = '',
  items,
  onClick,
}: Props) => (
  <section
    className={clsx(
      'flex items-start font-semibold overflow-x-auto text-grey-solid-2 text-sm',
      className
    )}
  >
    <ul className="bg-white-solid-1 border border-grey-solid-6 flex items-start rounded-sm">
      {[{ key: undefined, value: DEFAULT }, ...items].map((item) => (
        <li
          key={`${item.key}-${item.value}`}
          className="border-r border-grey-solid-6 last:border-r-0 text-nowrap"
        >
          <button
            className={clsx('hover:bg-grey-alpha-7 py-2 px-3', {
              'bg-grey-alpha-7 text-grey-solid-0': active === item.key,
            })}
            disabled={active === item.key}
            onClick={() => onClick(item)}
            type="button"
          >
            {item.value}
          </button>
        </li>
      ))}
    </ul>
  </section>
)
