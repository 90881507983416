import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { GridStoryblok } from '../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

type Columns = '12' | '6' | '4' | '3'

interface Props {
  className?: string
  children?: ReactNode[]
  lgCols: Columns
  mdCols: Columns
  smCols: Columns
}

let lastKey = 0

export const Grid: FC<Props> = ({
  className,
  children = [],
  lgCols,
  mdCols,
  smCols,
}) => (
  <div className={clsx('auto-rows-fr grid grid-cols-12 gap-2', className)}>
    {children.map((child) => {
      lastKey += 1

      return (<div
        key={lastKey + 1}
        className={`col-span-${smCols} md:col-span-${mdCols} lg:col-span-${lgCols}`}
      >
        {child}
      </div>)
      })}
  </div>
)

export const GridSb = ({ blok }: { blok: GridStoryblok }) => (
  <Grid
    children={blok.content?.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
    smCols={blok.smColumns}
    mdCols={blok.mdColumns}
    lgCols={blok.lgColumns}
  />
)
