import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  image: {
    alt: string
    filename: string
  }
}

export const CourseImageForecast = ({ children, image }: Props) => (
  <section className="border border-grey-solid-6 flex flex-col overflow-hidden rounded w-full">
    <div className="aspect-4/3 md:aspect-9/5 relative w-full">
      <img
        alt={image.alt}
        className="absolute inset-0 h-full object-cover w-full"
        src={image.filename}
      />
    </div>
    {children}
  </section>
)
