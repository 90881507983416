import { createContext } from 'react'

interface Props {
  countryCode: string,
  data: PremiumProduct[]
  loading: boolean
}

export const PremiumProductsContext = createContext<Props>({
  countryCode: 'US',
  data: [],
  loading: false,
})
