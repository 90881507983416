import { Dropdown, Option } from '../../molecules'
import { Search } from '../../atoms'

interface Props {
  dropdownProps: {
    options: Option[]
    prefix?: string
  }
  inputProps: {
    placeholder: string
  }
  query: string
  onChange: (key: string, value: string | number) => void
  orderBy: string
}

export const QueryPanel = ({
  dropdownProps,
  inputProps,
  query,
  onChange,
  orderBy,
}: Props) => (
  <div className="grid grid-cols-1 gap-y-2 md:grid-cols-12 md:gap-x-6">
    <div className="col-span-12 md:col-span-8">
      <Search
        defaultValue={query}
        placeholder={inputProps.placeholder}
        onChange={(val) => onChange('query', val)}
        name="query"
      />
    </div>
    <div className="col-span-12 md:col-span-4">
      <Dropdown
        onChange={({ value }) => onChange('orderBy', value)}
        options={dropdownProps.options}
        prefix={dropdownProps.prefix}
        text={dropdownProps.options.find((option) => option.value === orderBy)?.key as string}
      />
    </div>
  </div>
)
