import { ReactNode } from 'react'

import i18n from '../../assets/locales.json'
import { FreeTag, PremiumTag } from '../Tags/Tags'
import { FeatureListStoryblok } from '../../../../types/storyblok'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

interface Props {
  premium: ReactNode[]
  free: ReactNode[]
}

export const FeatureList = ({ premium, free }: Props) => (
  <section className="max-w-[800px] mx-auto">
    <div className="flex items-center justify-between mb-4 pl-4 pr-0 md:mb-8 md:px-8">
      <h5 className="font-semibold text-grey-solid-2 text-md md:text-xl uppercase">
        {i18n.global.feature}
      </h5>
      <div className="flex items-center px-1 space-x-1 md:space-x-3">
        <PremiumTag />
        <FreeTag />
      </div>
    </div>
    <ul className="bg-primary-solid-7 flex flex-col p-4 md:p-8 space-y-8 rounded">
      {premium}
    </ul>
    <div className="my-5 px-5 md:mt-10 md:mb-8 md:px-8">
      <h5 className="font-semibold text-grey-solid-2 text-md md:text-xl">
        {i18n.global.plusAllTheFreeFeatures}
      </h5>
    </div>
    <ul className="bg-white-solid-1 flex flex-col p-4 md:p-8 space-y-8">
      {free}
    </ul>
  </section>
)

export const FeatureListSb = ({ blok }: { blok: FeatureListStoryblok }) => (
  <FeatureList
    free={blok.free.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
    premium={blok.premium.map((nestedBlok) => (
      <StoryblokServerComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  />
)
