import { useEffect, useState } from 'react'

import i18n from '../assets/locales.json'
import { usePagination } from './usePagination'

const text = (total: number, query?: string) =>
  query
    ? `${total} ${i18n.global.resultsFor} "${query}"`
    : total === 1
    ? `${total} ${i18n.global.article}`
    : `${total} ${i18n.global.articles}`

export const usePaginationTitle = () => {
  const { loading, state, total } = usePagination()

  const [title, setTitle] = useState(text(total, state.search_term))

  useEffect(() => {
    if (loading) {
      return
    }
    setTitle(text(total, state.search_term))
  }, [loading, state, total])

  return title
}
