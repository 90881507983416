import i18n from '../../assets/locales.json'

import { LiveStatus } from '../LiveStatus/LiveStatus'

export interface RoundStatusProps {
  live: boolean
}

export const RoundStatus = ({ live }: RoundStatusProps) => (
  <div className="bg-grey-alpha-6 inline-flex items-center gap-x-1 py-1 px-2 rounded-xs">
    <span className="font-semibold text-nowrap text-xs text-grey-solid-2 uppercase">
      {live ? i18n.leaderboard.round.ongoing : i18n.leaderboard.round.finished}
    </span>
    <LiveStatus live={live} />
  </div>
)
