import clsx from 'clsx'
import { useMemo } from 'react'

import i18n from '../../assets/locales.json'

import { Badge } from '../Badge/Badge'
import { Button } from '../../atoms'
import { GlossaryText } from '../GlossaryText/GlossaryText'
import { PlanCardStoryblok } from '../../../../types'
import { storyblokLink } from '../../helpers'
import { usePremiumProducts } from '../../hooks'

interface Props {
  badge?: string
  buttonText: string
  buttonUrl: string
  caption: string
  highlight: boolean
  text: string
  title: string
}

export const PlanCard = ({
  badge,
  buttonText,
  buttonUrl,
  caption,
  highlight = false,
  text,
  title,
}: Props) => (
  <article
    className={clsx(
      'border flex flex-col items-center justify-center h-full p-8 md:px-12 rounded text-center',
      {
        'border-[3px] border-primary-solid-2': highlight,
        'border-separator-solid-1': !highlight,
      }
    )}
  >
    <p className="font-semibold mb-1 text-primary-solid-2">{caption}</p>
    <h2 className="font-semibold mb-3 text-dmd">{title}</h2>
    {badge && (
      <div className="mb-3">
        <Badge text={badge} />
      </div>
    )}
    <GlossaryText className="mb-8 text-grey-solid-2 text-lg" text={text} />
    <div className="flex flex-col items-center">
      <Button
        color="primary-solid-2"
        href={buttonUrl}
        text={buttonText}
        size="2xl"
      />
    </div>
  </article>
)

export const PlanCardSb = ({ blok }: { blok: PlanCardStoryblok }) => {
  const { countryCode, data, loading } = usePremiumProducts()

  const product = useMemo(() => {
    const match = data.find((d) => d.public_id === blok.public_id)
    if (loading || !match) {
      return {
        ...blok,
        buttonUrl: storyblokLink(blok.buttonUrl),
        highlight: Boolean(blok.highlight)
      }
    }
    const highlight = match.tags.includes('most_popular')
    return {
      ...blok,
      badge: highlight ? i18n.global.bestValue : undefined,
      buttonUrl: `/premium/checkout?country_code=${countryCode}&product=${match.public_id}`,
      highlight,
      title: `${match.currency_symbol}${match.amount}`,
    }
  }, [blok, data, loading])

  return <PlanCard {...product} />
}
