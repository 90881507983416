import { RelatedLinksStoryblok, RichtextStoryblok } from "../../../../types"
import { Text } from '../Text/Text'

interface Props {
  links: RichtextStoryblok
  title: string
}

export const RelatedLinks = ({ links, title }: Props) => (
  <section className="flex flex-col space-y-2">
    <h3 className="font-bold text-xl">{title}</h3>
    <Text text={links} />
  </section>
)

export const RelatedLinksSb = ({ blok }: RelatedLinksStoryblok) => (
  <RelatedLinks {...blok} />
)
