import moment from 'moment';
import textColor from '../shared/textColor.es6';

function toParString(value) {
  if (value === undefined) {
    return undefined;
  } if (value > 0) {
    return `+${value}`;
  } if (value === 0) {
    return 'E';
  }
  return `${value}`;
}

function defaultScoringModeIsNet(scoringModeType) {
  return scoringModeType === 'stableford';
}

function toParClassName(value) {
  if (value === undefined) {
    return undefined;
  } if (value >= 2) {
    return 'bogey2';
  } if (value === 1) {
    return 'bogey';
  } if (value === 0) {
    return 'par';
  } if (value === -1) {
    return 'birdie';
  }
  return 'eagle';
}

function scoringModeFromTypeAndNet(scoringModeType, scoringModeIsNet) {
  return `${scoringModeType}_${scoringModeIsNet ? 'net' : 'gross'}`;
}

const sortAscending = (a, b) => a - b;

const sortDescending = (a, b) => b - a;

function numberToHandicap(value) {
  if (value && value < 0) {
    return `+${Math.abs(value)}`;
  }

  return value;
}

const sortByHandicap = (aRound, bRound, ascending) => {
  if (aRound.playingHandicap === bRound.playingHandicap) return aRound.user.fullName.localeCompare(bRound.user.fullName);

  return ascending
    ? sortAscending(aRound.playingHandicap, bRound.playingHandicap)
    : sortDescending(aRound.playingHandicap, bRound.playingHandicap);
};

const compareRounds = (aRound, bRound, scoringMode) => {
  if (aRound.throughHole !== bRound.throughHole && (aRound.throughHole === '-' || bRound.throughHole === '-')) {
    return aRound.throughHole === '-' ? 1 : -1; // Pin the round with '-' to the bottom
  }

  switch (scoringMode) {
    case 'stableford_net':
      return aRound.stablefordNet === bRound.stablefordNet
        ? sortByHandicap(aRound, bRound, true)
        : sortDescending(aRound.stablefordNet, bRound.stablefordNet);
    case 'stableford_gross':
      return aRound.stablefordGross === bRound.stablefordGross
        ? sortByHandicap(aRound, bRound, false)
        : sortDescending(aRound.stablefordGross, bRound.stablefordGross);
    case 'stroke_play_net':
      return aRound.toParNetScore === bRound.toParNetScore
        ? sortByHandicap(aRound, bRound, true)
        : sortAscending(aRound.toParNetScore, bRound.toParNetScore);
    default: // 'stroke_play_gross'
      return aRound.toParGrossScore === bRound.toParGrossScore
        ? sortByHandicap(aRound, bRound, false)
        : sortAscending(aRound.toParGrossScore, bRound.toParGrossScore);
  }
};

class RoundGroupViewModel {
  constructor({
    id,
    slug,
    startedAt,
    status,
    scoringModeType,
    course,
    rounds,
  }) {
    const localScoringModeType = scoringModeType || 'stroke_play';
    this.id = id;
    this.slug = slug;
    this.startedAt = moment(startedAt).toDate();
    this.status = status;
    this.scoringModeType = localScoringModeType;
    this.scoringModeIsNet = defaultScoringModeIsNet(localScoringModeType);
    this.course = course;
    this.rounds = rounds;
  }

  roundsSortedByScoringModeTypeAndNet(scoringModeType, scoringModeIsNet) {
    return this.rounds.sort((a, b) => compareRounds(a, b, scoringModeFromTypeAndNet(scoringModeType, scoringModeIsNet)));
  }

  isLive() {
    return this.status === 'started';
  }

  scoringMode() {
    scoringModeFromTypeAndNet(this.scoringModeType, this.scoringModeIsNet);
  }

  defaultScoringModeIsNet(scoringModeType) {
    return defaultScoringModeIsNet(scoringModeType);
  }
}

class Round {
  constructor({
    id,
    user,
    creationToken,
    inputMode,
    tee,
    handicap,
    playingHandicap,
    stablefordGross,
    stablefordNet,
    strokePlayGross,
    strokePlayNet,
    toParGrossScore,
    toParNetScore,
    holes,
    throughHole,
  }) {
    this.id = id;
    this.user = user;
    this.creationToken = creationToken;
    this.inputMode = inputMode;
    this.tee = tee;
    this.handicap = handicap;
    this.playingHandicap = playingHandicap;
    this.stablefordGross = stablefordGross;
    this.stablefordNet = stablefordNet;
    this.strokePlayGross = strokePlayGross;
    this.strokePlayNet = strokePlayNet;
    this.toParGrossScore = toParGrossScore;
    this.toParNetScore = toParNetScore;
    this.toParGrossScoreString = toParString(toParGrossScore);
    this.toParNetScoreString = toParString(toParNetScore);
    this.holes = holes;
    this.throughHole = throughHole;
  }

  scoresByScoringMode(scoringModeType, scoringModeIsNet) {
    switch (scoringModeFromTypeAndNet(scoringModeType, scoringModeIsNet)) {
      case 'stroke_play_gross':
        return {
          score_title: 'Stk',
          total_score: this.strokePlayGross,
          to_par_title: 'To Par',
          to_par: this.toParGrossScoreString,
        };
      case 'stroke_play_net':
        return {
          score_title: 'Stk',
          total_score: this.strokePlayNet,
          to_par_title: 'To Par (Net)',
          to_par: this.toParNetScoreString,
        };
      case 'stableford_gross':
        return {
          score_title: 'Pts',
          total_score: this.stablefordGross,
          to_par_title: 'To Par',
          to_par: this.toParGrossScoreString,
        };
      case 'stableford_net':
        return {
          score_title: 'Pts',
          total_score: this.stablefordNet,
          to_par_title: 'To Par (Net)',
          to_par: this.toParNetScoreString,
        };
      default:
        return this.scoresByScoringMode('stroke_play', false);
    }
  }
}

class HoleScore {
  constructor({
    sequence,
    par,
    strokeIndex,
    totalOfStrokes,
    scratched,
    lastVersionAt,
    stablefordGross,
    stablefordNet,
    strokePlayGross,
    strokePlayNet,
    toParGrossScore,
    toParNetScore,
    scoreColorName,
  }) {
    this.sequence = sequence;
    this.par = par;
    this.strokeIndex = strokeIndex;
    this.totalOfStrokes = totalOfStrokes;
    this.isScratched = scratched;
    this.lastVersionAt = lastVersionAt;
    this.stablefordGross = stablefordGross;
    this.stablefordNet = stablefordNet;
    this.strokePlayGross = strokePlayGross;
    this.strokePlayNet = strokePlayNet;
    this.toParGrossScore = toParGrossScore;
    this.toParNetScore = toParNetScore;
    this.scoreColorName = scoreColorName;
  }

  stablefordScore(scoringModeType, scoringModeIsNet) {
    switch (scoringModeFromTypeAndNet(scoringModeType, scoringModeIsNet)) {
      case 'stableford_gross':
        return this.stablefordGross;
      case 'stableford_net':
        return this.stablefordNet;
      default:
        return '';
    }
  }
}

class User {
  constructor({
    fullName,
    handicap,
    playingHandicap,
    subtitle,
    avatarUrl,
    tee,
  }) {
    this.fullName = fullName;
    this.handicap = handicap;
    this.playingHandicap = playingHandicap;
    this.subtitle = subtitle;
    this.avatarUrl = avatarUrl;
    this.tee = tee;
  }
}

class Tee {
  constructor({
    name,
    backgroundColor,
  }) {
    this.name = name;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor(backgroundColor);
  }
}

class Course {
  constructor({
    name,
  }) {
    this.name = name;
  }
}

function createRoundGroupFromApi(roundGroup, defaultAvatar) {
  const roundScores = roundGroup.rounds.map((round) => {
    let totalPlayedHoles = 0;
    let throughHole;
    const holeScores = round.holes.map((hole) => {
      if (hole.total_of_strokes && hole.total_of_strokes > 0) {
        totalPlayedHoles += 1;
      }

      return new HoleScore({
        sequence: hole.sequence,
        par: hole.par,
        strokeIndex: hole.stroke_index,
        totalOfStrokes: hole.total_of_strokes,
        scratched: hole.scratched,
        lastVersionAt: hole.last_version_at,
        stablefordGross: hole.stableford_gross,
        stablefordNet: hole.stableford_net,
        strokePlayGross: hole.stroke_play_gross,
        strokePlayNet: hole.stroke_play_net,
        toParGrossScore: hole.to_par_gross_score,
        toParNetScore: hole.to_par_net_score,
        scoreColorName: (hole.total_of_strokes > 0 && toParClassName(hole.to_par_gross_score)) || 'none',
      });
    });

    if (totalPlayedHoles <= 0) {
      throughHole = '-';
    } else if (totalPlayedHoles === holeScores.length) {
      throughHole = 'F';
    } else {
      throughHole = totalPlayedHoles;
    }

    const tee = new Tee({
      name: round.tee_name,
      backgroundColor: round.tee_rgb,
    });

    const user = new User({
      fullName: round.user.full_name,
      handicap: numberToHandicap(round.handicap),
      playingHandicap: numberToHandicap(round.playing_handicap),
      subtitle: `P.HCP ${numberToHandicap(round.playing_handicap)} (HCP ${numberToHandicap(round.handicap)})`,
      avatarUrl: round.user.avatar_url || defaultAvatar,
      tee,
    });

    return new Round({
      id: round.id,
      user,
      creationToken: round.creation_token,
      inputMode: round.input_mode,
      tee,
      handicap: round.handicap,
      playingHandicap: round.playing_handicap,
      stablefordGross: round.stableford_gross,
      stablefordNet: round.stableford_net,
      strokePlayGross: round.stroke_play_gross,
      strokePlayNet: round.stroke_play_net,
      toParGrossScore: round.to_par_gross_score,
      toParNetScore: round.to_par_net_score,
      holes: holeScores,
      throughHole,
    });
  });

  const course = new Course({
    name: roundGroup.course.name,
  });

  return new RoundGroupViewModel({
    roundGroupId: roundGroup.id,
    slug: roundGroup.slug,
    startedAt: roundGroup.started_at,
    status: roundGroup.status,
    scoringModeType: roundGroup.scoring_mode,
    course,
    rounds: roundScores,
  });
}

export default createRoundGroupFromApi;
