import { FeatureHighlightStoryblok } from '../../../../types/storyblok'
import { GlossaryText } from '../GlossaryText/GlossaryText'

interface Props {
  image: {
    alt: string | null
    filename: string | null
  }
  text: string
  title: string
}

export const FeatureHighlight = ({ image, text, title }: Props) => (
  <section className="flex flex-col items-center justify-between lg:pt-16 lg:space-x-12 lg:flex-row lg:px-12">
    <div className="flex flex-col space-y-2 lg:space-y-3 text-center lg:max-w-[560px] lg:text-left">
      <h2 className="font-semibold text-xl lg:text-dsm">{title}</h2>
      <GlossaryText
        className="text-sm lg:text-lg text-grey-solid-2"
        text={text}
      />
    </div>
    <div className="aspect-1/2 max-w-[120px] mb-5 lg:mb-0 order-first lg:max-w-[300px] lg:order-last">
      {image.filename && <img alt={image.alt || ''} src={image.filename} />}
    </div>
  </section>
)

export const FeatureHighlightSb = ({
  blok,
}: {
  blok: FeatureHighlightStoryblok
}) => <FeatureHighlight {...blok} />
