import clsx from 'clsx'
import { ReactNode, useState } from 'react'

interface Props {
  className?: string
  defaultOpenIndex?: number
  items: {
    content: ReactNode
    title: string
  }[]
  variant: 'default' | 'rounded' | 'underline'
}

export const Tabs = ({
  className = 'space-y-2',
  defaultOpenIndex = 0,
  items,
  variant = 'default',
}: Props) => {
  const [open, setOpen] = useState(defaultOpenIndex)

  return (
    <section
      className={clsx(className, 'flex flex-col', {
        'items-center': variant !== 'default',
      })}
    >
      <div
        className={clsx('flex items-center overflow-x-auto text-nowrap', {
          'bg-primary-solid-7 py-3 rounded-full': variant === 'rounded',
          'divide-x-[1px] divide-primary-solid-6': variant === 'rounded',
          'py-8 space-x-4': variant === 'underline',
          'pr-4 py-2 md:space-x-8 space-x-4': variant === 'default',
        })}
      >
        {items.map((item, index) => (
          <button
            key={item.title}
            className={
              variant === 'underline'
                ? clsx('font-bold border-b-2 pb-2 text-lg', {
                    'border-b-transparent text-grey-solid-3': index !== open,
                    'border-b-primary-solid-2 text-primary-solid-2':
                      index === open,
                  })
                : variant === 'rounded'
                ? clsx(
                    'font-semibold first:pl-5 last:pr-5 py-1 px-4 text-xs md:text-md',
                    {
                      'text-primary-solid-5': index !== open,
                      'text-primary-solid-2': index === open,
                    }
                  )
                : clsx('font-semibold text-sm md:text-dxs', {
                    'text-grey-solid-4': index !== open,
                    'text-grey-solid-0': index === open,
                  })
            }
            onClick={() => setOpen(index)}
            type="button"
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="flex flex-col w-full">{items[open].content}</div>
    </section>
  )
}
