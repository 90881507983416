import { ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

import i18n from '../../assets/locales.json'
import { useGlossary } from '../../hooks'

interface Props {
  children: ReactNode
  href: string
}

export const GlossaryLink = ({ children, href }: Props) => {
  const { glossary } = useGlossary()

  const item = glossary.terms.find((t) => href.includes(t.full_slug))

  if (!item) {
    return (
      <a
        className="underline whitespace-nowrap hover:text-primary-solid-2"
        href={href}
      >
        {children}
      </a>
    )
  }

  return (
    <>
      <a
        className="underline whitespace-nowrap hover:text-primary-solid-2"
        id={`glossary-${item.term}`}
        href={href}
        onClick={(ev) => ev.preventDefault()}
        onKeyDown={(ev) => ev.preventDefault()}
        role="button"
        tabIndex={0}
        target="_blank"
      >
        {children}
      </a>
      <Tooltip
        anchorSelect={`#glossary-${item.term}`}
        className="glossaryTooltip"
        classNameArrow="glossaryArrow"
        clickable
        variant="light"
        wrapper="span"
      >
        <span className="flex flex-col items-start indent-0 not-italic">
          <span className="font-bold mb-2 text-md">{item.term}</span>
          <span className="line-clamp-3 mb-3 text-sm">{item.definition}</span>
          <a href={href} className="no-underline text-primary-solid-2 text-sm">
            {i18n.global.learnMore}
          </a>
        </span>
      </Tooltip>
    </>
  )
}
