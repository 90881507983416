import { FeatureCardStoryblok } from '../../../../types/storyblok'
import { Link } from '../Link/Link'

import { FreeTag, PremiumTag } from '../Tags/Tags'
import i18n from '../../assets/locales.json'
import { storyblokLink } from '../../helpers'

const Tags = {
  free: FreeTag,
  premium: PremiumTag,
}

interface Props {
  description: string
  tags?: (keyof typeof Tags)[]
  title: string
  url: string
}

export const FeatureCard = ({ description, tags, title, url }: Props) => (
  <div className="bg-primary-solid-7 flex flex-col h-full justify-between p-8 rounded space-y-4">
    <div className="flex flex-col space-y-2">
      <h3 className="font-semibold text-dxs">{title}</h3>
      {tags && (
        <div className="items-center hidden lg:flex">
          {tags.map((tag) => (
            <span key={tag}>{Tags[tag]()}</span>
          ))}
        </div>
      )}
      <p className="text-lg text-grey-solid-2">{description}</p>
    </div>
    <Link href={url} textClass="font-bold text-primary-solid-2 text-xl">
      {i18n.platform.discoverFeature}
    </Link>
  </div>
)

export const FeatureCardSb = ({ blok }: { blok: FeatureCardStoryblok }) => (
  <FeatureCard
    {...blok}
    url={storyblokLink(blok.link)}
  />
)
