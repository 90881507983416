import { ReactNode } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { RichText } from '../../atoms'
import { PressKitSectionStoryblok } from '../../../../types'

interface Props {
  items: ReactNode
  text: string | ReactNode
  title: string
}

export const PressKitSection = ({ items, text, title }: Props) => (
  <section className="flex flex-col">
    <h2 className="font-semibold mb-4 text-dsm">{title}</h2>
    <div className="mb-6 md:mb-8 text-lg text-grey-solid-2">{text}</div>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">{items}</div>
  </section>
)

export const PressKitSectionSb = ({
  blok,
}: {
  blok: PressKitSectionStoryblok
}) => (
  <PressKitSection
    items={(blok.items || []).map((item) => (
      <StoryblokServerComponent key={item._uid} blok={item} />
    ))}
    text={<RichText content={blok.text} />}
    title={blok.title}
  />
)
