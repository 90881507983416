import { ReactNode } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { PageLinksStoryblok } from '../../../../types/storyblok'

interface Props {
  children: ReactNode[]
}

export const PageLinks = ({ children }: Props) => (
  <nav aria-label="Pagination Navigation" className="pb-8 lg:pb-16">
    <ul className="flex items-center justify-between overflow-auto scroll-mx-4 snap-x lg:snap-none space-x-4 w-full">
      {children}
    </ul>
  </nav>
)

export const PageLinksSb = ({ blok }: { blok: PageLinksStoryblok }) => (
  <PageLinks>
    {blok.items.map((nestedBlok) => (
      <li key={nestedBlok._uid} className="scroll-pr-4 snap-align-none">
        <StoryblokServerComponent blok={nestedBlok} />
      </li>
    ))}
  </PageLinks>
)
