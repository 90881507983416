import { ReactNode } from 'react'
import { StoryblokServerComponent } from '@storyblok/react/rsc'

import { ReviewsGridStoryblok } from '../../../../types/storyblok'
import { CarouselGrid, CarouselGridItem } from '..'

interface Props {
  children?: ReactNode[]
  title: string
  variant?: 'md' | 'lg'
}

const Title = ({ title, variant }: Props) => (
  <>
    {variant === 'md' ? (
      <h3 className="mb-6 text-center lg:text-left text-md text-grey-solid-2">
        {title}
      </h3>
    ) : (
      <h2 className="font-semibold mb-2 text-center md:text-left text-xl md:text-dsm">
        {title}
      </h2>
    )}
  </>
)

export const ReviewsGrid = ({
  children = [],
  title,
  variant = 'md',
}: Props) => (
  <section className="flex flex-col">
    <Title title={title} variant={variant} />
    <CarouselGrid cols="3">
      {children.map((item, index) => (
        <CarouselGridItem key={index}>{item}</CarouselGridItem>
      ))}
    </CarouselGrid>
  </section>
)

export const ReviewsGridSb = ({ blok }: { blok: ReviewsGridStoryblok }) => (
  <section className="flex flex-col">
    <Title title={blok.title} variant={blok.size} />
    <CarouselGrid cols="3">
      {blok.items.map((nestedBlok) => (
        <CarouselGridItem key={nestedBlok._uid}>
          <StoryblokServerComponent blok={nestedBlok} />
        </CarouselGridItem>
      ))}
    </CarouselGrid>
  </section>
)
