import { Get } from '../networking.es6';

export default {
  fetchIdentity(service, courseIdentitySlug) {
    return Get(`${service.api}/api/web/course_identities/${courseIdentitySlug}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchIdentities(service, query) {
    return Get(`${service.api}/api/web/discover_course_identities/search?q=${query}&count=5`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  searchIdentities(service, place, query, page, count, sortBy, order) {
    const params = `place[slug]=${place}&with_images=true&q=${query}&page=${page}&count=${count}&sort_by=${sortBy}&sort_order=${order}`;
    return Get(`${service.api}/api/web/discover_course_identities/search?${params}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
};
