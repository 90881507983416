import { PlatformReviewStoryblok } from '../../../../types/storyblok'
import { Rating } from '../Rating/Rating'

interface Props {
  rating: '1' | '2' | '3' | '4' | '5'
  ratingValue: string
  title: string
}

export const PlatformReview = ({ rating, ratingValue, title }: Props) => (
  <section className="flex flex-col items-center lg:items-start space-y-2 lg:space-y-3 py-8 lg:py-16">
    <h3 className="font-semibold text-dxs lg:text-dmd">{title}</h3>
    <Rating className="space-x-0" rating={rating} size="lg" />
    <p className="font-semibold text-dxs lg:text-dmd">{ratingValue}</p>
  </section>
)

export const PlatformReviewSb = ({
  blok,
}: {
  blok: PlatformReviewStoryblok
}) => <PlatformReview {...blok} />
