import { AdvertiseStatListStoryblok } from '../../../../types'
import { AdvertiseSection } from '../AdvertiseSection/AdvertiseSection'

import {
  AdvertiseStatItem,
  AdvertiseStatItemProps,
} from '../AdvertiseStatItem/AdvertiseStatItem'

interface Props {
  text: string
  title: string
  items: AdvertiseStatItemProps[]
}

export const AdvertiseStatList = ({ text, title, items }: Props) => (
  <AdvertiseSection text={text} title={title}>
    <ul className="grid grid-cols-2 md:grid-cols-3 gap-2">
      {items.map((item) => (
        <AdvertiseStatItem key={item.name} {...item} />
      ))}
    </ul>
  </AdvertiseSection>
)

export const AdvertiseStatListSb = ({ blok }: AdvertiseStatListStoryblok) => (
  <AdvertiseStatList {...blok} />
)
